import React, { FC, useState } from "react";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boAddCompanyAdministratorAsync, boSelectCompanyAdmins, boSelectCompanyUsersLoadingState, boSelectInvitedCompanyAdmins } from "@/app/store/backofficeSlice";
import BOCompanyAdministratorRow from "./BOCompanyAdministratorRow";
import { EntityId } from "@reduxjs/toolkit";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import AddCompanyAdminModal from "@/views/home/company/detail/companySettingsUsers/AddCompanyAdminModal";
import { ICompanyAdministratorChangeRequest } from "@/model/ICompanyAdministratorChangeRequest";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import BhTableRow from "@components/table/BhTableRow";

interface Props {
  companyId: EntityId;
}

const BOCompanyCompanyAdministrators: FC<Props> = ({ companyId }) => {
  const companyAdministrators = useAppSelector((state) => boSelectCompanyAdmins(state));
  const invitedCompanyAdministrators = useAppSelector((state) => boSelectInvitedCompanyAdmins(state));
  const companyAdministratorsExist = companyAdministrators && companyAdministrators.length > 0;
  const invitedCompanyAdministratorsExist = invitedCompanyAdministrators && invitedCompanyAdministrators.length > 0;
  const loading = useAppSelector(boSelectCompanyUsersLoadingState);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const administratorsTableHeaders: Array<IBhTableHeader<any>> = [
    { id: 0, content: "Name", field: "", sortable: true },
    { id: 1, content: "Email", field: "" },
    { id: 2, content: "Phone", field: "" },
    { id: 3, content: "Occupation", field: "" },
    { id: 4, content: "Account manager", field: "" },
    { id: 5, content: "Checklists manager", field: "" },
    { id: 6, content: "Add to new project by default", field: "" },
    { id: 7, content: "", field: "" }
  ];

  const addAdministrator = (request: ICompanyAdministratorChangeRequest) => {
    request.username = request.username?.toLowerCase();
    dispatch(boAddCompanyAdministratorAsync({ companyId: companyId, request: request })).then(() => setAddModalOpen(false));
  };

  if (!companyId) return null;

  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="mb-4">Company administrators</h2>
        <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: () => setAddModalOpen(true) }}>
          Company admin
        </BhSecondaryButton>
      </div>
      <div>
        {loading !== BhStateStatusType.SUCCESS && (
          <table className="w-full">
            <BhTableHeader columns={administratorsTableHeaders} />
            <tbody>
              <BhTableRow>
                <td colSpan={8}>
                  <div className="bh-bg-pigeon-50 h-5 w-2/3 animate-pulse rounded" />
                </td>
              </BhTableRow>
            </tbody>
          </table>
        )}
        {loading === BhStateStatusType.SUCCESS && (
          <>
            {!companyAdministratorsExist && !invitedCompanyAdministratorsExist && <p className="text-center">No company administrators</p>}
            {(companyAdministratorsExist || invitedCompanyAdministratorsExist) && (
              <table className="w-full">
                <BhTableHeader columns={administratorsTableHeaders} />
                <tbody>
                  {companyAdministratorsExist &&
                    companyAdministrators.map((user, idx) => (
                      <BOCompanyAdministratorRow
                        user={user}
                        key={user.userEntityId}
                        companyId={companyId}
                        rowClasses={idx === companyAdministrators.length - 1 && !invitedCompanyAdministrators ? "border-b-0" : ""}
                      />
                    ))}
                  {invitedCompanyAdministratorsExist &&
                    invitedCompanyAdministrators.map((user, idx) => (
                      <BOCompanyAdministratorRow user={user} key={user.username} companyId={companyId} rowClasses={idx === invitedCompanyAdministrators.length - 1 ? "border-b-0" : ""} />
                    ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
      {addModalOpen && <AddCompanyAdminModal companyId={companyId} onSave={addAdministrator} onCancel={() => setAddModalOpen(false)} />}
    </div>
  );
};

export default BOCompanyCompanyAdministrators;
