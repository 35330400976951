import React, { FC, useEffect, useState } from "react";
import { IProject } from "@/model/IProject";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhInputLabel from "@components/input/BhInputLabel";
import BhToggleButtonBar, { IBhToggleButtonBarItem } from "@components/toggle/BhToggleButtonBar";
import { ProjectInvoiceType } from "@/model/IBillingInfo";
import { IProjectBilling } from "@/model/billing/IProjectBilling";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import { countriesMap } from "@/utilities/countriesMap";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";
import BhDatepickerStackedLabel from "@components/input/BhDatepickerStackedLabel";
import { useAppDispatch } from "@/app/hooks";
import { boSaveProjectBillingAsync } from "@/app/store/backofficeSlice";
import { getCountryName } from "@/utilities/countriesUtil";
import { useTranslation } from "react-i18next";
import { BhSectionMessageError } from "@components/sectionMessage/BhSectionMessages";

interface Props {
  setIsShown: (value: boolean) => void;
  project: IProject;
}

const BOCompanyProjectBillingModal: FC<Props> = ({ setIsShown, project }) => {
  const { i18n } = useTranslation();
  const [newProjectBilling, setNewProjectBilling] = useState<IProjectBilling>(project.billing);
  const countryNames = countriesMap.map((country) => getCountryName(country.countryCode, i18n.language)).sort();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (newProjectBilling.sumWithoutVat) {
      const vatPercentage = newProjectBilling.vatPercentage / 100;
      const vatAmount = newProjectBilling.sumWithoutVat * vatPercentage;
      const sumWithVat = vatAmount + newProjectBilling.sumWithoutVat;
      setNewProjectBilling({ ...newProjectBilling, vatAmount, sumWithVat });
    }
  }, [newProjectBilling.sumWithoutVat]);

  const billingPeriodValues: Array<IBhToggleButtonBarItem> = [
    {
      value: ProjectInvoiceType.MONTH,
      text: "Monthly"
    },
    {
      value: ProjectInvoiceType.YEAR,
      text: "Year"
    },
    {
      value: ProjectInvoiceType.FREE,
      text: "Free"
    }
  ];

  const invoiceTypeValues: Array<IBhToggleButtonBarItem> = [
    {
      value: false,
      text: "E-mail"
    },
    {
      value: true,
      text: "E-invoice"
    }
  ];

  const onFieldChangeCallback = (changedValue: Record<string, any>) => {
    setNewProjectBilling({ ...newProjectBilling, ...changedValue } as IProjectBilling);
  };

  const onProjectBillingSaveCallback = () => {
    dispatch(boSaveProjectBillingAsync(newProjectBilling)).then(() => {
      setIsShown(false);
    });
  };

  const onCancelCallback = () => {
    setNewProjectBilling(project.billing);
    setIsShown(false);
  };

  return (
    <BhModal
      setIsShown={setIsShown}
      size="2xl"
      header={<h2>Edit project billing</h2>}
      footer={<BhModalFooter confirmButtonText="Save changes" onConfirm={onProjectBillingSaveCallback} cancelButtonText="Cancel" onCancel={onCancelCallback} />}
    >
      <div className="h-full overflow-auto">
        <div className="flex flex-col gap-2 px-8 py-4">
          {newProjectBilling.transferDisabled && <BhSectionMessageError>This subscription cannot be transferred to other projects because it was activated after being closed</BhSectionMessageError>}
          <div>
            <BhInputLabel>Project</BhInputLabel>
            <p className="l-h-20px">{project.name}</p>
          </div>
          <BhToggleButtonBar items={billingPeriodValues} property="type" selected={newProjectBilling.type} label="Billing period" onClickAction={onFieldChangeCallback} />
          <h3>Billing info</h3>
          <div className="grid grid-cols-2 gap-4">
            <BhInputStackedLabel initialValue={newProjectBilling.billingDay} property="billingDay" label="Invoice date" onChangeCallback={onFieldChangeCallback} />
            <div className="full-w-datepicker">
              <BhDatepickerStackedLabel
                initialValue={new Date(newProjectBilling.billingDate)}
                property="billingDate"
                label="Next invoice"
                onChangeCallback={(e: any) => onFieldChangeCallback({ billingDate: e.billingDate.toISOString() })}
              />
            </div>
          </div>
          <BhInputStackedLabel initialValue={newProjectBilling.customerRecipientEmails} property="customerRecipientEmails" label="Billing e-mail" onChangeCallback={onFieldChangeCallback} />
          <div className="-ml-5">
            <BhCheckboxWithText text="Personal invoicing" isChecked={newProjectBilling.customerCivilian} property="customerCivilian" onChange={onFieldChangeCallback} />
          </div>
          <BhInputStackedLabel initialValue={newProjectBilling.customerRegCode} property="customerRegCode" label="Reg code" onChangeCallback={onFieldChangeCallback} />
          <BhInputStackedLabel initialValue={newProjectBilling.customerVatNumber} property="customerVatNumber" label="Vat number" labelHelper="(optional)" onChangeCallback={onFieldChangeCallback} />
          <BhInputStackedLabel initialValue={newProjectBilling.customerRecipient} property="customerRecipient" label="Recipient" onChangeCallback={onFieldChangeCallback} />
          <BhComboboxStackedLabel property="customerCountry" values={countryNames} label="Country" initialValue={newProjectBilling.customerCountry} onSelect={onFieldChangeCallback} />
          <BhInputStackedLabel initialValue={newProjectBilling.customerAddress} property="customerAddress" label="Legal address" onChangeCallback={onFieldChangeCallback} />
          <BhInputStackedLabel
            initialValue={newProjectBilling.customerAdditionalInfo}
            property="customerAdditionalInfo"
            label="Additional info"
            labelHelper="(optional)"
            onChangeCallback={onFieldChangeCallback}
          />
          <BhInputStackedLabel
            initialValue={newProjectBilling.vatPercentage}
            property="vatPercentage"
            label="VAT %"
            onChangeCallback={(e: any) => onFieldChangeCallback({ vatPercentage: parseFloat(e.vatPercentage) })}
          />
          <BhInputStackedLabel
            initialValue={newProjectBilling.vatAmount}
            property="vatAmount"
            label="VAT total"
            onChangeCallback={(e: any) => onFieldChangeCallback({ vatAmount: parseFloat(e.vatAmount) })}
          />
          <BhInputStackedLabel
            initialValue={newProjectBilling.sumWithoutVat}
            property="sumWithoutVat"
            label="Sum without VAT"
            onChangeCallback={(e: any) => onFieldChangeCallback({ sumWithoutVat: parseFloat(e.sumWithoutVat) })}
          />
          <BhInputStackedLabel
            initialValue={newProjectBilling.sumWithVat}
            property="sumWithVat"
            label="Sum with VAT"
            onChangeCallback={(e: any) => onFieldChangeCallback({ sumWithVat: parseFloat(e.sumWithVat) })}
          />
          <BhToggleButtonBar items={invoiceTypeValues} property="einvoice" selected={newProjectBilling.einvoice} label="Invoice type" onClickAction={onFieldChangeCallback} />
          <div>
            <h3>Trials</h3>
            <div className="-ml-5">
              <BhCheckboxWithText text="Project confirmed (applicable during trials)" isChecked={newProjectBilling.confirmed} property="confirmed" onChange={onFieldChangeCallback} />
            </div>
          </div>
        </div>
      </div>
    </BhModal>
  );
};

export default BOCompanyProjectBillingModal;
