import React, { FC, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-solid-svg-icons/faWarning";
import { useTranslation } from "react-i18next";
import BhRadioInput from "@components/input/BhRadioInput";
import BhModalFooter from "@components/modal/BhModalFooter";
import { EntityId } from "@reduxjs/toolkit";
import { ISimpleForm } from "@/model/IForm";
import { prefillFormFromPreviousFormAsync, saveFormNameAsync, selectCurrentFormFileEntityId, selectCurrentFormName } from "@/app/store/form/formSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { dalevDistance } from "@/utilities/jsUtilities";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhInputSimple from "@components/input/BhInputSimple";
import { fullPageLoadingSet } from "@/app/store/globalSlice";

interface Props {
  closeModal: Function;
  currentFormId?: EntityId;
  selectedForm: ISimpleForm;
}

const FormContainerPrefillModal: FC<Props> = ({ closeModal, currentFormId, selectedForm }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const formFileEntityId = useAppSelector(selectCurrentFormFileEntityId);
  const currentFormName = useAppSelector(selectCurrentFormName);
  const [nameDifferenceExisting, setNameDifferenceExisting] = useState<boolean>(false);
  const [useExistingName, setUseExistingName] = useState<boolean>(false);
  const [newName, setName] = useState<string>(selectedForm.name);

  useEffect(() => {
    if (currentFormName) {
      const distance = dalevDistance(currentFormName, selectedForm.name);
      if (distance > 3) {
        setNameDifferenceExisting(true);
      }
    }
  }, [currentFormName]);

  const onPrefill = async () => {
    if (!currentFormId) return;

    dispatch(fullPageLoadingSet(true));
    try {
      await dispatch(
        prefillFormFromPreviousFormAsync({
          formId: currentFormId,
          selectedForm: selectedForm
        })
      );

      if (nameDifferenceExisting && !useExistingName) {
        await dispatch(
          saveFormNameAsync({
            projectId: projectId,
            formFileEntityId: formFileEntityId,
            formName: newName
          })
        );
      }

      dispatch(fullPageLoadingSet(false));
      closeModal();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={() => closeModal()}
      header={
        <div className="inline-flex items-center">
          <FontAwesomeIcon className="bh-text-warning-yellow mt-1 pr-5" icon={faWarning} />
          <h2>{t("FORMBUILDER.FORM.PREFILL_MODAL.HEADER")}</h2>
        </div>
      }
      children={
        <div className="flex flex-col gap-y-4 p-8">
          <div>{t("FORMBUILDER.FORM.PREFILL_MODAL.BODY")}</div>
          {nameDifferenceExisting && (
            <div className="flex flex-col gap-y-4">
              <div className="font-bold">{t("FORMBUILDER.FORM.PREFILL_MODAL.BODY.NAME")}</div>
              <div className="flex flex-col gap-y-2">
                <BhRadioInput
                  property={"option1"}
                  label={
                    <div className="flex gap-x-1">
                      <div>{t("FORMBUILDER.FORM.PREFILL_MODAL.BODY.NAME.OPTION_1")}</div>
                    </div>
                  }
                  onChange={() => setUseExistingName(false)}
                  checked={!useExistingName}
                />
                {!useExistingName && (
                  <div className="px-6">
                    <BhInputSimple initialValue={newName} property={"name"} onChangeCallback={(value: any) => setName(value.name)} />
                  </div>
                )}
                <BhRadioInput
                  property={"option2"}
                  label={
                    <div className="flex gap-x-1">
                      <div>{t("FORMBUILDER.FORM.PREFILL_MODAL.BODY.NAME.OPTION_2")}</div>
                      <div className="font-bold">"{currentFormName}"</div>
                    </div>
                  }
                  onChange={() => setUseExistingName(true)}
                  checked={useExistingName}
                />
              </div>
            </div>
          )}
        </div>
      }
      footer={
        <BhModalFooter
          onConfirm={onPrefill}
          confirmButtonText={t("FORMBUILDER.FORM.PREFILL_MODAL.REPLACE") as string}
          cancelButtonText={t("GLOBAL.CANCEL") as string}
          onCancel={closeModal}
          isWarningButton={true}
          confirmDisabled={!useExistingName && !newName}
        />
      }
    />
  );
};

export default FormContainerPrefillModal;
