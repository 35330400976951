import React, { FC, startTransition, useCallback, useEffect, useRef } from "react";
import BhInputLabel from "@components/input/BhInputLabel";

interface Props {
  initialValue: any;
  property: string;
  onBlurCallback?: Function;
  onChangeCallback?: Function;
  onFocus?: Function;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  saveOnEnter?: boolean;
  label?: string;
}

const BhLargeInput: FC<Props> = ({ property, initialValue, required, onBlurCallback, onChangeCallback, onFocus, placeholder, disabled, inputRef, saveOnEnter, label }) => {
  let containedInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let inputRefPropOrContained = inputRef || containedInputRef;
    if (inputRefPropOrContained.current && initialValue !== undefined) inputRefPropOrContained.current.value = initialValue;
  }, [initialValue]);

  const onChange = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    startTransition(() => {
      const inputValue = e.target.value;
      // if (inputValue === initialValue) return;
      const changedValue = { [property]: inputValue };
      onChangeCallback && onChangeCallback(changedValue);
    });
  }, []);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      const inputRefPropOrContained = inputRef ? inputRef : containedInputRef;
      const changedValue = { [property]: inputRefPropOrContained.current?.value };
      onBlurCallback && onBlurCallback(changedValue);
    },
    [onBlurCallback]
  );

  const onKeyDown = (event: any) => {
    if (event.key === "Enter" && saveOnEnter) {
      event.preventDefault();
      const inputRefPropOrContained = inputRef ? inputRef : containedInputRef;
      inputRefPropOrContained.current?.blur();
    }
  };

  const handleOnFocus = () => {
    onFocus && onFocus();
  };

  return (
    <div>
      {label && (
        <div className="pl-2">
          <BhInputLabel>{label}</BhInputLabel>
        </div>
      )}
      <input
        ref={inputRef || containedInputRef}
        name={property}
        onChange={onChange}
        className="bh-text-deep-ocean bh-border-pigeon-50 focus:bh-border-deep-ocean hover:bh-border-pigeon-70 disabled:bh-border-pigeon-40 disabled:bh-bg-smoke w-full rounded border p-2 text-xl font-bold leading-7 focus:outline-0"
        placeholder={placeholder || ""}
        disabled={disabled}
        autoComplete="off"
        onBlur={onBlur}
        onFocus={handleOnFocus}
        onKeyDown={(e) => onKeyDown(e)}
      />
    </div>
  );
};

export default React.memo(BhLargeInput);
