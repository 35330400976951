import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { INewProjectForm } from "@/model/INewProjectForm";
import { EntityId } from "@reduxjs/toolkit";
import { countriesMap } from "@/utilities/countriesMap";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";
import { Trans, useTranslation } from "react-i18next";
import BhInputWithDropdownWithFetchValuesOnChange from "@components/input/BhInputWithDropdownWithFetchValuesOnChange";
import { bauhubToString } from "@/utilities/jsUtilities";
import { fetchMaruProjectsForCompany } from "@/api/maru/maruAPI";
import { IMaruProject } from "@/model/maru/IMaruProject";
import { getAlpha2Code, getCountryName } from "@/utilities/countriesUtil";

interface Props {
  newProjectForm: INewProjectForm;
  setNewProjectForm: Dispatch<SetStateAction<INewProjectForm>>;
  companyId: EntityId;
}

const NewProjectDetails: FC<Props> = ({ newProjectForm, setNewProjectForm, companyId }) => {
  const { t, i18n } = useTranslation();
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const isMaruCompany = company?.modules.some((m) => m.module === "MR_BUDGET");

  const countryNames = countriesMap.map((country) => getCountryName(country.countryCode, i18n.language)).sort();
  const selectedCountryStates = countriesMap.find((country) => country.countryCode === getAlpha2Code(newProjectForm.country, i18n.language))?.states;

  const onProjectDetailsChange = useCallback(
    (changedValue: INewProjectForm) => {
      const changedObject = changedValue.country ? { ...changedValue, state: "" } : { ...changedValue };
      setNewProjectForm((prevState) => {
        return { ...prevState, ...changedObject };
      });
    },
    [newProjectForm]
  );

  const selectMaruProject = (project: IMaruProject) => {
    setNewProjectForm((prevState) => {
      return { ...prevState, name: project.name, projectCode: project.code, contractNumber: project.code };
    });
  };

  const fetchMaruProjects = (searchString: string) => {
    const queryString = bauhubToString(searchString).replace(/\s/g, "+");
    return fetchMaruProjectsForCompany(companyId, queryString);
  };

  return (
    <>
      {company && (
        <div className="w-5/12">
          <h2 className="mb-5">
            <Trans>GLOBAL.PROJECT_INFO</Trans>
          </h2>
          <div className="flex flex-col gap-y-3">
            <div>
              <p className="bh-text-deep-ocean-80 font-semibold">
                <Trans>HOME.COMPANY</Trans>
              </p>
              <p>{company.name}</p>
            </div>
            {isMaruCompany && (
              <BhInputWithDropdownWithFetchValuesOnChange
                label={t("MODAL.NEW_PROJECT.NAME_PLACEHOLDER") as string}
                property="name"
                leadingProperty="code"
                onSelect={selectMaruProject}
                initialValue={newProjectForm.name}
                fetchValues={fetchMaruProjects}
              />
            )}
            {!isMaruCompany && (
              <BhInputStackedLabel label={t("MODAL.NEW_PROJECT.NAME_PLACEHOLDER")} initialValue={newProjectForm.name} property="name" onChangeCallback={onProjectDetailsChange} required={true} />
            )}
            <BhComboboxStackedLabel property="country" values={countryNames} initialValue={newProjectForm.country || ""} onSelect={onProjectDetailsChange} label={t("PROJECT.COUNTRY")} />
            <BhComboboxStackedLabel
              property="state"
              values={selectedCountryStates || []}
              initialValue={newProjectForm.state || ""}
              onSelect={onProjectDetailsChange}
              label={t("COMPANY.STATE")}
              labelHelper={t("INPUT.LABEL_HELPER.OPTIONAL_FIELD") as string}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NewProjectDetails;
