import React, { FC, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { IFormDataSaveRequest } from "@/model/IForm";

interface Props {
  initialValue: string;
  property: string;
  saveCallback?: Function;
  toolbarTools?: string;
  contentStyle?: string;
  latoFont?: boolean;
  disabled?: boolean;
}

const BhWysiwygEditor: FC<Props> = ({ initialValue, property, saveCallback, toolbarTools, contentStyle, latoFont, disabled }) => {
  const editorRef = useRef(null) as any;

  const defaultToolbar = "undo redo | styles | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table tabledelete";

  const saveEditorContent = () => {
    if (editorRef.current) {
      saveCallback &&
        saveCallback({
          changes: { [property]: editorRef.current.getContent() }
        } as IFormDataSaveRequest);
    }
  };

  const additionalCss =
    ".bauhub-field {background-color: #fbeabb;}" +
    ".mce-content-body [contentEditable=false][data-mce-selected], .mce-content-body [contentEditable=false] [contentEditable=true]:hover, .mce-content-body [contentEditable=false] [contentEditable=true]:focus {outline: none !important;}" +
    ".mce-content-body [contentEditable=false] [contentEditable=true]:hover, .mce-content-body [contentEditable=false] [contentEditable=true]:focus {background-color: #fdf7e6;}" +
    "h1 {font-size: 28px;line-height: 30px;margin-top: 16px;margin-bottom: 12px;}" +
    "h2 {font-size: 20px;line-height: 28px;margin-top: 12px;margin-bottom: 8px;}" +
    "h3 {font-size: 16px;line-height: 18px;margin-top: 8px;margin-bottom: 8px;}" +
    "p {margin-top: 6px;margin-bottom: 6px;}" +
    (latoFont ? "body {font-family: 'Lato'}" : "");

  return (
    <Editor
      tinymceScriptSrc={ConfigSingleton.getInstance().getConfig().PUBLIC_URL + "/tinymce4.3.0/tinymce.min.js"}
      onInit={(evt, editor) => (editorRef.current = editor)}
      disabled={disabled}
      initialValue={initialValue}
      init={{
        auto_focus: true,
        height: "60px",
        setup: (editor) => {
          editor.on("blur", () => {
            saveEditorContent();
          });
          // TODO "remove" event should fire save also, but should not fire two events with blur
        },
        style_formats: [
          { title: "Paragraph", format: "p" },
          { title: "Header 1", format: "h1" },
          { title: "Header 2", format: "h2" },
          { title: "Header 3", format: "h3" }
        ],
        statusbar: false,
        resize: false,
        menubar: false,
        plugins: "link image lists table autoresize",
        autoresize_bottom_margin: 20,
        toolbar: toolbarTools || defaultToolbar,
        themes: "modern",
        paste_auto_cleanup_on_paste: true,
        paste_remove_styles: true,
        paste_remove_styles_if_webkit: true,
        elementpath: false,
        branding: false,
        invalid_styles: {
          th: "height",
          td: "height"
        },
        content_style: (contentStyle || "") + additionalCss,
        content_css: latoFont && "/fonts/LatoFonts.css",
        width: "100%",
        editable_class: "bauhub-field",
        font_size_formats: "8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt"
      }}
    />
  );
};

export default BhWysiwygEditor;
