import React, { useCallback } from "react";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveCurrentProjectAsync, selectCurrentProject } from "@/app/store/project/projectSlice";
import { BauhubBannerType, IProject } from "@/model/IProject";
import { countriesMap } from "@/utilities/countriesMap";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";
import { useTranslation } from "react-i18next";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { getAlpha2Code, getCountryName } from "@/utilities/countriesUtil";
import { languageMap } from "@/views/home/profile/ProfileTabs/ProfileUserInfo";

const ProjectDetailsFormContainer = () => {
  const project = useAppSelector(selectCurrentProject);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const projectLanguageCode = languageMap[project.language].langCode;

  const projectCountryObject = countriesMap.find((country) => country.countryCode === getAlpha2Code(project.country, projectLanguageCode));
  const projectCountryTextValueInUserLanguage = getCountryName(getAlpha2Code(project.country, projectLanguageCode), i18n.language);
  const selectedCountryStates = projectCountryObject?.states;

  const countryNamesList = countriesMap.map((country) => getCountryName(country.countryCode, i18n.language)).sort();

  const onProjectSubmit = useCallback(
    (changedValue: IProject) => {
      if (changedValue?.name?.length < 1) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            children: t("INPUT.NOT_EMPTY")
          })
        );
        return;
      }
      let changedObject = { ...project, ...changedValue };
      if (Object.keys(changedValue)[0] === "country") {
        const changedValueCountryObject = countriesMap.find((country) => country.countryCode === getAlpha2Code(changedValue.country, i18n.language));
        const countryNameInProjectLanguage = changedValueCountryObject ? getCountryName(changedValueCountryObject.countryCode, projectLanguageCode) : changedValue.country;
        const changedValueInProjectLanguage = { country: countryNameInProjectLanguage };
        changedObject = { ...project, ...changedValueInProjectLanguage, state: undefined };
        return dispatch(saveCurrentProjectAsync(changedObject));
      }
      dispatch(saveCurrentProjectAsync(changedObject));
    },
    [project]
  );

  return (
    <div>
      <div className="relative mt-6">
        <BhInputStackedLabel
          initialValue={project.name || ""}
          property="name"
          label={t("FORMS.PROJECT_NAME") as string}
          onChangeCallback={onProjectSubmit}
          onBlurAction={onProjectSubmit}
          debounceLength={900}
          maxLength={300}
        />
      </div>
      <div className="mt-6 flex flex-row">
        <div className="mr-8 flex-1 basis-1/2">
          <BhComboboxStackedLabel
            initialValue={projectCountryTextValueInUserLanguage}
            onSelect={onProjectSubmit}
            values={countryNamesList}
            property="country"
            label={t("GLOBAL.COUNTRY") as string}
            placeholder={projectCountryTextValueInUserLanguage}
          />
        </div>
        <div className="flex-1 basis-1/2">
          <BhComboboxStackedLabel
            initialValue={project.state}
            onSelect={onProjectSubmit}
            values={selectedCountryStates ? selectedCountryStates : []}
            property="state"
            label={t("GLOBAL.STATE") as string}
            placeholder={project.state}
          />
        </div>
      </div>
      <div className="mt-6 flex flex-row">
        <div className="mr-8 flex-1 basis-1/2">
          <BhInputStackedLabel
            initialValue={project.city || ""}
            property="city"
            label={t("GLOBAL.CITY") as string}
            onChangeCallback={onProjectSubmit}
            onBlurAction={onProjectSubmit}
            debounceLength={900}
          />
        </div>
        <div className="flex-1 basis-1/2">
          <BhInputStackedLabel
            initialValue={project.zipCode || ""}
            property="zipCode"
            label={t("GLOBAL.ZIP") as string}
            onChangeCallback={onProjectSubmit}
            onBlurAction={onProjectSubmit}
            debounceLength={900}
          />
        </div>
      </div>
      <div className="mt-6 flex flex-row">
        <div className="mr-8 flex-1 basis-1/2">
          <BhInputStackedLabel
            initialValue={project.street || ""}
            property="street"
            label={t("COMPANY.STREET") as string}
            onChangeCallback={onProjectSubmit}
            onBlurAction={onProjectSubmit}
            debounceLength={900}
          />
        </div>
        <div className="flex-1 basis-1/2">
          <BhInputStackedLabel
            initialValue={project.house || ""}
            property="house"
            label={t("COMPANY.HOUSE") as string}
            onChangeCallback={onProjectSubmit}
            onBlurAction={onProjectSubmit}
            debounceLength={900}
          />
        </div>
      </div>
      <div className="mt-6 flex flex-row">
        <div className="mr-8 flex-1 basis-1/2">
          <BhInputStackedLabel
            initialValue={project.contractNumber || ""}
            property="contractNumber"
            label={t("MODAL.NEW_PROJECT.CONTRACTNUMBER_PLACEHOLDER") as string}
            onChangeCallback={onProjectSubmit}
            onBlurAction={onProjectSubmit}
            debounceLength={900}
          />
        </div>
        <div className="flex-1 basis-1/2"></div>
      </div>
    </div>
  );
};

export default ProjectDetailsFormContainer;
