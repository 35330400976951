import React, { FC } from "react";
import BhTableHeader from "@components/table/BhTableHeader";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boSelectAllCompaniesFilteredAndSorted, boSelectCompaniesLoadingState, boSelectSort, boSetSort } from "@/app/store/backofficeSlice";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { ICompany } from "@/model/ICompany";
import BhCompanyRow from "./BhCompanyRow";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import BhTableRow from "@components/table/BhTableRow";

const BOCompaniesListTable: FC = () => {
  const companies = useAppSelector(boSelectAllCompaniesFilteredAndSorted);
  const dispatch = useAppDispatch();
  const sort = useAppSelector(boSelectSort);
  const loading = useAppSelector(boSelectCompaniesLoadingState);

  const tableColumnHeaders: Array<IBhTableHeader<ICompany>> = [
    { id: 0, content: "Name", field: "name", classes: "w-1/2", sortable: true },
    { id: 1, content: "Plan", field: "plan", sortable: true }
  ];

  const onTableHeaderClickCallback = (column: IBhTableHeader<ICompany>) => {
    if (column.field) {
      const companiesSort = { ...sort.companiesSort };
      const updatedCompaniesSort = { ...companiesSort, property: column.field, reversed: column.field === companiesSort.property ? !companiesSort.reversed : companiesSort.reversed };
      const newSort = { ...sort, companiesSort: updatedCompaniesSort };
      dispatch(boSetSort(newSort));
    }
  };

  return (
    <table className="w-full px-2">
      <BhTableHeader columns={tableColumnHeaders} onClickCallback={onTableHeaderClickCallback} reversed={sort.companiesSort.reversed} sortedBy={sort.companiesSort.property} />
      <tbody className="divide-y">
        {loading !== BhStateStatusType.SUCCESS && (
          <BhTableRow>
            <td>
              <div className="h-5 w-3/4 animate-pulse rounded bg-gray-400" />
            </td>
            <td className="w-full">
              <div className="h-5 w-3/4 animate-pulse rounded bg-gray-400" />
            </td>
          </BhTableRow>
        )}
        {loading === BhStateStatusType.SUCCESS && companies?.map((company) => <BhCompanyRow company={company} key={company.id} />)}
      </tbody>
    </table>
  );
};

export default BOCompaniesListTable;
