import React, { FC } from "react";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";
import { useAppSelector } from "@/app/hooks";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import { useTranslation } from "react-i18next";
import { IFormDataSaveRequest } from "@/model/IForm";

interface Props {
  disabled?: boolean;
  saveCallback?: Function;
  isPreview?: boolean;
}

const FormFooterRow: FC<Props> = ({ disabled, saveCallback, isPreview }) => {
  const { t } = useTranslation();
  const printAddons = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, "printAddons") : true));
  const isSignedDigitally = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, "isSignedDigitally") : true));

  const save = (changedObject: any) => {
    saveCallback && saveCallback({ changes: changedObject } as IFormDataSaveRequest);
  };

  return (
    <div className="flex w-full flex-col px-14 lg:px-0">
      <div className="bh-pigeon-30-separator my-4" />
      <div className="flex flex-col gap-y-2">
        <BhCheckboxWithText text={t("FORMS.PRINT_ADDONS")} isChecked={Boolean(printAddons)} property={"printAddons"} onChange={save} disabled={disabled || isPreview} />
        <BhCheckboxWithText text={t("FORMS.GLOBAL.SIGNATURES_DIGITALLY")} isChecked={Boolean(isSignedDigitally)} property={"isSignedDigitally"} onChange={save} disabled={disabled || isPreview} />
      </div>
    </div>
  );
};

export default FormFooterRow;
