import React, { FC, useEffect } from "react";
import ConfirmationsListFilter from "@/views/home/project/detail/confirmations/list/ConfirmationsListFilter";
import ConfirmationsListSort from "@/views/home/project/detail/confirmations/list/ConfirmationsListSort";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchAllUserConfirmationsForProjectAsync,
  selectUserFileConfirmationIdsForProjectSortedFiltered,
  selectUserNeedsApprovalFileConfirmationIdsForProjectSortedFiltered
} from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import ConfirmationsListItemContainer from "@/views/home/project/detail/confirmations/list/ConfirmationsListItemContainer";
import { shallowEqual } from "react-redux";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { Trans } from "react-i18next";

interface Props {}

const ConfirmationsPersonalList: FC<Props> = () => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const needsApprovalConfirmations = useAppSelector((state) => selectUserNeedsApprovalFileConfirmationIdsForProjectSortedFiltered(state, projectId), shallowEqual);
  const userConfirmations = useAppSelector((state) => selectUserFileConfirmationIdsForProjectSortedFiltered(state, projectId), shallowEqual);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllUserConfirmationsForProjectAsync(projectId));
  }, []);

  const isEmpty = needsApprovalConfirmations.length === 0 && userConfirmations.length === 0;

  return (
    <BhScrollableBody header={<ConfirmationsListFilter />} keepScrollForKey="confirmationsPersonalList">
      <ConfirmationsListSort />
      {needsApprovalConfirmations.length > 0 && (
        <div className="pt-2">
          {needsApprovalConfirmations.map((fileConfirmationId) => {
            return <ConfirmationsListItemContainer key={fileConfirmationId} fileConfirmationId={fileConfirmationId} />;
          })}
        </div>
      )}
      {userConfirmations.length > 0 && (
        <div className="pt-4">
          {userConfirmations.map((fileConfirmationId) => {
            return <ConfirmationsListItemContainer key={fileConfirmationId} fileConfirmationId={fileConfirmationId} />;
          })}
        </div>
      )}
      {isEmpty && (
        <BhNoMatchesImage>
          <Trans>FILE_CONFIRMATION.NONE</Trans>
        </BhNoMatchesImage>
      )}
    </BhScrollableBody>
  );
};

export default ConfirmationsPersonalList;
