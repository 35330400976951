import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch } from "@/app/hooks";
import BOCompanySettingsContainer from "@/components/backoffice/company/BOCompanySettingsContainer";
import { boFetchCompanyAsync, boFetchCompanyBillingAsync, boFindCompanyUsersAsync, boResetCompany } from "@/app/store/backofficeSlice";

interface Props {
  companyId: EntityId;
}

const BOCompany: FC<Props> = ({ companyId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return function cleanup() {
      dispatch(boResetCompany());
    };
  }, []);

  useEffect(() => {
    dispatch(boFetchCompanyAsync(companyId)).then(() => {
      dispatch(boFindCompanyUsersAsync(companyId));
      dispatch(boFetchCompanyBillingAsync(companyId));
    });
  }, [companyId]);

  return <BOCompanySettingsContainer />;
};

export default BOCompany;
