import React, { FC } from "react";
import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import BhInputSimple from "@components/input/BhInputSimple";
import BhToggleButtonBar from "@components/toggle/BhToggleButtonBar";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import { IDropdownItem } from "@/model/IDropdownItem";
import BhInputWithSuffixDropdown from "@components/input/BhInputWithSuffixDropdown";
import { formatDateWithMonthName } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";

interface Props {
  baseVersionRow: IChecklistBaseVersionRow;
  saveCallback: Function;
  changeFieldTypeCallback: Function;
  dropdownOptions: Array<IDropdownItem>;
  disabled?: boolean;
}

const ChecklistBaseDatepicker: FC<Props> = ({ baseVersionRow, saveCallback, changeFieldTypeCallback, dropdownOptions, disabled }) => {
  const { t } = useTranslation();

  const size = [
    { text: t("CHECKLIST_BASE.TEXT_FIELD.SIZE.SMALL").toUpperCase(), value: "DATEPICKER" },
    { text: t("CHECKLIST_BASE.TEXT_FIELD.SIZE.LARGE").toUpperCase(), value: "LARGE_TEXTFIELD" }
  ];

  return (
    <div className="flex flex-col">
      <div className="mb-2 flex flex-row items-center">
        <div>
          <BhToggleButtonBar items={size} onClickAction={changeFieldTypeCallback} selected={baseVersionRow.fieldType} property={"fieldType"} disabled={disabled} />
        </div>
        <BhCheckboxWithText text={t("CHECKLIST.ROW.IS_EDITABLE")} isChecked={baseVersionRow.editable} property={"editable"} isSmallCheckbox={true} onChange={saveCallback} disabled={true} />
        <BhCheckboxWithText text={t("CHECKLIST.ROW.IS_REQUIRED")} isChecked={baseVersionRow.required} property={"required"} isSmallCheckbox={true} onChange={saveCallback} disabled={disabled} />
      </div>
      <div className="flex w-full flex-row items-center gap-x-4">
        <div className="w-3/12">
          <BhInputSimple
            initialValue={baseVersionRow.fieldTitle}
            property={"fieldTitle"}
            placeholder={t("CHECKLIST_BASE.TEXT_FIELD.NAME.PLACEHOLDER") as string}
            onBlurAction={saveCallback}
            disabled={disabled}
          />
        </div>
        <div className="w-9/12">
          <BhInputWithSuffixDropdown
            initialPrefix={t("CHECKLIST_BASE.TEXT_FIELD.TYPE.DATEPICKER")}
            initialValue={formatDateWithMonthName(new Date(baseVersionRow.fieldValue))}
            property={"fieldValue"}
            dropdownElements={dropdownOptions}
            placeholder={t("CHECKLIST_BASE.TEXT_FIELD.PLACEHOLDER") as string}
            disabled={disabled}
            inputDisabledOnly={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ChecklistBaseDatepicker;
