import React, { FC, useState } from "react";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhModal from "@components/modal/BhModal";
import BhInputLabel from "@components/input/BhInputLabel";
import { BauhubFormTypes, BauhubMntFormTypes, IFormTemplate } from "@/model/IForm";
import { useTranslation } from "react-i18next";
import { naturalSortByField } from "@/utilities/sortUtilities";
import BhInputWithDropdown from "@components/input/BhInputWithDropdown";
import BhTagListContainer from "@components/tags/BhTagListContainer";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { languageMap } from "@/views/home/profile/ProfileTabs/ProfileUserInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import { boSaveFormsToCountryCodeCompanies } from "@/api/backoffice/boFormsAPI";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";

interface Props {
  setIsShown: any;
}

const BOFormsCopyToLanguageModal: FC<Props> = ({ setIsShown }) => {
  const { t } = useTranslation();
  const [selectedFormBases, setSelectedFormBases] = useState<Array<any>>([]);
  const formBuilderForms = [...BauhubMntFormTypes, ...BauhubFormTypes].map((formType) => ({ formType: formType, name: t("FORMS." + formType) })).sort((a, b) => naturalSortByField(a, b, "name"));
  const countryCodes = Object.values(languageMap).map((lang) => lang.countryCode);
  const [countryCode, setCountryCode] = useState("EE");
  const [loading, setLoading] = useState(false);
  const [addToProjects, setAddToProjects] = useState(false);

  const selectFormTemplate = (formTemplate: IFormTemplate) => {
    if (!selectedFormBases.map((f) => f.formType).includes(formTemplate.formType)) {
      setSelectedFormBases([...selectedFormBases, formTemplate]);
    }
  };

  const selectCountryCode = (countryCode: string) => {
    setCountryCode(countryCode);
  };

  const deleteFormTemplate = (formTemplate: IFormTemplate) => {
    setSelectedFormBases(selectedFormBases.filter((t) => t.formType !== formTemplate.formType));
  };

  const confirmSelection = () => {
    setLoading(true);
    boSaveFormsToCountryCodeCompanies({ countryCode: countryCode, formTypes: selectedFormBases.map((b) => b.formType), addToProjects: addToProjects }).then(() => {
      setLoading(false);
    });
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={setIsShown}
      onClose={() => setIsShown(false)}
      size={"2xl"}
      header={<h2>Copy form templates to specific country code companies</h2>}
      children={
        <div className="flex flex-col p-8">
          <div className="flex flex-row gap-x-4">
            <div className="w-1/2">
              <div className="w-32">
                <div className="flex flex-col">
                  <BhInputLabel>CountryCode</BhInputLabel>
                  <BhDropdown
                    buttonClasses={"w-28"}
                    button={
                      <button className="min-w-70px bh-border-smoke-40 hover:bh-border-pigeon-70 flex h-9 w-full items-center justify-between rounded border p-2">
                        {countryCode}
                        <FontAwesomeIcon icon={faCaretDown} />
                      </button>
                    }
                    menu={
                      <BhFixedDropdownParent>
                        <BhDropdownMenu values={countryCodes} closeOnItemClick={true} type={BhDropdownTypeEnum.STRING} onSelect={selectCountryCode} widthClass={"w-28"} />
                      </BhFixedDropdownParent>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <BhInputLabel>Select forms</BhInputLabel>
              {selectedFormBases.length > 0 && (
                <div className="mb-4 flex flex-row gap-x-2">
                  <BhTagListContainer>
                    {selectedFormBases.map((selectedForm) => (
                      <BhTag key={selectedForm.formType} type={BhTagType.TAG} onDeleteClick={deleteFormTemplate} object={selectedForm}>
                        {selectedForm.name}
                      </BhTag>
                    ))}
                  </BhTagListContainer>
                </div>
              )}
              <BhInputWithDropdown initialValue={""} property={"name"} onSelect={selectFormTemplate} values={formBuilderForms} />
            </div>
          </div>
          <div className="py-4">
            <BhCheckboxWithText text="Enable form for all active projects" isChecked={addToProjects} property="addToProjects" onChange={() => setAddToProjects(!addToProjects)} />
          </div>
        </div>
      }
      footer={<BhModalFooter onCancel={() => setIsShown(false)} onConfirm={confirmSelection} confirmDisabled={loading} confirmButtonText={"Copy templates"} />}
    />
  );
};

export default BOFormsCopyToLanguageModal;
