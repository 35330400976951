import React, { FC } from "react";
import BhSpreadsheetTableHeaderRow from "@components/spreadsheet/BhSpreadsheetTableHeaderRow";
import BhSpreadsheetTableTH from "@components/spreadsheet/BhSpreadsheetTableTH";
import BhSpreadsheetTableHead from "@components/spreadsheet/BhSpreadsheetTableHead";
import { IBhSort } from "@/model/IBhSort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  columns: Array<IBhTableHeader<ISalesInvoice>>;
  sort: IBhSort<any>;
  onClickCallback?: Function;
}

const BhSpreadsheetTableHeader: FC<Props> = ({ columns, sort, onClickCallback }) => {
  return (
    <BhSpreadsheetTableHead>
      <BhSpreadsheetTableHeaderRow>
        {columns.map((column, index) => {
          return (
            <BhSpreadsheetTableTH key={index} classes={column.classes}>
              <span className={classNames(column.sortable && "cursor-pointer")} onClick={() => column.sortable && onClickCallback?.(column)}>
                <span>{column.content}</span>
                {sort.property === column.field && <FontAwesomeIcon className="px-1" icon={sort.reversed ? faCaretDown : faCaretUp} />}
              </span>
            </BhSpreadsheetTableTH>
          );
        })}
      </BhSpreadsheetTableHeaderRow>
    </BhSpreadsheetTableHead>
  );
};

export default BhSpreadsheetTableHeader;
