import React, { FC } from "react";
import BOHeader from "@/views/home/backoffice/views/BOHeader";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { bauhubPost } from "@/api/bauhubAPI";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { useAppDispatch } from "@/app/hooks";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { boRunArchivedProjectFreezeNotificationJob, boRunBillingNotificationJob } from "@/api/backoffice/boJobsAPI";

const BOBackend: FC = () => {
  const dispatch = useAppDispatch();

  const [showMongoRowsCopyModal, hideMongoRowsCopyModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideMongoRowsCopyModal}
      header={<h3>Initial form base version rows copy to MongoDB</h3>}
      body={"If you don't know what it does, don't do it!"}
      confirmationButtonText={"Transfer"}
      cancelButtonText={"Cancel"}
      handleDelete={() => {
        return bauhubPost("/form/builder/rows/transfer/mongo").then((response) => {
          dispatch(
            toastFlagAdded({
              id: uuidv4(),
              type: BauhubBannerType.SUCCESS,
              disappear: true,
              children: response.value
            })
          );
          hideMongoRowsCopyModal();
        });
      }}
    />
  ));

  const [showArchivedProjectFreezeNotificationJobModal, hideArchivedProjectFreezeNotificationJobModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideArchivedProjectFreezeNotificationJobModal}
      header={<h2>Run job</h2>}
      body={"Are you sure you want to run archived project freeze notification job?"}
      confirmationButtonText={"Run"}
      cancelButtonText={"Cancel"}
      handleDelete={() => {
        boRunArchivedProjectFreezeNotificationJob();
        hideArchivedProjectFreezeNotificationJobModal();
      }}
    />
  ));

  const [showBillingNotificationJobModal, hideBillingNotificationJobModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideBillingNotificationJobModal}
      header={<h2>Run job</h2>}
      body={"Are you sure you want to run billing notification job?"}
      confirmationButtonText={"Run"}
      cancelButtonText={"Cancel"}
      handleDelete={() => {
        boRunBillingNotificationJob();
        hideBillingNotificationJobModal();
      }}
    />
  ));

  return (
    <div className="px-16 sm:px-4">
      <BOHeader />
      <div className="mt-10">
        <BhPrimaryButton buttonProps={{ onClick: showMongoRowsCopyModal }}>Form rows to mongo transfer</BhPrimaryButton>
      </div>
      <div className="mt-10 flex flex-col gap-y-3">
        <h2>Jobs</h2>
        <div>
          <BhSecondaryButton buttonProps={{ onClick: showArchivedProjectFreezeNotificationJobModal }}>Archived project freeze notification job</BhSecondaryButton>
        </div>
        <div>
          <BhSecondaryButton buttonProps={{ onClick: showBillingNotificationJobModal }}>Billing notification job</BhSecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default BOBackend;
