import {
  FileEntityBranch,
  FileEntityType,
  IFileEntity,
  IFileMoveOrCopyRequestDTO,
  IFolderFileEntity,
  IRevisionReplaceRequestDTO,
  IUploadFileEntity,
  NewVersionOption,
  ReplaceRevisionDTO
} from "@/model/files/IFileEntity";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import { IFileUploadPresignedDTO } from "@/model/files/IFileUploadPresignedDTO";
import { ICompanyLogoDTO } from "@/model/ICompanyLogoDTO";
import { IUserAvatarDTO } from "@/model/IUserAvatarDTO";
import { bauhubGet, bauhubPost, bauhubPublicGet } from "@/api/bauhubAPI";
import { AnyAction, EntityId, ThunkDispatch } from "@reduxjs/toolkit";
import { fileAdded, fileModified, filesRemoved } from "@/app/store/filesSlice";
import { setUploadModalOpen, setUploadState, uploadFileAdded, uploadFileModified, uploadFileRemoved, uploadFileUpdated } from "@/app/store/uploadSlice";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { IContainerCreateRequest } from "@/model/container/IContainerCreateRequest";
import { IAsyncJobResponse } from "@/model/IAsyncJobResponse";
import { getCookie, serialize, untilCondition } from "@/utilities/jsUtilities";
import { IFileAccess, IWorkGroupToFileEntity } from "@/model/files/IFileAccess";
import { uniqueId } from "lodash";
import { RootState, store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { abortUploadFunctionsStore, parallelUploadCountStore } from "@/api/upload/uploadHelper";
import { IFolderHierarchyDTO } from "@/model/files/IFolderHierarchyDTO";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { IBimFloorplansDTO, IManifestDTO } from "@/model/files/IManifestDTO";

export function fetchDirectory(dirId: EntityId): Promise<Array<IFileEntity>> {
  return bauhubGet("/v2/file/dir/" + dirId);
}

export function fetchDirectoryOld(dirId: EntityId): Promise<Array<IFileEntity>> {
  return bauhubGet("/file/dir/" + dirId);
}

export function fetchFileEntity(fileEntityId: EntityId): Promise<IFileEntity> {
  return bauhubGet("/file/" + fileEntityId);
}

export function fetchFileEntityWithContainerInfo(fileEntityId: EntityId): Promise<IFileEntity> {
  return bauhubGet("/file/" + fileEntityId + "/container/info");
}

export function saveFileEntities(fileEntities: Array<IFileEntity>): Promise<Array<IFileEntity>> {
  return bauhubPost("/file/all", fileEntities);
}

export function saveNewDirectory(folderFileEntity: IFileEntity): Promise<IFileEntity> {
  return bauhubPost("/file/dir", folderFileEntity);
}

export function deleteFiles(fileEntities: Array<IFileEntity>): Promise<Array<IFileEntity>> {
  return bauhubPost("/file/delete/all", fileEntities);
}

export function removeFileFromContainer(fileId: EntityId): Promise<IFileEntity> {
  return bauhubPost("/project/container/file/" + fileId + "/remove");
}

export function saveFileName(fileEntity: IFileEntity): Promise<IFileEntity> {
  return bauhubPost("/file/dir/rename", { id: fileEntity.id, name: fileEntity.name });
}

export function fetchUrlForFile(
  fileId: EntityId,
  isThumb?: boolean,
  isDownload?: boolean,
  uuid?: string,
  isPdfTron?: boolean,
  isCompressed?: boolean,
  isXkt?: boolean
): Promise<{ uuid: string; value: string }> {
  const urlParams: any = {};
  if (isThumb !== undefined) urlParams.isThumb = `${isThumb}`;
  if (isThumb !== undefined) urlParams.st = `${false}`;
  if (isDownload !== undefined) urlParams.isDownload = `${isDownload}`;
  if (isPdfTron !== undefined) urlParams.isPdfTron = `${isPdfTron}`;
  if (uuid !== undefined) urlParams.fileUuid = uuid;
  if (isCompressed !== undefined) urlParams.isCompressed = isCompressed;
  if (isXkt !== undefined) urlParams.isXkt = isXkt;

  return bauhubGet("/url/" + fileId, urlParams);
}

export function fetchPresignedUploadUrl(projectId: EntityId, contentType?: string, parentId?: EntityId, workGroupId?: EntityId): Promise<IFileUploadPresignedDTO> {
  const urlParams: any = {};
  urlParams.projectId = `${projectId}`;
  urlParams.contentType = contentType || "";
  if (parentId !== undefined) urlParams.parentId = `${parentId}`;
  if (workGroupId !== undefined) urlParams.workGroupId = workGroupId;

  return bauhubGet("/url/upload", urlParams);
}

export function fetchPresignedUploadUrlForCompany(companyId: EntityId, contentType?: string): Promise<IFileUploadPresignedDTO> {
  const urlParams: any = {};
  urlParams.companyId = `${companyId}`;
  urlParams.contentType = contentType || "";

  return bauhubGet("/company/url/upload", urlParams);
}

export function fetchPresignedUploadUrlForAvatar(userId: EntityId, contentType: string): Promise<IUserAvatarDTO> {
  const urlParams: any = {};
  if (userId !== undefined) urlParams.userId = `${userId}`;
  if (contentType !== undefined) urlParams.contentType = contentType;

  return bauhubGet("/url/upload/avatar", urlParams);
}

export function fetchPresignedBatchUrl(fileIds: Array<EntityId>): Promise<{ value: string }> {
  return bauhubPost("/url/batch/presign?st=false", { value: fileIds });
}

export function uploadFile(
  url: string,
  method: "PUT" | "POST",
  headers: Array<{ name: string; value: string }>,
  body: File | FormData,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  uploadingFileEntity: IUploadFileEntity
): { promise: Promise<Response>; xhrObject: XMLHttpRequest } {
  const xhr = new XMLHttpRequest();

  const promise: Promise<Response> = new Promise((resolve, reject) => {
    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const percentage = Math.floor((100.0 * event.loaded) / event.total);
        const percentageAlwaysNotHundred = percentage < 100 ? percentage : percentage - 1;
        const changedObject = { ...uploadingFileEntity, ...{ percentage: percentageAlwaysNotHundred } };
        dispatch(uploadFileModified(changedObject));
      }
    });
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const response = new Response(xhr.response);
          resolve(response);
        } else {
          resolve({ ok: false } as Response);
        }
      }
    };
    xhr.onabort = function () {
      resolve({ ok: false } as Response);
    };
    xhr.open(method, url, true);
    for (const header of headers) {
      xhr.setRequestHeader(header.name, header.value);
    }
    xhr.send(body);
  });

  return { promise, xhrObject: xhr };
}

export async function uploadAndImportZipFile(
  file: File,
  projectId: EntityId,
  isDocument: boolean,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  getState: Function,
  dirId?: EntityId
): Promise<IAsyncJobResponse | undefined> {
  dispatch(setUploadState(BhStateStatusType.PENDING));
  dispatch(setUploadModalOpen(true));
  let newFile: IUploadFileEntity = {
    name: file.name,
    size: file.size,
    projectId: projectId,
    contentType: file.type,
    uuid: uniqueId(file.name),
    revision: 1,
    uploading: true,
    uploaded: new Date().toISOString()
  } as IUploadFileEntity;

  dispatch(uploadFileAdded(newFile));

  await untilCondition((_: any) => parallelUploadCountStore.getCount() < 5);

  const state: RootState = getState() as RootState;
  if (state.upload.entities[newFile.uuid]?.errorUploading) return;

  parallelUploadCountStore.increment();

  const urlParams: any = {};
  if (isDocument !== undefined) urlParams.isDocument = `${isDocument}`;
  if (dirId !== undefined) urlParams.dirId = `${dirId}`;

  let urlAndParams = "/project/" + projectId + "/zip/upload";

  if (urlParams) {
    urlAndParams += "?" + new URLSearchParams(serialize(urlParams));
  }

  const formData = new FormData();
  formData.append("file", new Blob([file]), file.name);

  const { promise, xhrObject } = uploadFile(
    ConfigSingleton.getInstance().getConfig().REACT_APP_API_URL + urlAndParams,
    "POST",
    [
      {
        name: "X-XSRF-TOKEN",
        value: getCookie("XSRF-TOKEN") || ""
      }
    ],
    formData,
    dispatch,
    newFile
  );

  abortUploadFunctionsStore.set(newFile.uuid, xhrObject);
  return promise.then(async (response) => {
    abortUploadFunctionsStore.set(newFile.uuid, null);
    if (response.ok) {
      dispatch(uploadFileRemoved(newFile.uuid));
      return response.json();
    } else {
      console.log("Upload error 1");
      const changedObject = { ...newFile, ...{ uploading: false, errorUploading: true } };
      dispatch(uploadFileModified(changedObject));
    }
  });
}

export async function uploadAndSaveContainerMetaInfo(
  fileEntityType: FileEntityType,
  file: File,
  projectId: EntityId,
  parentFileEntityId: EntityId | undefined,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  getState: Function
): Promise<IFileEntity | undefined> {
  // TODO: Convert iOS filenames
  dispatch(setUploadState(BhStateStatusType.PENDING));
  dispatch(setUploadModalOpen(true));
  let newFile: IUploadFileEntity = {
    name: file.name,
    size: file.size,
    projectId: projectId,
    contentType: file.type,
    uuid: uniqueId(file.name),
    revision: 1,
    type: fileEntityType,
    uploading: true,
    uploaded: new Date().toISOString()
  } as IUploadFileEntity;

  if (parentFileEntityId) newFile.parentFileEntityId = parentFileEntityId as number;
  dispatch(uploadFileAdded(newFile));

  await untilCondition((_: any) => parallelUploadCountStore.getCount() < 5);
  parallelUploadCountStore.increment();

  const state: RootState = getState() as RootState;
  if (state.upload.entities[newFile.uuid]?.errorUploading) return;

  const url = "/project/" + projectId + "/container/upload" + (parentFileEntityId && parentFileEntityId !== -1 ? "?dirId=" + parentFileEntityId : "");
  const formData = new FormData();
  formData.append("file", new Blob([file]), file.name);

  const { promise, xhrObject } = uploadFile(
    ConfigSingleton.getInstance().getConfig().REACT_APP_API_URL + url,
    "POST",
    [
      {
        name: "X-XSRF-TOKEN",
        value: getCookie("XSRF-TOKEN") || ""
      }
    ],
    formData,
    dispatch,
    newFile
  );

  abortUploadFunctionsStore.set(newFile.uuid, xhrObject);
  return promise.then(async (response) => {
    abortUploadFunctionsStore.set(newFile.uuid, null);
    if (response.ok) {
      const savedContainer = await response.json();
      const changedObject = { ...newFile, ...{ uploading: false } };
      dispatch(uploadFileModified(changedObject));
      dispatch(fileAdded(savedContainer));
      return savedContainer;
    } else {
      console.log("Upload error 2");
      const changedObject = { ...newFile, ...{ uploading: false, errorUploading: true } };
      dispatch(uploadFileModified(changedObject));
      return changedObject;
    }
  });
}

export async function uploadLargeFileAndSaveFileMetaInfo(
  fileEntityType: FileEntityType,
  projectId: EntityId,
  parentDirId: EntityId,
  fileSize: number,
  fileName: string,
  file: File,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  getState: Function
): Promise<IFileEntity | undefined> {
  dispatch(setUploadState(BhStateStatusType.PENDING));
  dispatch(setUploadModalOpen(true));

  let newFile: IUploadFileEntity = {
    uuid: uniqueId(file.name),
    name: file.name,
    size: file.size,
    projectId: projectId,
    type: fileEntityType,
    uploading: true,
    uploaded: new Date().toISOString()
  } as IUploadFileEntity;

  if (parentDirId) newFile.parentFileEntityId = parentDirId as number;

  dispatch(uploadFileAdded(newFile));

  await untilCondition((_: any) => parallelUploadCountStore.getCount() < 5);
  parallelUploadCountStore.increment();

  const state: RootState = getState() as RootState;
  if (state.upload.entities[newFile.uuid]?.errorUploading) return;

  const url = ConfigSingleton.getInstance().getConfig().REACT_APP_API_URL + "/project/" + projectId + "/large/upload?size=" + fileSize + (parentDirId ? "&dirId=" + parentDirId : "");
  const formData = new FormData();
  formData.append("file", new Blob([file]), file.name);

  const { promise, xhrObject } = uploadFile(url, "POST", [{ name: "X-XSRF-TOKEN", value: getCookie("XSRF-TOKEN") || "" }], formData, dispatch, newFile);

  abortUploadFunctionsStore.set(newFile.uuid, xhrObject);
  return promise
    .then(async (response) => {
      abortUploadFunctionsStore.set(newFile.uuid, null);
      if (response.ok) {
        const uuidValue = await response.json();
        if (uuidValue.value) {
          const newFileWithCorrectUuid = { ...newFile, uuid: uuidValue.value };
          return saveFile(newFileWithCorrectUuid)
            .then((savedFileEntity) => {
              // Cannot use savedFileEntity because using a different uuid would make upload modal treat the file as new
              dispatch(uploadFileModified({ ...newFile, name: savedFileEntity.name, uploading: false }));
              dispatch(fileAdded(savedFileEntity));
              return savedFileEntity;
            })
            .catch(() => {
              console.log("Metadata error 3");
              const changedObject = { ...newFile, ...{ uploading: false, errorUploading: true } };
              dispatch(uploadFileModified(changedObject));
              return changedObject;
            });
        } else {
          console.log("Saving error 4");
          const changedObject = { ...newFile, ...{ uploading: false, errorUploading: true } };
          dispatch(uploadFileModified(changedObject));
          return changedObject;
        }
      } else {
        console.log("Upload error 5");
        const changedObject = { ...newFile, ...{ uploading: false, errorUploading: true } };
        dispatch(uploadFileModified(changedObject));
        return changedObject;
      }
    })
    .catch((error) => {
      console.log("Upload error 6");
      const changedObject = { ...newFile, ...{ uploading: false, errorUploading: true } };
      dispatch(uploadFileModified(changedObject));
      return changedObject;
    });
}

export async function fetchPresignedUploadUrlUploadAndSaveFileMetaInfo(
  file: File,
  projectId: EntityId,
  parentFileEntityId: EntityId | undefined,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  uploadingStateFileEntity: IUploadFileEntity
): Promise<IFileEntity> {
  // TODO: Convert iOS filenames
  dispatch(setUploadState(BhStateStatusType.PENDING));
  dispatch(setUploadModalOpen(true));

  return fetchPresignedUploadUrl(projectId, file.type, parentFileEntityId).then(async (res) => {
    const withCorrectUuid = { ...uploadingStateFileEntity, uuid: res.objectName };

    dispatch(uploadFileUpdated({ id: uploadingStateFileEntity.uuid, changes: withCorrectUuid }));

    const { promise, xhrObject } = uploadFile(res.url, "PUT", [{ name: "Content-Type", value: file.type + ";charset=UTF-8" }], file, dispatch, withCorrectUuid);

    abortUploadFunctionsStore.set(withCorrectUuid.uuid, xhrObject);
    return promise.then((response) => {
      abortUploadFunctionsStore.set(withCorrectUuid.uuid, null);
      if (response.ok) {
        return saveFile(withCorrectUuid)
          .then((savedFileEntity) => {
            const changedObject = { ...withCorrectUuid, ...savedFileEntity, ...{ uploading: false } };
            dispatch(uploadFileModified(changedObject));
            dispatch(fileAdded(savedFileEntity));
            return savedFileEntity;
          })
          .catch(() => {
            console.log("Metadata error 7");
            const changedObject = { ...withCorrectUuid, ...{ uploading: false, errorUploading: true } };
            dispatch(uploadFileModified(changedObject));
            return changedObject;
          });
      } else {
        console.log("Upload error 8");
        const changedObject = { ...withCorrectUuid, ...{ uploading: false, errorUploading: true } };
        dispatch(uploadFileModified(changedObject));
        return changedObject;
      }
    });
  });
}

export async function fetchPresignedUploadUrlUploadForCompanyAndSaveFileMetaInfo(
  file: File,
  companyId: EntityId,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  uploadingStateFileEntity: IUploadFileEntity
): Promise<IFileEntity> {
  // TODO: Convert iOS filenames
  dispatch(setUploadState(BhStateStatusType.PENDING));
  dispatch(setUploadModalOpen(true));

  return fetchPresignedUploadUrlForCompany(companyId, file.type).then(async (res) => {
    const withCorrectUuid = { ...uploadingStateFileEntity, uuid: res.objectName };

    dispatch(uploadFileUpdated({ id: uploadingStateFileEntity.uuid, changes: withCorrectUuid }));

    const { promise, xhrObject } = uploadFile(res.url, "PUT", [{ name: "Content-Type", value: file.type + ";charset=UTF-8" }], file, dispatch, withCorrectUuid);

    abortUploadFunctionsStore.set(withCorrectUuid.uuid, xhrObject);
    return promise.then((response) => {
      abortUploadFunctionsStore.set(withCorrectUuid.uuid, null);
      if (response.ok) {
        return saveFile(withCorrectUuid)
          .then((savedFileEntity) => {
            const changedObject = { ...withCorrectUuid, ...savedFileEntity, ...{ uploading: false } };
            dispatch(uploadFileModified(changedObject));
            dispatch(fileAdded(savedFileEntity));
            return savedFileEntity;
          })
          .catch(() => {
            console.log("Metadata error 7");
            const changedObject = { ...withCorrectUuid, ...{ uploading: false, errorUploading: true } };
            dispatch(uploadFileModified(changedObject));
            return changedObject;
          });
      } else {
        console.log("Upload error 8");
        const changedObject = { ...withCorrectUuid, ...{ uploading: false, errorUploading: true } };
        dispatch(uploadFileModified(changedObject));
        return changedObject;
      }
    });
  });
}

export async function fetchPresignedUploadUrlUploadAndSaveRevisionFileMetaInfo(
  file: File,
  projectId: EntityId,
  revisionFile: IFileEntity,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  uploadingStateFileEntity: IUploadFileEntity
): Promise<IFileEntity> {
  // TODO: Convert iOS filenames
  dispatch(setUploadState(BhStateStatusType.PENDING));
  dispatch(setUploadModalOpen(true));

  return fetchPresignedUploadUrl(projectId, file.type, revisionFile.parentFileEntityId).then(async (res) => {
    const withCorrectUuid = { ...uploadingStateFileEntity, uuid: res.objectName };

    dispatch(uploadFileUpdated({ id: uploadingStateFileEntity.uuid, changes: withCorrectUuid }));

    const { promise, xhrObject } = uploadFile(res.url, "PUT", [{ name: "Content-Type", value: file.type + ";charset=UTF-8" }], file, dispatch, withCorrectUuid);

    abortUploadFunctionsStore.set(withCorrectUuid.uuid, xhrObject);
    return promise.then((response) => {
      abortUploadFunctionsStore.set(withCorrectUuid.uuid, null);
      if (response.ok) {
        return saveFileRevision(revisionFile.id, withCorrectUuid, true)
          .then((savedFileEntity) => {
            const changedObject = { ...withCorrectUuid, ...savedFileEntity, ...{ uploading: false } };
            dispatch(uploadFileModified(changedObject));
            dispatch(fileAdded(savedFileEntity));
            const newRevisionFile = { ...revisionFile, parentFileEntityId: savedFileEntity.id };
            dispatch(fileModified(newRevisionFile));
            dispatch(filesRemoved(savedFileEntity.children));
            return savedFileEntity;
          })
          .catch((error) => {
            store.dispatch(
              toastFlagAdded({
                id: uuidv4(),
                type: BauhubBannerType.ERROR,
                disappear: true,
                translateCode: "ERROR.REQUEST_FAILED"
              })
            );
            console.log("Upload error 9");
            const changedObject = { ...withCorrectUuid, ...{ uploading: false, errorUploading: true } };
            dispatch(uploadFileModified(changedObject));
            return changedObject;
          });
      } else {
        console.log("Upload error 10");
        const changedObject = { ...withCorrectUuid, ...{ uploading: false, errorUploading: true } };
        dispatch(uploadFileModified(changedObject));
        return changedObject;
      }
    });
  });
}

export function saveFile(fileEntity: IFileEntity): Promise<IFileEntity> {
  return bauhubPost("/file/meta", fileEntity);
}

export function saveFileRevision(parentFileEntityId: EntityId, fileEntity: IFileEntity, allowNameChange?: boolean): Promise<IFileEntity> {
  const dto = { file: fileEntity, allowNameChange: allowNameChange };
  return bauhubPost("/file/" + parentFileEntityId + "/rev", dto);
}

export function undoFileRevision(fileEntityId: EntityId): Promise<IFileEntity> {
  return bauhubPost("/file/" + fileEntityId + "/rev/undo");
}

export function undoAndPreserveFileRevision(fileEntityId: EntityId): Promise<IFileEntity> {
  return bauhubPost("/file/" + fileEntityId + "/rev/undo/preserve");
}

export function addFilesToContainer(containerUuid: string, fileIds: Array<EntityId>, areFilesSelectedFromProject: boolean): Promise<Array<IFileEntity>> {
  return bauhubPost("/project/container/" + containerUuid + "/files/add/" + areFilesSelectedFromProject, fileIds);
}

export function createContainer(projectId: EntityId, requestDTO: IContainerCreateRequest): Promise<IAsyncJobResponse> {
  return bauhubPost("/project/" + projectId + "/container?st=false", requestDTO);
}

export function fetchPresignedLogoUploadUrl(companyId: EntityId, contentType: string): Promise<ICompanyLogoDTO> {
  return bauhubGet("/url/upload/logo", { companyId: companyId, contentType: contentType });
}

export function restoreFileFromLog(fileId: EntityId): Promise<IFileEntity> {
  return bauhubPost("/file/undo/" + fileId);
}

export function restoreFileFromEventLog(eventId: EntityId): Promise<IFileEntity> {
  return bauhubPost("/event/undo/delete/" + eventId);
}

export function checkForFileDeletedParents(projectId: EntityId, fileEntityId: EntityId): Promise<IFolderFileEntity> {
  return bauhubGet("/project/" + projectId + "/file/" + fileEntityId + "/parents/check");
}

export function fetchFileLog(fileId: EntityId): Promise<Array<IBauhubEvent>> {
  return bauhubGet("/file/" + fileId + "/fileLog");
}

export function fetchUrlsForFiles(fileIds: Array<EntityId>, isDownload?: boolean, isThumb?: boolean, isCompressed?: boolean): Promise<Record<number, string>> {
  return bauhubGet("/batch/url/" + fileIds.join(","), { isDownload: isDownload, isThumb: isThumb, isCompresse: isCompressed });
}

export function fetchUrlsForBimFiles(fileIds: Array<EntityId>): Promise<Array<{ uuid: string; value: string }>> {
  return bauhubGet("/batch/url/bim/" + fileIds.join(","));
}

export function fetchBimManifests(fileIds: Array<EntityId>): Promise<Array<IManifestDTO>> {
  return bauhubPost("/bim/manifests?st=false", { value: fileIds });
}

export function fetchBimFloorplans(projectId: EntityId): Promise<IBimFloorplansDTO> {
  return bauhubGet("/bim/project/" + projectId + "/floorplans");
}

export function fetchFileRevisions(fileId: EntityId): Promise<Array<IFileEntity>> {
  return bauhubGet("/file/" + fileId + "/rev");
}

export function fetchAllUnlockedFilesForDirs(dirIds: Array<EntityId>): Promise<Array<IFileEntity>> {
  return bauhubPost("/file/dirs/unlocked?st=false", dirIds);
}

export function searchFilesInBranch(projectId: EntityId, query: string, branchTypes: Array<FileEntityBranch>): Promise<{ fileResults: Array<IFileEntity> }> {
  return bauhubGet("/search/" + projectId + "/files/branch", { query: query, branchTypes: branchTypes });
}

export function fetchDirectoryFiles(directoryIds: Array<EntityId>): Promise<Array<IFileEntity>> {
  return bauhubPost("/file/dirs/children/unlocked?st=false", directoryIds);
}

export function copyFiles(dto: IFileMoveOrCopyRequestDTO): Promise<Array<IFileEntity>> {
  return bauhubPost("/file/all/copy", dto);
}

export function moveFiles(dto: IFileMoveOrCopyRequestDTO): Promise<Array<IFileEntity>> {
  return bauhubPost("/file/all/move", dto);
}

export function replaceFileRevision(dto: IRevisionReplaceRequestDTO): Promise<ReplaceRevisionDTO> {
  let body = {} as any;
  if (dto.revisionOption) {
    body = {
      stackDraggedFile: dto.revisionOption === NewVersionOption.ONLY_DRAGGED_FILE_AS_NEW_REVISION,
      stackFiles: dto.revisionOption === NewVersionOption.STACK_DRAGGED_FILE_REVISIONS,
      combineFiles: dto.revisionOption === NewVersionOption.COMBINE_FILE_REVISIONS_ACCORDING_TO_TIME
    };
  }
  return bauhubPost("/revision/fileToReplace/" + dto.fileToReplaceId + "/replacementFile/" + dto.replacementFileId, body);
}

export function fetchFileAccessList(projectId: EntityId, fileEntityId: EntityId): Promise<IFileAccess> {
  return bauhubGet("/project/" + projectId + "/file/" + fileEntityId + "/access");
}

export function saveFileAccess(fileEntityId: EntityId, partyId: EntityId): Promise<IWorkGroupToFileEntity> {
  return bauhubPost("/project/file/access/save", { fileEntityId: fileEntityId, workGroupId: partyId });
}

export function fetchChildrenDirectoriesForDirectory(dirId: EntityId): Promise<Array<IFolderFileEntity>> {
  return bauhubGet("/v2/dir/" + dirId);
}

export function fetchParentDirectoriesForDirectory(dirId: EntityId): Promise<Array<IFolderFileEntity>> {
  return bauhubGet("/v2/file/" + dirId + "/parents");
}

export function fetchParentsForDirectories(dirIds: Array<EntityId>): Promise<Array<IFolderFileEntity>> {
  return bauhubPost("/v2/file/directories/parents?st=false", { value: dirIds });
}

export function fetchDeletedSignatureFileEntity(projectId: EntityId, containerId: EntityId, signatureId: EntityId): Promise<IFileEntity> {
  return bauhubGet("/project/" + projectId + "/container/" + containerId + "/signature/" + signatureId + "/deleted");
}

export function fetchPublicUrlForContainer(containerUuid: string, signInviteUuid: string): Promise<{ value: string }> {
  return bauhubPublicGet("/container/" + containerUuid + "/url/" + signInviteUuid);
}

export function fetchPublicUrlForFileInContainer(containerUuid: string, signInviteUuid: string, fileUuid: string, isDownload?: boolean, isCompressed?: boolean): Promise<{ value: string }> {
  return bauhubPublicGet("/container/" + containerUuid + "/" + signInviteUuid + "/url/" + fileUuid, { isDownload, isCompressed });
}

export function createFolderHierarchy(directoryId: EntityId, paths: Array<string>): Promise<Array<IFolderHierarchyDTO>> {
  return bauhubPost("/file/dir/" + directoryId + "/hierarchy?st=false", paths);
}

export function toggleContainerQueuedSigning(containerFileEntity: IFileEntity): Promise<IFileEntity> {
  return bauhubPost("/file/toggleQueue", containerFileEntity);
}

export function findFormsInFilesToCopyOrMove(projectId: EntityId, fileIds: Array<EntityId>): Promise<Array<IFileEntity>> {
  return bauhubPost("/file/all/transfer/check?st=false", { projectId: projectId, fileIds: fileIds });
}
