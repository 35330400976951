import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData, setFormModalsField, toggleFormModalsOpen } from "@/app/store/form/formSlice";
import { IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import BhTextareaStackedLabel from "@components/input/BhTextareaStackedLabel";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhInputWithFetchedUsers from "@components/input/BhInputWithFetchedUsers";
import { IFormDataSaveRequest } from "@/model/IForm";

interface Props {
  field: IFormBaseVersionRowField;
  saveCallback?: Function;
  disabled?: boolean;
  isPreview?: boolean;
}

const FormTextField: FC<Props> = ({ field, saveCallback, disabled, isPreview }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const inputValue = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, field.property) : field.value.text));
  const isImportEnabled = field.value.import;
  const isHelperTextEnabled = field.value.helper && field.value.helper.enabled;
  const isPrefillEnabled = field.value.prefill && field.value.prefill.enabled;
  const isUserField = field.value.userInput;

  const openImportModal = (field: string) => {
    dispatch(toggleFormModalsOpen({ modal: "importFieldInfoModal" }));
    dispatch(setFormModalsField(field));
  };

  const handleSaveCallback = (changedObject: any) => {
    saveCallback &&
      saveCallback({
        changes: changedObject
      } as IFormDataSaveRequest);
  };

  return (
    <>
      {isUserField ? (
        <div className="flex flex-col">
          <div className="text-14px l-h-18px bh-text-deep-ocean-80 place-self-start px-0.5 py-1.5 font-semibold">{field.value.label || "\u00A0"}</div>
          <BhInputWithFetchedUsers initialValue={inputValue} property={field.property} saveCallback={handleSaveCallback} disabled={disabled} />
        </div>
      ) : (
        <BhTextareaStackedLabel
          onBlurCallback={handleSaveCallback}
          inputRef={inputRef}
          initialValue={inputValue}
          property={field.property}
          label={field.value.label || "\u00A0"}
          disabled={disabled}
          placeholder={isPrefillEnabled && isPreview ? t("FORMBUILDER.FORM.BASE.TEXT.PREFILL." + field.value.prefill.value.toUpperCase()) : "\u00A0"}
          validationHelper={isHelperTextEnabled && field.value.helper.text}
          trailingButton={
            isImportEnabled && (
              <BhTextOnlyButton
                buttonProps={{
                  onClick: () => openImportModal(field.property),
                  disabled: disabled
                }}
              >
                {t("FORMBUILDER.FORM.BASE.ATTACHMENT.IMPORT")}
              </BhTextOnlyButton>
            )
          }
          inputClasses={isImportEnabled ? "pr-24" : undefined}
        />
      )}
    </>
  );
};

export default FormTextField;
