import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveRemoveUserFromWorkGroupAsync, setRemoveUserModalShown } from "@/app/store/project/projectWorkGroupsSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhModal from "@components/modal/BhModal";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import { IUserRemovalInfoResponse } from "@/model/invites/IUserRemovalInfoResponse";
import { EntityId } from "@reduxjs/toolkit";
import { getUserFullName } from "@/model/IUser";
import { fetchUserRemovalInfo } from "@/api/partyAPI";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { textWithVariables } from "@/utilities/jsUtilities";

interface Props {
  partyId: EntityId;
  listItem: IPartyPrivilegeListItemDTO;
}

const PartyRemoveUserModal: FC<Props> = ({ partyId, listItem }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const [removalInfo, setRemovalInfo] = useState({ signInvites: [], fileConfirmations: [] } as IUserRemovalInfoResponse);
  const anyPending = removalInfo.fileConfirmations.length > 0 || removalInfo.signInvites.length > 0;
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  const modalBodyVariableMap: Record<string, string> = {
    username: getUserFullName(listItem),
    pendingInvites: removalInfo.signInvites.length.toString(),
    pendingConfirmations: removalInfo.fileConfirmations.length.toString()
  };

  useEffect(() => {
    if (listItem.userEntityId && !listItem.inviteId) {
      fetchUserRemovalInfo(projectId, partyId, listItem.userEntityId).then((info) => {
        setRemovalInfo(info);
      });
    }
  }, []);

  const handleSubmit = () => {
    setSubmitDisabled(true);
    dispatch(saveRemoveUserFromWorkGroupAsync({ workGroupId: partyId, dto: listItem })).then(() => {
      setSubmitDisabled(false);
    });
  };

  const closeModal = () => {
    dispatch(setRemoveUserModalShown(false));
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={closeModal}
      size="5xl"
      header={<h2>{t("PROJECT.CONFIRM_ADMIN_REMOVE.REMOVE")}</h2>}
      children={
        <div className="p-8">
          {anyPending && <div className="mb-6">{parse(textWithVariables(t("PROJECT.CONFIRM_ADMIN_REMOVE.BODY.ALT"), modalBodyVariableMap))}</div>}
          <div>{t("PROJECT.CONFIRM_ADMIN_REMOVE.BODY")}</div>
        </div>
      }
      footer={
        <div className="flex flex-row items-center">
          <BhTextOnlyButton buttonProps={{ onClick: closeModal }}>{t("GLOBAL.CLOSE")}</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ onClick: handleSubmit, disabled: isSubmitDisabled }}>{t("PROJECT.CONFIRM_ADMIN_REMOVE.REMOVE")}</BhPrimaryButton>
        </div>
      }
    />
  );
};

export default PartyRemoveUserModal;
