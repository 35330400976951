import React, { FC } from "react";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import BOTransferAuthsTableRow from "@components/backoffice/users/BOTransferAuthsTableRow";
import { IBoAdminUserResponse } from "@/model/backoffice/users/IBoAdminUserResponse";
import { useAppDispatch } from "@/app/hooks";
import { boTransferAuthoritiesAsync } from "@/app/store/backofficeSlice";

interface Props {
  users: Array<IBoAdminUserResponse>;
  selectedUser: IBoAdminUserResponse;
}

const BOTransferAuthsTable: FC<Props> = ({ users, selectedUser }) => {
  const dispatch = useAppDispatch();
  const usersTableHeaders: Array<IBhTableHeader<any>> = [
    { id: 0, content: "Name", field: "", sortable: true },
    { id: 1, content: "E-mail", field: "" },
    { id: 2, content: "ID", field: "" },
    { id: 3, content: "", field: "" }
  ];

  const transferAuthorities = (username: string) => {
    const dto = {
      usernameToTransferFrom: username,
      usernameToTransferTo: selectedUser.username
    };
    dispatch(boTransferAuthoritiesAsync(dto));
  };
  return (
    <table className="w-full">
      <BhTableHeader columns={usersTableHeaders} />
      <tbody>
        {users.map((user) => (
          <BOTransferAuthsTableRow key={user.id} user={user} selectedUser={selectedUser} onConfirm={transferAuthorities} />
        ))}
      </tbody>
    </table>
  );
};

export default BOTransferAuthsTable;
