import React, { FC, useMemo } from "react";
import { useAppSelector } from "@/app/hooks";
import { makeSelectAllCompanyProjectsSortedForSettings } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import ProjectListItem from "@/features/projectList/projectListItem/ProjectListItem";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import { Trans } from "react-i18next";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";

interface Props {
  companyId: EntityId;
  searchString: string;
}

const CompanySettingsProjectList: FC<Props> = ({ companyId, searchString }) => {
  const selectAllCompanyProjectsSortedForSettings = useMemo(makeSelectAllCompanyProjectsSortedForSettings, []);
  const sortedProjects = useAppSelector((state) => selectAllCompanyProjectsSortedForSettings(state, companyId));

  const filteredAndSortedProjects = sortedProjects.filter((project) => project.name.toLowerCase().includes(searchString.toLowerCase()));
  const listIsEmpty = sortedProjects.length === 0;
  const filteredListIsEmpty = filteredAndSortedProjects.length === 0;

  return (
    <div className="mb-8" key={companyId}>
      {listIsEmpty && (
        <div className="text-center">
          <BhLargeBoldText>
            <BhNoMatchesImage />
            <Trans>COMPANY.NO_PROJECTS</Trans>
          </BhLargeBoldText>
        </div>
      )}
      {!listIsEmpty && filteredListIsEmpty && (
        <BhLargeBoldText classes="text-center">
          <Trans>GLOBAL.NO_RESULTS</Trans>
        </BhLargeBoldText>
      )}
      {filteredAndSortedProjects && (
        <div>
          {filteredAndSortedProjects.map((project) => {
            return <ProjectListItem projectId={project.id} key={project.id} isCompanySettingsView={true} />;
          })}
        </div>
      )}
    </div>
  );
};

export default CompanySettingsProjectList;
