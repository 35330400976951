import React, { FC } from "react";
import BhSpreadsheetTable from "@components/spreadsheet/BhSpreadsheetTable";
import BhSpreadsheetTableBody from "@components/spreadsheet/BhSpreadsheetTableBody";
import BhSpreadsheetTableTR from "@components/spreadsheet/BhSpreadsheetTableTR";
import BhSpreadsheetTableCell from "@components/spreadsheet/BhSpreadsheetTableCell";
import { formatCurrency } from "@/utilities/accountingUtilities";
import { sum } from "lodash";
import { formatDate } from "@/utilities/dateUtility";
import { IRevenueInvoiceDTO } from "@/model/invoices/IRevenueInvoiceDTO";
import BhSpreadsheetTableHeader from "@components/spreadsheet/BhSpreadsheetTableHeader";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boSelectSort, boSetSort } from "@/app/store/backofficeSlice";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";

interface Props {
  invoices: Array<IRevenueInvoiceDTO>;
  deferred?: boolean;
}

const BORevenueTable: FC<Props> = ({ invoices }) => {
  const boSort = useAppSelector(boSelectSort);
  const dispatch = useAppDispatch();

  const formatSum = (sum: number) => {
    return formatCurrency(sum, { symbol: "€", thousandsSeparator: " ", decimalSeparator: ",", significantDigits: 2 }, true);
  };

  const sortCallback = (column: IBhTableHeader<ISalesInvoice>) => {
    if (column.field) {
      const updatedRevenueSort = { ...boSort.revenueSort, property: column.field, reversed: column.field === boSort.revenueSort.property ? !boSort.revenueSort.reversed : boSort.revenueSort.reversed };
      const newSort = { ...boSort, revenueSort: updatedRevenueSort };
      dispatch(boSetSort(newSort));
    }
  };

  const columns: Array<IBhTableHeader<ISalesInvoice>> = [
    { content: "Invoice Number", field: "fullInvoiceNumber", sortable: true, classes: "w-36" },
    { content: "Invoice date", field: "invoiceDate", sortable: true, classes: "w-36" },
    { content: "Customer", field: "customerRecipient", sortable: true },
    { content: "Type", field: "type", classes: "w-28" },
    { content: "Months/end date", field: "endDate", sortable: true, classes: "w-36" },
    { content: "Invoice total", field: "sumWithoutVat", sortable: true, classes: "w-36" },
    { content: "In this period", classes: "w-36" }
  ];

  return (
    <BhSpreadsheetTable>
      <BhSpreadsheetTableHeader columns={columns} sort={boSort.revenueSort} onClickCallback={sortCallback} />
      <BhSpreadsheetTableBody>
        {invoices?.map((subscription, index) => (
          <BhSpreadsheetTableTR key={index}>
            <BhSpreadsheetTableCell>{subscription.invoice.fullInvoiceNumber}</BhSpreadsheetTableCell>
            <BhSpreadsheetTableCell classes="text-center">{formatDate(subscription.invoice.invoiceDate)}</BhSpreadsheetTableCell>
            <BhSpreadsheetTableCell>{subscription.invoice.customerRecipient}</BhSpreadsheetTableCell>
            <BhSpreadsheetTableCell classes="text-center">{subscription.invoice.type}</BhSpreadsheetTableCell>
            <BhSpreadsheetTableCell>
              {subscription.invoice.durationMonths}/{subscription.invoice.endDate}
            </BhSpreadsheetTableCell>
            <BhSpreadsheetTableCell classes="text-right">{formatSum(subscription.invoice.sumWithoutVat)}</BhSpreadsheetTableCell>
            <BhSpreadsheetTableCell classes="text-right">{formatSum(subscription.sumInPeriod)}</BhSpreadsheetTableCell>
          </BhSpreadsheetTableTR>
        ))}
        {invoices && invoices.length > 0 && (
          <BhSpreadsheetTableTR highlight={true}>
            <BhSpreadsheetTableCell></BhSpreadsheetTableCell>
            <BhSpreadsheetTableCell></BhSpreadsheetTableCell>
            <BhSpreadsheetTableCell></BhSpreadsheetTableCell>
            <BhSpreadsheetTableCell></BhSpreadsheetTableCell>
            <BhSpreadsheetTableCell>
              <strong>Total: </strong>
            </BhSpreadsheetTableCell>
            <BhSpreadsheetTableCell classes="text-right">{formatSum(sum(invoices?.map((i) => i.invoice.sumWithoutVat)))}</BhSpreadsheetTableCell>
            <BhSpreadsheetTableCell classes="text-right">{formatSum(sum(invoices?.map((i) => i.sumInPeriod)))}</BhSpreadsheetTableCell>
          </BhSpreadsheetTableTR>
        )}
        {(!invoices || invoices.length === 0) && (
          <BhSpreadsheetTableTR>
            <BhSpreadsheetTableCell colSpan={7}>No data</BhSpreadsheetTableCell>
          </BhSpreadsheetTableTR>
        )}
      </BhSpreadsheetTableBody>
    </BhSpreadsheetTable>
  );
};

export default BORevenueTable;
