import { FC, ReactElement } from "react";
import { useAppSelector } from "@/app/hooks";
import { hasCurrentUserBackOfficeAccess, hasCurrentUserBackOfficeAccessToResource, isCurrentUserSuperUser } from "@/app/store/userSlice";
import { SubResource } from "@/model/IUserAuthority";

interface Props {
  children: ReactElement;
  subResource?: SubResource;
}

const RequireBOAuth: FC<Props> = ({ children, subResource }) => {
  const isSuperUser = useAppSelector(isCurrentUserSuperUser);
  const hasBackOfficeAccess = useAppSelector(hasCurrentUserBackOfficeAccess);
  const hasBackOfficeSubResourceAccess = useAppSelector((state) => hasCurrentUserBackOfficeAccessToResource(state, subResource));

  if (isSuperUser) {
    return children;
  }

  if (!subResource && hasBackOfficeAccess) {
    return children;
  }

  if (subResource && hasBackOfficeSubResourceAccess) {
    return children;
  }

  return null;
};

export default RequireBOAuth;
