import React, { Dispatch, FC, SetStateAction } from "react";
import { useAppDispatch } from "@/app/hooks";
import BhModal from "@components/modal/BhModal";
import { IFrozenProjectDetails, setProjectFrozenModalVisible } from "@/app/store/globalSlice";
import BhModalFooter from "@components/modal/BhModalFooter";
import parse from "html-react-parser";
import { textWithVariables } from "@/utilities/jsUtilities";
import { formatDate } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  setIsBillingModalShown: Dispatch<SetStateAction<boolean>>;
  projectFrozenModalDetails: IFrozenProjectDetails;
}

const ProjectFrozenModal: FC<Props> = ({ projectFrozenModalDetails, setIsBillingModalShown }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  if (!projectFrozenModalDetails) return null;

  return (
    <>
      <BhModal
        isShown={true}
        setIsShown={(value: boolean) => dispatch(setProjectFrozenModalVisible(value))}
        header={<h2>{t("PROJECT_FROZEN")}</h2>}
        children={
          <div className="flex flex-col gap-y-6 px-8 pb-8 pt-4">
            {parse(textWithVariables(t("PROJECT_FROZEN_MODAL_PART_1"), { projectFrozenDate: formatDate(new Date(projectFrozenModalDetails.projectArchivedDate)) }))}
            <div className="bh-bg-smoke bh-border-pigeon-50 flex flex-col gap-y-2 rounded border p-4">
              <div className="flex flex-row gap-x-4 leading-6">
                <div>
                  <FontAwesomeIcon icon={faCircleInfo} className="h-4 w-4" />
                </div>
                <div className="text-18px font-bold">{t("PROJECT_FROZEN_MODAL_PART_2.HEADER")}</div>
              </div>
              <div className="pl-8">
                {parse(
                  textWithVariables(t("PROJECT_FROZEN_MODAL_PART_2.BODY"), {
                    companySettingsUrl: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${projectFrozenModalDetails.companyId}/billing-plan`
                  })
                )}
              </div>
            </div>
          </div>
        }
        footer={
          <BhModalFooter
            cancelButtonText={t("GLOBAL.CLOSE") as string}
            confirmButtonText={t("PROJECT_FROZEN_MODAL.BUTTON") as string}
            onCancel={() => {
              dispatch(setProjectFrozenModalVisible(false));
            }}
            onConfirm={() => {
              dispatch(setProjectFrozenModalVisible(false));
              setIsBillingModalShown(true);
            }}
          />
        }
      />
    </>
  );
};

export default ProjectFrozenModal;
