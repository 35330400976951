import React, { FC } from "react";
import { IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";
import BhTimePickerStackedLabel from "@components/input/BhTimePickerStackedLabel";
import { IFormDataSaveRequest } from "@/model/IForm";

interface Props {
  field: IFormBaseVersionRowField;
  saveCallback?: Function;
  isPreview?: boolean;
  disabled?: boolean;
}

const FormTimeField: FC<Props> = ({ field, disabled, isPreview, saveCallback }) => {
  const inputValue = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, field.property) : field.value.time));

  const save = (changedObject: any) => {
    saveCallback &&
      saveCallback({
        changes: changedObject
      } as IFormDataSaveRequest);
  };

  return (
    <div className="mb-1">
      <BhTimePickerStackedLabel property={field.property} initialValue={inputValue} disabled={disabled} onChangeCallback={save} label={field.value.label || "\u00A0"} classes={"!min-w-full"} />
    </div>
  );
};

export default FormTimeField;
