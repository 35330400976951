import React from "react";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhDatePicker from "@components/input/BhDatePicker";
import BhFilter from "@components/filters/BhFilter";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boSelectFilter, boSetFilters } from "@/app/store/backofficeSlice";
import { IBoCompanyProjectInvoiceTypeFilter, IBoCompanyProjectStatusFilter, ICompanyProjectsFilter } from "@/model/backoffice/IBoFilter";
import BhFilterWithToggle from "@components/filters/BhFilterWithToggle";

const BOCompanyProjectsFilter = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(boSelectFilter);

  const onNameFilterChange = (searchQuery: Record<string, string>) => {
    const changedObject = { ...filter, ...searchQuery };
    dispatch(boSetFilters(changedObject));
  };
  const onInvoiceTypeFilterChange = (changedValue: IBoCompanyProjectInvoiceTypeFilter) => {
    const companyProjectsFilter: ICompanyProjectsFilter = { ...filter.companyProjectsFilter };
    const updatedInvoiceTypeFilter = companyProjectsFilter.invoiceType.map((type) => (type.value === changedValue.value ? { ...type, selected: !type.selected } : type));
    const newCompanyProjectsFilter = { ...companyProjectsFilter, invoiceType: updatedInvoiceTypeFilter };
    const newFilter = { ...filter, companyProjectsFilter: newCompanyProjectsFilter };
    dispatch(boSetFilters(newFilter));
  };

  const isInvoiceTypeSelected = (orderType: IBoCompanyProjectInvoiceTypeFilter) => {
    return filter.invoicesFilter.orderTypes.flatMap((t) => t.selected && t.value).includes(orderType.value);
  };

  const onInvoiceTypeFilterReset = () => {
    const newCompanyProjectsFilter = filter.companyProjectsFilter.invoiceType.map((type) => ({ ...type, selected: false }));
    const newFilter = { ...filter, companyProjectsFilter: { ...filter.companyProjectsFilter, invoiceType: newCompanyProjectsFilter } };
    dispatch(boSetFilters(newFilter));
  };

  const onStatusFilterChange = (changedValue: IBoCompanyProjectStatusFilter) => {
    const companyProjectsFilter: ICompanyProjectsFilter = { ...filter.companyProjectsFilter };
    const updatedStatusFilter = companyProjectsFilter.status.map((status) => (status.value === changedValue.value ? { ...status, selected: !status.selected } : status));
    const newCompanyProjectsFilter = { ...companyProjectsFilter, status: updatedStatusFilter };
    const newFilter = { ...filter, companyProjectsFilter: newCompanyProjectsFilter };
    dispatch(boSetFilters(newFilter));
  };

  const onStatusFilterReset = () => {
    const newCompanyProjectsFilter = filter.companyProjectsFilter.status.map((status) => ({ ...status, selected: false }));
    const newFilter = { ...filter, companyProjectsFilter: { ...filter.companyProjectsFilter, status: newCompanyProjectsFilter } };
    dispatch(boSetFilters(newFilter));
  };

  const isStatusFilterSelected = (status: IBoCompanyProjectStatusFilter) => {
    return filter?.companyProjectsFilter?.status?.flatMap((s) => (s.selected ? [s.value] : [])).includes(status.value);
  };

  const onSinceDateChange = (changedObj: Record<string, string>) => {
    const newFilter = { ...filter, companyProjectsFilter: { ...filter.companyProjectsFilter, created: changedObj.date } };
    dispatch(boSetFilters(newFilter));
  };

  const onSinceDateReset = () => {
    const newFilter = { ...filter, companyProjectsFilter: { ...filter.companyProjectsFilter, created: "" } };
    dispatch(boSetFilters(newFilter));
  };

  const onShowArchivedChange = () => {
    const newFilter = { ...filter, companyProjectsFilter: { ...filter.companyProjectsFilter, showArchived: !filter.companyProjectsFilter.showArchived } };
    dispatch(boSetFilters(newFilter));
  };

  return (
    <div>
      <BhFilterContainer>
        <div>
          <BhSearchInputWBG initialValue={filter.projectsSearchQuery} property="projectsSearchQuery" placeholder="Search projects" inputClasses="w-72" onChangeCallback={onNameFilterChange} />
        </div>
        <BhFilter
          title={"Status"}
          values={filter.companyProjectsFilter.status}
          textProperty={"name"}
          onSelect={onStatusFilterChange}
          onReset={onStatusFilterReset}
          isSelected={isStatusFilterSelected}
          selectedValues={filter.companyProjectsFilter.status?.filter((status) => status.selected).length}
        />
        <BhDatePicker property={"date"} placeholder="Created" onChangeCallback={onSinceDateChange} onResetCallback={onSinceDateReset} returnISOString={true} />
        <BhFilter
          title={"Invoice type"}
          values={filter.companyProjectsFilter.invoiceType}
          textProperty={"name"}
          onSelect={onInvoiceTypeFilterChange}
          isSelected={isInvoiceTypeSelected}
          onReset={onInvoiceTypeFilterReset}
          selectedValues={filter.companyProjectsFilter.invoiceType?.filter((type) => type.selected).length}
        />
        <BhFilterWithToggle text="Show archived projects" value={filter.companyProjectsFilter.showArchived} onClickAction={onShowArchivedChange} />
      </BhFilterContainer>
    </div>
  );
};

export default BOCompanyProjectsFilter;
