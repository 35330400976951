import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import {
  attachFilesToChecklistRoundRow,
  deleteChecklistRoundRowTask,
  fetchChecklistRoundWithRows,
  removeFileFromChecklistRoundRow,
  saveChecklistRoundRow,
  saveSubRowForRoundListRow,
  saveSubRowForRoundRow
} from "@/api/checklist/checklistAPI";
import { IChecklistRoundRow } from "@/model/checklist/IChecklistRoundRow";
import { ChecklistRowType } from "@/model/checklist/IChecklistBaseVersionRow";
import { ITask } from "@/model/taskBoard/ITask";
import { taskRemoved } from "@/app/store/tasksSlice";
import { setRemovedAnnotationIdsForFileAnnotationId } from "@/app/store/fileAnnotationsSlice";
import { IChecklistAttachment } from "@/model/checklist/IChecklistAttachment";

export const checklistRowsAdapter = createEntityAdapter<IChecklistRoundRow>();

export interface IChecklistRowsState {
  status: BhStateStatusType;
}

export const checklistRowsInitialState = checklistRowsAdapter.getInitialState<IChecklistRowsState>({
  status: BhStateStatusType.INITIAL
});

export const fetchChecklistRoundWithRowsAsync = createAsyncThunk("checklist/round/fetch", async (checklistRoundId: EntityId, { dispatch }) => {
  return fetchChecklistRoundWithRows(checklistRoundId);
});

export const saveChecklistRoundRowAsync = createAsyncThunk("checklist/round/row/save", async (checklistRoundRow: IChecklistRoundRow) => {
  return saveChecklistRoundRow(checklistRoundRow);
});

export const saveSubRowForRoundRowAsync = createAsyncThunk("checklist/round/sub/row/save", async (dto: { roundRowId: EntityId; roundId: EntityId }) => {
  return saveSubRowForRoundRow(dto.roundRowId, dto.roundId);
});

export const saveSubRowForRoundListRowAsync = createAsyncThunk("checklist/round/sub/list/row/save", async (dto: { baseVersionRowId: EntityId; roundId: EntityId }) => {
  return saveSubRowForRoundListRow(dto.baseVersionRowId, dto.roundId);
});

export const deleteChecklistRoundRowTaskAsync = createAsyncThunk("checklist/row/task/delete", async (dto: { checklistRoundRow: IChecklistRoundRow; taskId: EntityId }, { dispatch }) => {
  dispatch(taskRemoved(dto.taskId));
  const result = deleteChecklistRoundRowTask(dto.checklistRoundRow.id, dto.taskId);

  const rowTask = dto.checklistRoundRow.tasks.find((task) => task.id === dto.taskId);
  if (rowTask) {
    const relation = rowTask.fileAnnotationRelations && rowTask.fileAnnotationRelations.length > 0 && rowTask.fileAnnotationRelations[0];
    relation && dispatch(setRemovedAnnotationIdsForFileAnnotationId({ fileEntityId: relation.fileEntityId, annotationIds: [relation.annotationId], type: "Task" }));
  }

  return result;
});

export const attachFilesToChecklistRoundRowAsync = createAsyncThunk(
  "checklist/round/row/attachment/save",
  async (dto: { checklistRoundId: EntityId; checklistRoundRowId: EntityId; attachments: Array<IChecklistAttachment> }) => {
    return attachFilesToChecklistRoundRow(dto.checklistRoundId, dto.checklistRoundRowId, dto.attachments);
  }
);

export const removeFileFromChecklistRoundRowAsync = createAsyncThunk("checklist/round/row/attachment/remove", async (dto: { checklistRoundId: EntityId; fileEntityId: EntityId }) => {
  return removeFileFromChecklistRoundRow(dto.checklistRoundId, dto.fileEntityId);
});

const checklistRowsSlice = createSlice({
  name: "checklistRows",
  initialState: checklistRowsInitialState,
  reducers: {
    removeAllChecklistRows: checklistRowsAdapter.removeAll,
    addOneChecklistRow: checklistRowsAdapter.addOne,
    modifyOneChecklistRow: checklistRowsAdapter.upsertOne,
    modifyChecklistRows: checklistRowsAdapter.upsertMany,
    setChecklistRowTask: (state, action: PayloadAction<{ task: ITask }>) => {
      const checklistRow = state.entities[action.payload.task.checklistRowId];
      if (checklistRow) {
        const isCheckpointList = checklistRow.baseRow && checklistRow.baseRow.checklistRowType === ChecklistRowType.CHECKPOINT_LIST;
        state.entities[action.payload.task.checklistRowId] = {
          ...checklistRow,
          tasks: checklistRow.tasks ? [...checklistRow.tasks.filter((task) => task.id !== action.payload.task.id), action.payload.task] : [action.payload.task],
          description: isCheckpointList ? action.payload.task.description : checklistRow.description
        };
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChecklistRoundWithRowsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchChecklistRoundWithRowsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const rows = action.payload.rows;
        if (rows) {
          checklistRowsAdapter.setAll(state, rows);
        }
      })
      .addCase(saveChecklistRoundRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveChecklistRoundRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        if (action.payload.deleted) {
          checklistRowsAdapter.removeOne(state, action.payload.id);
        } else {
          checklistRowsAdapter.upsertOne(state, action.payload);
        }
      })
      .addCase(saveSubRowForRoundRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveSubRowForRoundRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        checklistRowsAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveSubRowForRoundListRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveSubRowForRoundListRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        checklistRowsAdapter.upsertOne(state, action.payload);
      })
      .addCase(deleteChecklistRoundRowTaskAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(deleteChecklistRoundRowTaskAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const roundRow = Object.values(state.entities).find((row) => row && action.payload && row.id === action.payload.id);
        if (roundRow && action.payload) {
          roundRow.tasks = action.payload.tasks;
        }
      })
      .addCase(attachFilesToChecklistRoundRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(attachFilesToChecklistRoundRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const roundRow = Object.values(state.entities).find((row) => row && action.payload && row.id === action.payload.id);
        if (roundRow && action.payload) {
          roundRow.attachments = [...(roundRow.attachments || []), ...action.payload.attachments];
        }
      })
      .addCase(removeFileFromChecklistRoundRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(removeFileFromChecklistRoundRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const roundRow = Object.values(state.entities).find((row) => row && row.attachments.some((attachment) => attachment.id === action.payload.fileEntityId));
        if (roundRow) {
          roundRow.attachments = roundRow.attachments.filter((attachment) => attachment.id !== action.payload.fileEntityId);
        }
      });
  }
});

export const { addOneChecklistRow, modifyOneChecklistRow, modifyChecklistRows, removeAllChecklistRows, setChecklistRowTask } = checklistRowsSlice.actions;

export const { selectAll: selectAllChecklistRows, selectById: selectChecklistRowById, selectIds: selectChecklistRowIds } = checklistRowsAdapter.getSelectors((state: RootState) => state.checklistRows);

export const selectIsChecklistRowSaving = (state: RootState) => state.checklistRows.status === BhStateStatusType.PENDING;

const selectBaseRowId = (state: RootState, baseRowId: EntityId) => baseRowId;
const selectIsExtraRow = (state: RootState, baseRowId: EntityId, isExtraRow: boolean) => isExtraRow;
export const selectSortedChecklistRowsByBaseRowId = createSelector([selectAllChecklistRows, selectBaseRowId, selectIsExtraRow], (checklistRows, baseRowId, isExtraRow) => {
  return checklistRows
    .filter((row) => {
      return row.checklistBaseVersionRowId === baseRowId && row.extraRow === isExtraRow;
    })
    .sort((a, b) => (a.id > b.id ? 1 : -1));
});

export const selectChecklistRowByBaseRowId = createSelector([selectAllChecklistRows, (state: any, baseRowId: EntityId) => baseRowId], (checklistRows, baseRowId) => {
  return checklistRows.find((row) => {
    return row.checklistBaseVersionRowId === baseRowId;
  });
});
export const selectPreviousChecklistRowType = createSelector([selectAllChecklistRows, (state: any, baseRowId: EntityId) => baseRowId], (checklistRows, baseRowId) => {
  const currentRow = checklistRows.find((row) => row.checklistBaseVersionRowId === baseRowId);
  if (!currentRow || !currentRow.baseRow) return null;

  const previousRow = checklistRows.find((row) => currentRow.baseRow && row.baseRow && row.baseRow.orderNumber === currentRow.baseRow.orderNumber - 1);
  return previousRow?.baseRow?.checklistRowType || null;
});
export const selectNextChecklistRowType = createSelector([selectAllChecklistRows, (state: any, baseRowId: EntityId) => baseRowId], (checklistRows, baseRowId) => {
  const currentRow = checklistRows.find((row) => row.checklistBaseVersionRowId === baseRowId);
  if (!currentRow || !currentRow.baseRow) return null;

  const previousRow = checklistRows.find((row) => currentRow.baseRow && row.baseRow && row.baseRow.orderNumber === currentRow.baseRow.orderNumber + 1);
  return previousRow?.baseRow?.checklistRowType || null;
});
export const selectChecklistRows = createSelector([selectAllChecklistRows, (state: any, checklistRoundId: EntityId) => checklistRoundId], (checklistRows, checklistRoundId) => {
  return checklistRows.find((row) => {
    return row.checklistRoundId === checklistRoundId;
  });
});
export const checkIfAllRequiredRowsFilled = createSelector([selectAllChecklistRows], (checklistRows) => {
  const requiredRows = checklistRows.filter((row) => row.baseRow && row.baseRow.required);
  let result = true;
  requiredRows.forEach((row) => {
    if (row.baseRow && row.baseRow.checklistRowType === ChecklistRowType.OPTION_FIELD) {
      if (!row.optionFieldValue.values.some((choice: any) => !!choice.value)) {
        result = false;
      }
    } else {
      if (!row.fieldValue) {
        result = false;
      }
    }
  });
  return result;
});
export const selectIsLastPlusMinusRow = createSelector([selectAllChecklistRows, (state: any, rowId: EntityId) => rowId], (checklistRows, rowId) => {
  const lastPlusMinusRow = checklistRows
    .filter((row) => row.baseRow && row.baseRow.checklistRowType === ChecklistRowType.PLUSMINUS)
    .reduce((max, row) => (max.baseRow && row.baseRow && max.baseRow.orderNumber > row.baseRow.orderNumber ? max : row));
  return lastPlusMinusRow.id === rowId;
});
export const selectPlusMinusRowsOkTotal = createSelector([selectAllChecklistRows], (checklistRows) => {
  const plusMinusRows = checklistRows.filter((row) => row.baseRow && row.baseRow.checklistRowType === ChecklistRowType.PLUSMINUS);
  const totalsOfRows = plusMinusRows.map((row) => row.plusMinusFieldValue.okTotal);
  return totalsOfRows.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
});
export const selectPlusMinusRowsNotOkTotal = createSelector([selectAllChecklistRows], (checklistRows) => {
  const plusMinusRows = checklistRows.filter((row) => row.baseRow && row.baseRow.checklistRowType === ChecklistRowType.PLUSMINUS);
  const totalsOfRows = plusMinusRows.map((row) => row.plusMinusFieldValue.notOkTotal);
  return totalsOfRows.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
});

export default checklistRowsSlice.reducer;
