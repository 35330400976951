import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boFetchOutstandingSalesInvoicesForDateRangeAsync, boSelectOutstandingInvoicesSorted } from "@/app/store/backofficeSlice";
import BOHeader from "@/views/home/backoffice/views/BOHeader";
import { faReceipt } from "@fortawesome/pro-solid-svg-icons/faReceipt";
import BOInvoicesTable from "@components/backoffice/invoices/BOInvoicesTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ITabCardType } from "@components/backoffice/company/projects/BOCompanyProjectsTabCards";
import BOInvoiceTabCard from "@components/backoffice/invoices/BOInvoiceTabCard";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import { formatDateISO } from "@/utilities/dateUtility";
import BhDatepickerStackedLabel from "@components/input/BhDatepickerStackedLabel";
import { formatCurrency } from "@/utilities/accountingUtilities";

const BOOutstandingInvoices: FC = () => {
  const dispatch = useAppDispatch();
  const outstandingSalesInvoices = useAppSelector(boSelectOutstandingInvoicesSorted);
  const [filter, setFilter] = useState({
    since: "",
    until: formatDateISO(new Date())
  });

  useEffect(() => {
    if (filter) {
      dispatch(boFetchOutstandingSalesInvoicesForDateRangeAsync(filter));
    }
  }, [filter]);

  const outstandingInvoicesSumWithoutVat = outstandingSalesInvoices.reduce((acc, row) => acc + row.salesInvoice.sumWithoutVat, 0) ?? 0;
  const outstandingInvoicesSumWithVat = outstandingSalesInvoices.reduce((acc, row) => acc + row.salesInvoice.sumWithVat, 0) ?? 0;

  const noSalesInvoices = !outstandingSalesInvoices || outstandingSalesInvoices.length === 0;

  const invoiceStatusTabCards = [
    {
      type: ITabCardType.DEFAULT,
      title: "Sum without VAT",
      value: formatCurrency(outstandingInvoicesSumWithoutVat, {
        symbol: "€",
        thousandsSeparator: " ",
        decimalSeparator: ",",
        significantDigits: 2
      }),
      icon: <FontAwesomeIcon icon={faReceipt} className="bh-text-deep-ocean" />
    },
    {
      type: ITabCardType.DEFAULT,
      title: "Sum with VAT",
      value: formatCurrency(outstandingInvoicesSumWithVat, {
        symbol: "€",
        thousandsSeparator: " ",
        decimalSeparator: ",",
        significantDigits: 2
      }),
      icon: <FontAwesomeIcon icon={faReceipt} className="bh-text-deep-ocean" />
    }
  ];

  const updateFilter = (key: string, value: any) => {
    setFilter({ ...filter, [key]: value });
  };

  const handleDateChange = (changedValue: any, key: string) => {
    updateFilter(key, formatDateISO(changedValue.date));
  };

  const handleDateReset = (key: string) => {
    updateFilter(key, "");
  };

  return (
    <div className="w-full px-16 pb-40 sm:px-4">
      <div className="flex items-end justify-between">
        <BOHeader customHeader={<h1>Outstanding invoices</h1>} />
      </div>
      <div className="mt-6 flex w-10/12 gap-10">
        <div className="flex w-full gap-1">
          {invoiceStatusTabCards.map((card) => (
            <BOInvoiceTabCard type={card.type} icon={card.icon} title={card.title} value={card.value} key={card.title} />
          ))}
        </div>
      </div>
      <div className="mt-6 flex flex-wrap items-center justify-between">
        <BhFilterContainer>
          <BhDatepickerStackedLabel
            initialValue={new Date(filter.since)}
            property={"date"}
            placeholder="Since"
            onChangeCallback={(value: any) => handleDateChange(value, "since")}
            onResetCallback={() => handleDateReset("since")}
            label={"Invoice date since"}
          />
          <BhDatepickerStackedLabel
            initialValue={new Date(filter.until)}
            property={"date"}
            placeholder="Until"
            onChangeCallback={(value: any) => handleDateChange(value, "until")}
            onResetCallback={() => handleDateReset("until")}
            label={"Invoice date until"}
          />
        </BhFilterContainer>
      </div>
      <div className="my-4 w-full">
        <h2>Invoices</h2>
        {noSalesInvoices && <p className="bh-text-deep-ocean-60 text-lg">No invoices</p>}
        <div className="w-full">{!noSalesInvoices && <BOInvoicesTable invoiceRows={outstandingSalesInvoices} disabled={true} />}</div>
      </div>
    </div>
  );
};

export default BOOutstandingInvoices;
