import React, { FC } from "react";
import { IProject } from "@/model/IProject";
import BhTableRow from "@components/table/BhTableRow";
import moment from "moment";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import BOProjectDropdown from "@components/backoffice/company/projects/BOProjectDropdown";
import { capitalizeFirstLetter } from "@/utilities/jsUtilities";
import { useAppDispatch } from "@/app/hooks";
import { boCloseProjectAsync, boSaveProjectLanguageAsync, boSuspendProjectAsync } from "@/app/store/backofficeSlice";
import { Authority, Resource } from "@/model/IUserAuthority";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { languageMap } from "@/views/home/profile/ProfileTabs/ProfileUserInfo";
import BhTooltip from "@components/BhTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhDropdown from "@components/dropdown/BhDropdown";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";

interface Props {
  project: IProject;
  setAdministratorsModalOpen: Function;
}

const BOCompanyProjectTableRow: FC<Props> = ({ project, setAdministratorsModalOpen }) => {
  const dispatch = useAppDispatch();
  const projectUsers = Array.from(new Set(project.relatedUsers.map((user) => user.username)));
  const projectAdministrators = project.relatedUsers.filter((user) => user.authority === Authority.ADMIN && user.resource === Resource.PROJECT);
  const suspendProject = () => {
    const projectDTO: IProject = { ...project, suspended: !project.suspended };
    dispatch(boSuspendProjectAsync(projectDTO));
  };
  const closeProject = () => {
    const projectDTO: IProject = { ...project, closed: !project.closed };
    dispatch(boCloseProjectAsync(projectDTO));
  };

  const handleLanguageChange = (language: any) => {
    dispatch(boSaveProjectLanguageAsync({ id: project.id, language: language.value } as IProject));
  };

  return (
    <BhTableRow key={project.id}>
      <td>{project.name}</td>
      <td>{project.status === "IN_PROGRESS" ? "In progress" : "Archived"}</td>
      <td>{moment(project.created).format("DD.MM.YY")}</td>
      <td>{projectUsers.length}</td>
      <td>
        <div className="flex items-center gap-1">
          {projectAdministrators.length} <BhIconButton icon={faEye} buttonProps={{ onClick: () => setAdministratorsModalOpen(project) }} />
        </div>
      </td>
      <td>{capitalizeFirstLetter(project.securityLevel)}</td>
      <td>
        <div className="pl-2">
          <BhCheckbox isChecked={project.suspended} onChange={suspendProject} property="suspended" large={true} />
        </div>
      </td>
      <td>
        <div className="pl-2">
          <BhCheckbox isChecked={project.closed} onChange={closeProject} property="closed" large={true} />
        </div>
      </td>
      <td>
        <div className="flex flex-row items-center gap-x-2">
          <div>{project.billing.type}</div>
          {project.billing.transferDisabled && (
            <div>
              <BhTooltip body={"This subscription cannot be transferred to other projects because it was activated after being closed"}>
                <FontAwesomeIcon icon={faInfoCircle} className="bh-text-error-red" />
              </BhTooltip>
            </div>
          )}
        </div>
      </td>
      <td>{project.billing.billingDay}</td>
      <td>{moment(project.billing.billingDate).format("DD.MM.YYYY")}</td>
      <td>
        <div className="w-[110px]">
          <BhDropdown
            buttonClasses={"w-28"}
            button={
              <button className="min-w-70px bh-border-smoke-40 hover:bh-border-pigeon-70 flex h-9 w-full items-center justify-between rounded border p-2">
                {languageMap[project.language].label}
                <FontAwesomeIcon icon={faCaretDown} />
              </button>
            }
            menu={
              <BhDropdownMenu values={Object.values(languageMap)} closeOnItemClick={true} type={BhDropdownTypeEnum.STRING} onSelect={handleLanguageChange} textProperty={"label"} widthClass={"w-28"} />
            }
          />
        </div>
      </td>
      <td>
        <div className="relative flex justify-center ">
          <BOProjectDropdown project={project} />
        </div>
      </td>
    </BhTableRow>
  );
};

export default BOCompanyProjectTableRow;
