import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import BhNewTagWithDropdown from "@components/tags/BhNewTagWithDropdown";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { TaskStatus } from "@/model/taskBoard/ITask";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";

interface Props {
  row: any;
  saveCallback: Function;
  disabled: boolean;
}

const FormProtocolFieldTaskStatus: FC<Props> = ({ row, disabled, saveCallback }) => {
  const { t } = useTranslation();
  const showTaskStatusTag = !disabled && row.task && row.task.status;
  const showTaskStatusDropdown = !disabled && (!row.task || !row.task.status);

  const statusClassesMap: Record<string, { backgroundColorClass: string; textColorClass: string; statusName: string; dropdownValue: string }> = {
    TODO: { backgroundColorClass: "bh-bg-dull-grey-10", textColorClass: "bh-text-dull-grey", statusName: "TODO", dropdownValue: t("TASK.TODO") },
    IN_PROGRESS: { backgroundColorClass: "bh-bg-mediterranean-10", textColorClass: "bh-text-mediterranean", statusName: "IN_PROGRESS", dropdownValue: t("TASK.IN_PROGRESS") },
    NEEDS_APPROVAL: { backgroundColorClass: "bh-bg-warning-yellow-20", textColorClass: "bh-text-warning-yellow-10-dark", statusName: "NEEDS_APPROVAL", dropdownValue: t("TASK.NEEDS_APPROVAL") },
    DONE: { backgroundColorClass: "bh-bg-lush-10", textColorClass: "bh-text-lush", statusName: "DONE", dropdownValue: t("TASK.DONE") },
    ARCHIVED: { backgroundColorClass: "bh-bg-rhino-10 hover:bh-bg-rhino-20", textColorClass: "bh-text-rhino", statusName: "ARCHIVED", dropdownValue: t("TASK.ARCHIVED") }
  };

  const statusDropdownValues = [
    { statusName: TaskStatus.TODO, text: t("TASK.TODO") },
    { statusName: TaskStatus.IN_PROGRESS, text: t("TASK.IN_PROGRESS") },
    { statusName: TaskStatus.NEEDS_APPROVAL, text: t("TASK.NEEDS_APPROVAL") },
    { statusName: TaskStatus.DONE, text: t("TASK.DONE") }
  ];

  const saveStatus = (changedObject: any) => {
    const statusObject = { status: changedObject.statusName };
    saveCallback(statusObject);
  };

  const statusTagDropdownValues = Object.values(statusClassesMap).filter((value) => value.statusName !== TaskStatus.ARCHIVED);

  return (
    <>
      {showTaskStatusTag && (
        <BhNewTagWithDropdown
          dropdownPosition={BhDropdownPositionEnum.BOTTOM_LEFT}
          tagText={t("TASK." + (row.task && row.task.status ? row.task.status : TaskStatus.TODO))}
          values={statusTagDropdownValues}
          textProperty={"dropdownValue"}
          onSelect={saveStatus}
          textColorClass={statusClassesMap[row.task ? row.task.status : TaskStatus.TODO].textColorClass}
          backgroundColorClass={statusClassesMap[row.task ? row.task.status : TaskStatus.TODO].backgroundColorClass}
          disabled={disabled}
        />
      )}
      {showTaskStatusDropdown && (
        <BhDropdown
          button={<BhTextOnlyButton buttonProps={{ classes: "bh-hover-item-opacity" }}>{t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.STATUS")}</BhTextOnlyButton>}
          menu={
            <BhDropdownMenu
              values={statusDropdownValues}
              textProperty={"text"}
              type={BhDropdownTypeEnum.STRING}
              onSelect={saveStatus}
              widthClass={"w-fit whitespace-nowrap"}
              closeOnItemClick={true}
              translateValues={true}
            />
          }
          position={BhDropdownPositionEnum.BOTTOM_LEFT}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default FormProtocolFieldTaskStatus;
