import React, { FC, useEffect, useState } from "react";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import BhTableRow from "@components/table/BhTableRow";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import BOHeader from "@/views/home/backoffice/views/BOHeader";
import { boFetchSalesInvoiceReportPresignedUrl, boFetchSalesInvoiceReports, boRunSalesInvoiceReportsJob } from "@/api/backoffice/boInvoicesAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { ISalesInvoiceReport, SalesInvoiceReportType } from "@/model/billing/ISalesInvoiceReport";
import { formatDate } from "@/utilities/dateUtility";
import BhSelectInputFilter from "@components/filters/BhSelectInputFilter";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";

const BOSalesInvoiceReports: FC = () => {
  const [reports, setReports] = useState<Array<ISalesInvoiceReport>>([]);
  const [filter, setFilter] = useState({ type: "" as SalesInvoiceReportType });
  const [filteredAndSortedReports, setFilteredAndSortedReports] = useState<Array<ISalesInvoiceReport>>([]);

  useEffect(() => {
    boFetchSalesInvoiceReports().then((result) => {
      setReports(result);
    });
  }, []);

  useEffect(() => {
    const reportsFilteredAndSorted = filterAndSortReports(reports);
    setFilteredAndSortedReports(reportsFilteredAndSorted);
  }, [filter, reports]);

  const filterAndSortReports = (reportsArray: Array<ISalesInvoiceReport>) => {
    const filteredReports = !filter.type ? reportsArray : reportsArray.filter((report) => report.type === filter.type);
    return filteredReports.sort((a, b) => (new Date(a.generated) < new Date(b.generated) ? 1 : -1));
  };

  const [showRunJobConfirmationModal, hideRunJobConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideRunJobConfirmationModal}
      header={<h2>Invoice report generation</h2>}
      body={<div>You’re about to run an invoice report generation job. This will generate new reports and send them to the configured recipients based on today’s status.</div>}
      confirmationButtonText={"Run job"}
      handleDelete={() => {
        boRunSalesInvoiceReportsJob();
        hideRunJobConfirmationModal();
      }}
    />
  ));

  const tableColumnHeaders: Array<IBhTableHeader<any>> = [
    {
      id: 0,
      content: "Name",
      classes: ""
    },
    {
      id: 1,
      content: "Type",
      classes: "w-20"
    },
    { id: 2, content: "Generated", classes: "w-44", field: "generated" }
  ];

  const download = (report: any) => {
    boFetchSalesInvoiceReportPresignedUrl(report).then((result) => {
      imitateUrlDownload(result.value);
    });
  };

  const reportTypeMap: Record<SalesInvoiceReportType, string> = {
    MONTHLY_DOUBTFUL_INVOICES: "Doubtful",
    MONTHLY_OUTSTANDING_INVOICES: "Outstanding",
    MONTHLY_RECOVERED_INVOICES: "Recovered"
  };

  const onTypeFilterSelect = (changedValue: any) => {
    setFilter({ type: changedValue.property });
  };

  const onTypeFilterReset = () => {
    setFilter({ type: "" as SalesInvoiceReportType });
  };

  return (
    <div className="w-full px-16 pb-40 sm:px-4">
      <BOHeader
        customHeader={
          <div className="flex w-full flex-row items-center justify-between">
            <h1>Reports</h1>
            <div>
              <BhSecondaryButton buttonProps={{ onClick: showRunJobConfirmationModal }}>Run invoice generation</BhSecondaryButton>
            </div>
          </div>
        }
      />
      <BhFilterContainer>
        <BhSelectInputFilter
          values={[
            {
              property: SalesInvoiceReportType.MONTHLY_DOUBTFUL_INVOICES,
              value: reportTypeMap[SalesInvoiceReportType.MONTHLY_DOUBTFUL_INVOICES]
            },
            {
              property: SalesInvoiceReportType.MONTHLY_OUTSTANDING_INVOICES,
              value: reportTypeMap[SalesInvoiceReportType.MONTHLY_OUTSTANDING_INVOICES]
            },
            {
              property: SalesInvoiceReportType.MONTHLY_RECOVERED_INVOICES,
              value: reportTypeMap[SalesInvoiceReportType.MONTHLY_RECOVERED_INVOICES]
            }
          ]}
          currentValue={reportTypeMap[filter.type]}
          onSelect={onTypeFilterSelect}
          placeholder={"Report type"}
          textProperty={"value"}
          onReset={onTypeFilterReset}
        />
      </BhFilterContainer>
      <table className="h-full w-full">
        <BhTableHeader columns={tableColumnHeaders} sortedBy={"generated"} reversed={true} />
        <tbody>
          {filteredAndSortedReports &&
            filteredAndSortedReports.length > 0 &&
            filteredAndSortedReports.map((report) => {
              return (
                <BhTableRow key={report.id}>
                  <td>
                    <div className="flex flex-row items-center gap-x-2">
                      <BhFileCardIcon fileEntity={{ id: report.id, name: report.name, type: FileEntityType.FORM } as IFileEntity} fileCardSize={FileCardSize.LARGE} />
                      <div className="hover:cursor-pointer hover:underline" onClick={() => download(report)}>
                        {report.name}
                      </div>
                    </div>
                  </td>
                  <td>{reportTypeMap[report.type]}</td>
                  <td>{formatDate(new Date(report.generated))}</td>
                </BhTableRow>
              );
            })}
          {!filteredAndSortedReports ||
            (filteredAndSortedReports.length === 0 && (
              <BhTableRow>
                <td colSpan={3} className="text-center">
                  No reports found
                </td>
              </BhTableRow>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default BOSalesInvoiceReports;
