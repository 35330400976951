import React, { FC } from "react";
import { Link } from "react-router-dom";
import { classNames } from "@/utilities/jsUtilities";
import { useAppSelector } from "@/app/hooks";
import { BauhubPlan, ProjectStatus } from "@/model/IProject";
import ProjectListItemTag from "@/features/projectList/projectListItem/ProjectListItemTag";
import ProjectListNotificationButton from "@/features/projectList/projectListItem/ProjectListNotificationButton";
import ProjectListItemPin from "@/features/projectList/projectListItem/ProjectListItemPin";
import BhProjectStatusBadge from "@components/badge/BhProjectStatusBadge";
import { EntityId } from "@reduxjs/toolkit";
import { selectCompanyById, selectProjectById } from "@/app/store/companiesSlice";
import BhCardContainer from "@components/cards/BhCardContainer";
import { useTranslation } from "react-i18next";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import { isMaruProject } from "@/utilities/customClients/maruUtilities";
import ProjectListSignatureButton from "@/features/projectList/projectListItem/ProjectListSignatureButton";
import { addYearsToDate, formatDate } from "@/utilities/dateUtility";

interface Props {
  projectId: EntityId;
  isCompanySettingsView?: boolean;
}

const ProjectListItem: FC<Props> = ({ projectId, isCompanySettingsView }) => {
  const { t } = useTranslation();
  const project = useAppSelector((state) => selectProjectById(state, projectId));
  const company = useAppSelector((state) => project && selectCompanyById(state, project.companyId));

  if (!project || !company) {
    return null;
  }

  const isArchived = project.status === ProjectStatus.ARCHIVED ? " bh-text-pigeon-70 bh-bg-smoke " : " bh-text-deep-ocean-80 ";
  const isMaru = isMaruProject(project);

  const showPinButton = !isCompanySettingsView;

  const showToBeFrozenLabel = project.status === ProjectStatus.ARCHIVED && !project.frozen && project.archivedBillingModel === BauhubPlan.LIGHT && project.plan === BauhubPlan.LIGHT;
  const toBeFrozenDate = showToBeFrozenLabel && project.archivedDate ? formatDate(addYearsToDate(new Date(project.archivedDate), 2)) : "";
  const showFrozenLabel = project.status === ProjectStatus.ARCHIVED && project.frozen && project.archivedBillingModel === BauhubPlan.LIGHT && project.plan === BauhubPlan.LIGHT;

  return (
    <BhCardContainer classes={isArchived}>
      <Link className="w-full cursor-pointer" to={`/project/${project.id}/overview`}>
        <div className="group flex items-center transition duration-300">
          <BhProjectStatusBadge type={project.securityLevel} />
          <div className="flex flex-1 flex-wrap items-center p-6 transition duration-300">
            <BhLargeBoldText classes={classNames(isArchived, "mr-3")}>
              {isMaru && <span className="pr-2">{project.contractNumber}</span>}
              {project.name}
            </BhLargeBoldText>
            <ProjectListItemTag project={project} projectFrozenDate={showFrozenLabel ? project.frozenDate : undefined} />
            {showToBeFrozenLabel && toBeFrozenDate && <div className="text-12px bh-text-deep-ocean-80 leading-4">{t("PROJECT.LIST.TO_BE_FROZEN") + " " + toBeFrozenDate}</div>}
          </div>
          <div className="mr-4 flex flex-row">
            <div className="my-6 mr-4 w-10">
              <ProjectListSignatureButton projectId={project.id} />
            </div>
            <div className="my-6 mr-4 w-10">
              <ProjectListNotificationButton projectId={project.id} />
            </div>
          </div>
          <div className="mr-4 flex w-36 transition duration-300 sm:hidden">
            <p className="text-center">
              {t("GLOBAL.ORDER_BY.CREATED")}: {formatDate(project.created)}
            </p>
          </div>
          {showPinButton && <ProjectListItemPin projectId={project.id} classes="px-2 mr-3 w-14" />}
        </div>
      </Link>
    </BhCardContainer>
  );
};

export default ProjectListItem;
