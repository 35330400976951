import { ThunkDispatch } from "@reduxjs/toolkit";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { IFormDataSaveRequest } from "@/model/IForm";

export const createObjectId = (m = Math, d = Date, h = 16, s = (s: any) => m.floor(s).toString(h)) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

export const findProtocolNextMainRowNumber = (currentRowNumber: string) => {
  const currentRowNumberString = currentRowNumber !== undefined ? currentRowNumber.toString() : "";
  // Check if the input is a valid number
  if (typeof currentRowNumberString !== "string" || isNaN(parseInt(currentRowNumberString, 10))) {
    return "";
  }

  // Parse the whole number part, regardless of decimals
  let wholeNumber = Math.floor(parseFloat(currentRowNumberString));

  // Increment and return the result as a string
  return (wholeNumber + 1).toString();
};

export const findProtocolNextSubRowNumber = (currentRowNumber: string) => {
  const currentRowNumberString = currentRowNumber !== undefined ? currentRowNumber.toString() : "";
  // Check if the input is a valid number (whole or decimal)
  if (typeof currentRowNumberString !== "string" || isNaN(parseFloat(currentRowNumberString))) {
    return "";
  }

  // Split the string by the decimal point
  const parts = currentRowNumberString.split(".");

  // If it's a whole number, return it with ".1"
  if (parts.length === 1) {
    let wholeNumber = parseInt(parts[0], 10);
    return wholeNumber + ".1";
  }

  // If it has a decimal part, increment the decimal part
  if (parts.length === 2) {
    let wholeNumber = parseInt(parts[0], 10);
    let decimalNumber = parseInt(parts[1], 10);
    return wholeNumber + "." + (decimalNumber + 1).toString();
  }

  // If the input doesn't match expected format, return an empty string
  return "";
};

export const getFieldType = (value: any) => {
  if (typeof value === "boolean") {
    return "CHECKBOX";
  }
  if (typeof value === "string") {
    return "TEXT";
  }
};

export const generateMockScmData = () => {
  return {
    firstAid: {
      plus: 0,
      minus: 0
    },
    fire: {
      plus: 0,
      minus: 0
    },
    sign: {
      plus: 0,
      minus: 0
    },
    fall: {
      plus: 0,
      minus: 0
    },
    scaffolding: {
      plus: 0,
      minus: 0
    },
    ladder: {
      plus: 0,
      minus: 0
    },
    connecting: {
      plus: 0,
      minus: 0
    },
    lightning: {
      plus: 0,
      minus: 0
    },
    installations: {
      plus: 0,
      minus: 0
    },
    lifting: {
      plus: 0,
      minus: 0
    },
    digging: {
      plus: 0,
      minus: 0
    },
    materials: {
      plus: 0,
      minus: 0
    },
    protection: {
      plus: 0,
      minus: 0
    },
    rooms: {
      plus: 0,
      minus: 0
    },
    overall: {
      plus: 0,
      minus: 0
    },
    environment: {
      plus: 0,
      minus: 0
    },
    legal: {
      plus: 0,
      minus: 0
    }
  };
};

export const validateAndParseNumberValue = (
  value: {
    number: any;
  },
  saveCallback: Function,
  initialValue: any,
  dispatch: ThunkDispatch<any, any, any>,
  inputRef: any
) => {
  const regex = new RegExp("^[0-9]+$");
  if (regex.test(value.number)) {
    const parsedValue = { number: parseInt(value.number) };
    if (parsedValue.number === initialValue) return;
    saveCallback({ changes: parsedValue } as IFormDataSaveRequest);
  } else {
    dispatch(
      toastFlagAdded({
        id: uuidv4(),
        type: BauhubBannerType.ERROR,
        disappear: true,
        translateCode: "FORM.NUMBER.INVALID_INSERT"
      })
    );
    if (inputRef && inputRef.current) inputRef.current.value = initialValue;
  }
};
