import React, { FC } from "react";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BOFormsCopyToLanguage from "@/views/home/backoffice/views/forms/BOFormsCopyToLanguage";

const BOForms: FC = () => {
  return (
    <BhScrollableBody>
      <div className="px-16 sm:px-4">
        <h2>Forms</h2>
        <BOFormsCopyToLanguage />
      </div>
    </BhScrollableBody>
  );
};

export default BOForms;
