import React, { FC } from "react";
import { ICompany } from "@/model/ICompany";

import { boSaveCompanyBillingAsync, boSelectCompanyBilling, boSelectCompanyBillingLoadingState } from "@/app/store/backofficeSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import BOMaruFinbiteAuthCode from "@components/backoffice/company/BOMaruFinbiteAuthCode";
import BOCompanyAndBillingGeneral from "@/components/backoffice/company/companyAndBilling/BOCompanyAndBillingGeneral";
import BOCompanyBillingSettings from "@components/backoffice/company/companyAndBilling/BOCompanyBillingSettings";
import BOCompanyConsolidatedInvoiceSettings from "@components/backoffice/company/companyAndBilling/BOCompanyConsolidatedInvoiceSettings";
import BOCompanyKillswitch from "@components/backoffice/company/companyAndBilling/BOCompanyKillswitch";
import BOCompanyCompanyAdministrators from "@/components/backoffice/company/companyAndBilling/BOCompanyCompanyAdministrators";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";

interface Props {
  company: ICompany;
}

const BOCompanyAndBillingInfoTab: FC<Props> = ({ company }) => {
  const companyBilling = useAppSelector(boSelectCompanyBilling);
  const dispatch = useAppDispatch();
  const isMaruCompany = company?.modules?.some((m) => m.module === "MR_BUDGET");
  const billingLoading = useAppSelector(boSelectCompanyBillingLoadingState);

  const handleBillingChange = (changedObj: Record<string, any>) => {
    const changedCompanyBilling = { ...companyBilling, ...changedObj };
    dispatch(boSaveCompanyBillingAsync(changedCompanyBilling));
  };

  return (
    <div className="m-auto max-w-[1500px] px-44 py-10 xl:px-8">
      <BOCompanyAndBillingGeneral />
      <BhPigeon20Separator />
      {isMaruCompany && <BOMaruFinbiteAuthCode />}
      {isMaruCompany && <BhPigeon20Separator />}
      <BOCompanyCompanyAdministrators companyId={company.id} />
      <BhPigeon20Separator classes="mb-4 mt-0" />
      {billingLoading !== BhStateStatusType.SUCCESS && <div className="bh-bg-pigeon-50 mx-2 my-6 h-5 w-2/3 animate-pulse rounded" />}
      {billingLoading === BhStateStatusType.SUCCESS && (
        <>
          <BOCompanyBillingSettings onFieldChangeCallback={handleBillingChange} />
          <BhPigeon20Separator />
          <BOCompanyConsolidatedInvoiceSettings onFieldChangeCallback={handleBillingChange} />
          <BhPigeon20Separator />
          <BOCompanyKillswitch />
        </>
      )}
    </div>
  );
};

export default BOCompanyAndBillingInfoTab;
