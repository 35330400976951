import React from "react";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import BOUsersTableRow from "@components/backoffice/users/BOUsersTableRow";
import { useAppSelector } from "@/app/hooks";
import { boSelectUsers } from "@/app/store/backofficeSlice";

const BOUsersTable = () => {
  const users = useAppSelector(boSelectUsers);
  const usersExist = users && users.length > 0;
  const usersTableHeaders: Array<IBhTableHeader<any>> = [
    { id: 0, content: "Name", field: "", sortable: true },
    { id: 1, content: "E-mail", field: "" },
    { id: 2, content: "Phone", field: "" },
    { id: 3, content: "Language", field: "" },
    { id: 4, content: "eID", field: "", classes: "text-center w-1" },
    { id: 5, content: "2FA", field: "", classes: "text-center w-1" },
    { id: 6, content: "", field: "" }
  ];

  return (
    <>
      {usersExist && (
        <table className="mt-4 w-full">
          <BhTableHeader columns={usersTableHeaders} />
          <tbody>
            {users.map((user) => (
              <BOUsersTableRow key={user.id} user={user} />
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default BOUsersTable;
