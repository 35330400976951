import React, { FC, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BOTransferAuthsTable from "@components/backoffice/users/BOTransferAuthsTable";
import { useAppDispatch } from "@/app/hooks";
import { boFindAllUsersLikeAsync } from "@/app/store/backofficeSlice";
import { IBoAdminUserResponse } from "@/model/backoffice/users/IBoAdminUserResponse";

interface Props {
  setIsShown: (value: boolean) => void;
  selectedUser: IBoAdminUserResponse;
}

const BOTransferAuthsModal: FC<Props> = ({ setIsShown, selectedUser }) => {
  const dispatch = useAppDispatch();
  const [users, setUsers] = useState<Array<IBoAdminUserResponse>>();
  const usersExists = users && users.length > 0;
  const onChange = (query: string) => {
    dispatch(boFindAllUsersLikeAsync(query)).then((action) => {
      const foundUsers = action.payload as Array<IBoAdminUserResponse>;
      setUsers(foundUsers);
    });
  };

  return (
    <BhModal header={<h2>Transfer authorities</h2>} size="4xl" footer={<BhModalFooter onCancel={() => setIsShown(false)} cancelButtonText="Close" />} setIsShown={setIsShown}>
      <div className="min-h-120 h-full space-y-4 py-8 px-12">
        <p className="bh-text-18">To whom you want to transfer these authorities</p>
        <div className="flex items-center">
          <BhSearchInputWBG property={"query"} inputClasses="w-72" debounceLength={1000} onChangeCallback={(changedVal: any) => onChange(changedVal.query)} placeholder="Search users" />
        </div>
        <div className="h-full overflow-auto">{usersExists && <BOTransferAuthsTable users={users} selectedUser={selectedUser} />}</div>
      </div>
    </BhModal>
  );
};

export default BOTransferAuthsModal;
