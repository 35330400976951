import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { CompanyInvoiceType, IBillingModel, ProjectInvoiceType } from "@/model/IBillingInfo";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";
import { IProjectOrder } from "@/model/billing/IProjectOrder";
import { activateProjectSubscription, fetchProjectOrders, getProjectActivationBillingModel } from "@/api/billingAPI";
import { IProjectBilling } from "@/model/billing/IProjectBilling";
import BillingForm from "@/views/home/project/detail/projectSettings/BillingForm";
import { CompanyStatus } from "@/model/ICompany";
import { formatDate } from "@/utilities/dateUtility";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { isValidEmails } from "@/utilities/jsUtilities";

interface Props {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  projectOrder: IProjectOrder;
  setProjectOrders?: React.Dispatch<React.SetStateAction<IProjectOrder[]>>;
  isProjectFrozen?: boolean;
}

const CompanySettingsActivateProjectModal: FC<Props> = ({ isShown, setIsShown, projectOrder, setProjectOrders, isProjectFrozen }) => {
  const company = useAppSelector((state) => selectCompanyById(state, projectOrder.companyBillingRequisites?.companyId ?? 0));
  const [projectBilling, setProjectBilling] = useState<IProjectBilling>(projectOrder.projectBilling);
  const [billingModel, setBillingModel] = useState<IBillingModel>({} as IBillingModel);
  const { t } = useTranslation();
  const companyHasMonthlyAggregateInvoice = projectOrder.companyBillingRequisites?.invoiceType === CompanyInvoiceType.AGGREGATE;

  useEffect(() => {
    if (company && isShown) {
      getProjectActivationBillingModel(company.id, projectOrder.projectBilling.projectId).then((billingModel) => {
        setBillingModel(billingModel);
      });
    }
  }, [isShown]);

  useEffect(() => {
    if (projectOrder.projectBilling && isProjectFrozen) {
      setProjectBilling({
        ...projectOrder.projectBilling,
        type: ProjectInvoiceType.YEAR,
        transferDisabled: true
      });
    }
  }, []);

  const getProjectBillingWithCompanyBillingValues = () => {
    return {
      ...projectBilling,
      customerRecipientEmails: projectOrder.companyBillingRequisites!.customerRecipientEmails,
      customerRecipient: projectOrder.companyBillingRequisites!.customerRecipient,
      customerAddress: projectOrder.companyBillingRequisites!.customerAddress,
      customerRegCode: projectOrder.companyBillingRequisites!.customerRegCode,
      customerVatNumber: projectOrder.companyBillingRequisites!.customerVatNumber,
      customerAdditionalInfo: projectOrder.companyBillingRequisites!.customerAdditionalInfo
    };
  };

  const onActivate = async () => {
    setIsShown(false);
    if (projectOrder.companyBillingRequisites) {
      const companyHasConflictingInvoiceType = projectBilling?.type === ProjectInvoiceType.MONTH && companyHasMonthlyAggregateInvoice;
      const projectBillingDto = companyHasConflictingInvoiceType ? getProjectBillingWithCompanyBillingValues() : projectBilling;
      activateProjectSubscription(projectBillingDto).then(() => {
        if (setProjectOrders && projectOrder.companyBillingRequisites?.companyId) {
          fetchProjectOrders(projectOrder.companyBillingRequisites?.companyId).then((projectOrders) => {
            setProjectOrders(projectOrders);
          });
        }
      });
    }
  };

  const onFormChange = (changedValue: IProjectBilling) => {
    setProjectBilling((prevState) => {
      return { ...prevState, ...changedValue };
    });
  };

  const onCancel = () => {
    setIsShown(false);
    setProjectBilling(projectOrder.projectBilling);
  };

  const isSaveButtonDisabled =
    !projectBilling.customerRecipientEmails ||
    projectBilling.customerRecipientEmails.length === 0 ||
    !isValidEmails(projectBilling.customerRecipientEmails) ||
    !projectBilling.customerRecipient ||
    projectBilling.customerRecipient.length === 0 ||
    (!projectBilling.customerCivilian && (!projectBilling.customerRegCode || projectBilling.customerRegCode.length === 0));

  if (!company) {
    return null;
  }

  return (
    <BhModal
      isShown={isShown}
      setIsShown={setIsShown}
      size={"2xl"}
      onClose={onCancel}
      header={<h2>{t("PROJECT.ACTIVATION.MODAL.HEADER")}</h2>}
      children={
        <BhScrollableBody>
          <div className="px-8">
            <BillingForm
              billingForm={projectBilling}
              onChange={onFormChange}
              companyBillingRequisites={projectOrder.companyBillingRequisites}
              companyHasMonthlyAggregateInvoice={companyHasMonthlyAggregateInvoice}
              companyHasTrial={company?.status === CompanyStatus.TRIAL}
              companyTrialEnd={formatDate(company.trialEnd)}
              billingModel={billingModel && billingModel}
              projectName={projectOrder.name}
              isProjectFrozen={isProjectFrozen}
            />
          </div>
        </BhScrollableBody>
      }
      footer={<BhModalFooter onCancel={onCancel} onConfirm={onActivate} confirmDisabled={isSaveButtonDisabled} confirmButtonText={t("PROJECT.ACTIVATE") as string} />}
    />
  );
};

export default CompanySettingsActivateProjectModal;
