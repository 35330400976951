import React, { FC, useEffect, useState } from "react";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";

interface Props {
  initialValue: string;
  property: string;
  values: Array<any>;
  onSelect: Function;
  placeholder?: string;
  label?: string;
  required?: boolean;
}

const BhInputWithDropdown: FC<Props> = ({ initialValue, placeholder, values, onSelect, property, label, required }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValues, setDropdownValues] = useState(values || []);
  const outsideClickRef = useOuterClick(() => {
    setIsDropdownOpen(false);
  });

  useEffect(() => {
    setDropdownValues(values);
  }, [values]);

  const filterValues = (filter: any) => {
    const filteredValues = values.filter((val: any) => {
      return val[property].toLowerCase().includes(filter.text.toLowerCase());
    });
    setDropdownValues(filteredValues);
    setIsDropdownOpen(filteredValues.length > 0);
  };

  const onSelectAction = (selectedObject: any) => {
    setIsDropdownOpen(false);
    setDropdownValues(values || []);
    onSelect(selectedObject);
  };

  return (
    <div className="relative w-full" onBlur={() => setIsDropdownOpen(false)}>
      <div className="relative">
        <div className="w-full">
          <BhInputStackedLabel
            inputRef={outsideClickRef}
            initialValue={initialValue}
            property={"text"}
            placeholder={placeholder}
            onChangeCallback={filterValues}
            label={label}
            onFocus={() => setIsDropdownOpen(true)}
            required={required}
          />
        </div>
        <div className="absolute bottom-2 right-3">
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      </div>
      {isDropdownOpen && dropdownValues.length > 0 && (
        <div className="absolute z-50 min-w-full max-w-[500px]" onMouseDown={(e) => e.preventDefault()}>
          <BhFixedDropdownParent>
            <BhDropdownMenu type={BhDropdownTypeEnum.STRING} textProperty={property} values={dropdownValues} onSelect={onSelectAction} customDropdown={true} widthClass="w-full" />
          </BhFixedDropdownParent>
        </div>
      )}
    </div>
  );
};
export default React.memo(BhInputWithDropdown);
