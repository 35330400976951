import React, { FC } from "react";
import BhTableRow from "@components/table/BhTableRow";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { IUser } from "@/model/IUser";
import BhTagListContainer from "@components/tags/BhTagListContainer";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { formatDateTime } from "@/utilities/dateUtility";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentUser } from "@/app/store/userSlice";
import { IBoCompanyUser } from "@/model/companyUsers/IBoCompanyUser";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { EntityId } from "@reduxjs/toolkit";
import { boChangeCompanyAdministratorAsync, boChangeCompanyAdminUserInviteAsync, boRemoveCompanyAdminInviteAsync, boRemoveCompanyAdministratorAsync } from "@/app/store/backofficeSlice";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import BhTooltip from "@components/BhTooltip";

interface Props {
  user: IBoCompanyUser | ICompanyInvitedUser;
  rowClasses?: string;
  companyId: EntityId;
}

const BOCompanyAdministratorRow: FC<Props> = ({ user, rowClasses, companyId }) => {
  const inviteDateExists = user && !user.userExists && (user as ICompanyInvitedUser).inviteSentAt;
  const inviteDate = inviteDateExists && (user as ICompanyInvitedUser).inviteSentAt;
  const contactNumberExists = user && user.userExists && (user as IBoCompanyUser).contactNumber;
  const contactNumber = contactNumberExists ? (user as IBoCompanyUser).contactNumber : "";
  const titleExists = user && user.userExists && (user as IBoCompanyUser).title;
  const title = titleExists ? (user as IBoCompanyUser).title : "";
  const currentUser = useAppSelector(selectCurrentUser);
  const notOwnUser = user.username !== currentUser.username;
  const dispatch = useAppDispatch();
  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>Are you sure you want to remove a company administrator?</h2>}
      body={"Administrator authorities will be deleted."}
      confirmationButtonText={"Delete"}
      handleDelete={() => {
        if (user?.userExists) {
          dispatch(boRemoveCompanyAdministratorAsync({ companyId: companyId, userId: (user as ICompanyUser).userEntityId }));
        } else {
          dispatch(boRemoveCompanyAdminInviteAsync(user as ICompanyInvitedUser));
        }
        hideDeleteConfirmationModal();
      }}
    />
  ));

  const onAdminChange = (changedValue: any) => {
    const changedObject = { ...user, ...changedValue } as ICompanyUser;
    if (user?.userExists) {
      dispatch(boChangeCompanyAdministratorAsync({ companyId: companyId, changedUser: changedObject }));
    } else {
      const request = {
        username: changedObject.username,
        accountManager: changedObject.accountManager,
        newProjectDefaultAdmin: changedObject.newProjectDefaultAdmin,
        checklistsManager: changedObject.checklistsManager,
        formsManager: changedObject.formsManager
      };
      dispatch(boChangeCompanyAdminUserInviteAsync({ companyId: companyId, request: request }));
    }
  };

  return (
    <BhTableRow classes={rowClasses}>
      <td>
        <div className="flex items-center gap-4">
          <div className="relative inline-block">
            {user.userExists && <BhUserIconWithName user={user as unknown as IUser} adminBadgeVisible={true} />}
            {inviteDate && (
              <div>
                <BhTagListContainer>
                  <BhTag type={BhTagType.TAG}>Invite pending</BhTag>
                </BhTagListContainer>
                <div>Invite sent {formatDateTime(inviteDate)}</div>
              </div>
            )}
          </div>
        </div>
      </td>
      <td>
        <a className="bh-underline-link font-weight-600" href={"mailto:" + user.username}>
          {user.username}
        </a>
      </td>
      <td>
        <p>{contactNumber}</p>
      </td>
      <td>
        <p>{title}</p>
      </td>
      <td>{notOwnUser && <BhToggleSwitch value={user.accountManager} property="accountManager" onClickAction={onAdminChange} />}</td>
      <td>
        <BhToggleSwitch value={user.checklistsManager} property="checklistsManager" onClickAction={onAdminChange} />
      </td>
      <td>
        <BhToggleSwitch value={user.newProjectDefaultAdmin} property="newProjectDefaultAdmin" onClickAction={onAdminChange} />
      </td>
      <td>
        {notOwnUser && (
          <BhTooltip body="Remove administrator">
            <BhIconButton icon={faCircleMinus} buttonProps={{ onClick: showDeleteConfirmationModal }} />
          </BhTooltip>
        )}
      </td>
    </BhTableRow>
  );
};

export default BOCompanyAdministratorRow;
