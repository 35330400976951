import React, { FC, useState } from "react";
import BhWysiwygEditor from "@components/wysiwyg/BhWysiwygEditor";
import { useTranslation } from "react-i18next";

interface Props {
  fieldValue: any;
  disabled?: boolean;
  saveFieldCallback: Function;
}

const FormBaseWysiwygField: FC<Props> = ({ fieldValue, saveFieldCallback, disabled }) => {
  const { t } = useTranslation();
  const [editable, setEditable] = useState(false);

  const saveFunction = (value: any) => {
    saveFieldCallback("html", value.changes.html).finally(() => {
      setEditable(false);
    });
  };

  const innerHtml = fieldValue.html ? fieldValue.html : '<div class="bh-text-pigeon cursor-pointer">' + t("FORMS.TEXT_FIELD_PLACEHOLDER") + "</div>";

  return (
    <div
      className="flex w-full flex-col"
      onClick={() => {
        !disabled && setEditable(true);
      }}
    >
      {!editable && <div className="wysiwyg-output" dangerouslySetInnerHTML={{ __html: innerHtml }}></div>}
      {editable && !disabled && <BhWysiwygEditor initialValue={fieldValue.html} property={"html"} saveCallback={saveFunction} latoFont={true} />}
    </div>
  );
};

export default FormBaseWysiwygField;
