import React, { FC, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { Trans } from "react-i18next";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownButton from "@components/dropdown/BhDropdownButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "i18next";
import TimeAgo from "javascript-time-ago";
import { setDefaultLocale } from "react-datepicker";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BhBauhubLogo from "@components/logo/BhBauhubLogo";
import { languageMap } from "@/views/home/profile/ProfileTabs/ProfileUserInfo";
import { urlParamsToObject } from "@/utilities/jsUtilities";
import { languageStore } from "@/views/authentication/RequireAuth";
import lt from "javascript-time-ago/locale/lt.json";
import lv from "javascript-time-ago/locale/lv.json";
import fi from "javascript-time-ago/locale/fi.json";

interface Props {
  withoutSignInButton?: boolean;
}

const PublicNavbar: FC<Props> = ({ withoutSignInButton }) => {
  const locationState = useLocation();
  const { lang } = urlParamsToObject(locationState.search);
  const [publicLanguage, setPublicLanguage] = useLocalStorage<string>("publicLanguageCode", lang !== undefined ? lang : "en_EN");
  const navigate = useNavigate();

  useEffect(() => {
    if (publicLanguage && publicLanguage.length > 0) {
      const lang = publicLanguage.split("_")[0];
      if (lang !== i18n.language) {
        lang && i18n.changeLanguage(lang);
        if (publicLanguage === "en_EN") {
          TimeAgo.addLocale(en);
        }
        if (publicLanguage === "ru_RU") {
          TimeAgo.addLocale(ru);
        }
        if (publicLanguage === "lt_LT") {
          TimeAgo.addLocale(lt);
        }
        if (publicLanguage === "lv_LV") {
          TimeAgo.addLocale(lv);
        }
        if (publicLanguage === "fi_FI") {
          TimeAgo.addLocale(fi);
        }
        TimeAgo.setDefaultLocale(lang);

        languageStore.set(lang);

        setDefaultLocale(lang);
      }
    }
  }, [publicLanguage]);

  return (
    <Disclosure as="nav" className="bh-border-pigeon-20 z-30 flex h-14 flex-row border-b">
      <div className="flex px-8">
        <div className="flex flex-shrink-0 items-center">
          <a className="h-font" href={languageMap[publicLanguage]?.homepage} target="_blank" rel="noreferrer">
            <BhBauhubLogo />
          </a>
        </div>
      </div>
      <div className="flex items-center">
        <a className="h-font" href={languageMap[publicLanguage]?.support} target="_blank" rel="noreferrer">
          <Trans>PROJECT.NAVBAR.HELP</Trans>
        </a>
      </div>
      <div className="flex w-full items-center justify-end space-x-2 px-8">
        <BhDropdown
          button={<BhDropdownButton title="" value={languageMap[publicLanguage].label} reversed={true} />}
          menu={
            <BhDropdownMenu
              values={Object.values(languageMap)}
              type={BhDropdownTypeEnum.STRING}
              textProperty={"label"}
              onSelect={(lang: any) => {
                setPublicLanguage(lang.value);
              }}
            />
          }
          position={BhDropdownPositionEnum.BOTTOM_LEFT}
        />
        {!withoutSignInButton && (
          <BhSecondaryButton buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/`) }}>
            <Trans>LOGIN.SIGNIN</Trans>
          </BhSecondaryButton>
        )}
      </div>
    </Disclosure>
  );
};

export default PublicNavbar;
