import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IFrozenProjectDetails, selectIsProjectFrozenModalVisible, selectProjectFrozenModalDetails, setProjectFrozenModalDetails } from "@/app/store/globalSlice";
import ProjectFrozenModal from "@/views/global/ProjectFrozenModal";
import ProjectActivateModal from "@/views/home/project/detail/projectSettings/ProjectActivateModal";

const ProjectFrozenModals: FC = () => {
  const dispatch = useAppDispatch();
  const projectFrozenModalVisible = useAppSelector(selectIsProjectFrozenModalVisible);
  const projectFrozenModalDetails = useAppSelector(selectProjectFrozenModalDetails);
  const [isBillingModalVisible, setIsBillingModalVisible] = useState(false);

  useEffect(() => {
    if (!isBillingModalVisible && !projectFrozenModalVisible) {
      dispatch(setProjectFrozenModalDetails({} as IFrozenProjectDetails));
    }
  }, [isBillingModalVisible, projectFrozenModalVisible]);

  if (!projectFrozenModalDetails) return null;

  return (
    <>
      {projectFrozenModalVisible && <ProjectFrozenModal projectFrozenModalDetails={projectFrozenModalDetails} setIsBillingModalShown={setIsBillingModalVisible} />}
      {isBillingModalVisible && (
        <ProjectActivateModal
          isShown={isBillingModalVisible}
          setIsShown={setIsBillingModalVisible}
          companyId={parseInt(projectFrozenModalDetails.companyId.toString())}
          projectId={parseInt(projectFrozenModalDetails.projectId.toString())}
          isProjectFrozen={true}
        />
      )}
    </>
  );
};

export default ProjectFrozenModals;
