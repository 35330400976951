import React, { FC } from "react";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import { formatDate } from "@/utilities/dateUtility";

interface Props {
  salesInvoice: ISalesInvoice;
}

const BOInvoiceDoubtfulField: FC<Props> = ({ salesInvoice }) => {
  return (
    <div className="relative flex items-center justify-center gap-x-1">
      <div>
        <BhCheckbox isChecked={salesInvoice.doubtful} disabled={true} property={"doubtful"} large={true} onChange={() => {}} />
      </div>
      {salesInvoice.doubtfulDate && salesInvoice.doubtful && <div>{formatDate(salesInvoice.doubtfulDate)}</div>}
    </div>
  );
};

export default BOInvoiceDoubtfulField;
