import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { Trans, useTranslation } from "react-i18next";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import useHandleFormSubmit from "@/utilities/hooks/useHandleFormSubmit";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhTextareaStackedLabel from "@components/input/BhTextareaStackedLabel";
import { saveFeedback } from "@/api/feedbackAPI";
import { selectCurrentProjectId, selectCurrentProjectIsPro } from "@/app/store/project/projectSlice";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons/faSpinnerThird";
import SupportContactCard from "@/views/home/project/detail/support/SupportContactCard";
import SupportContactCardDark from "@/views/home/project/detail/support/SupportContactCardDark";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { selectCurrentUserLanguage } from "@/app/store/userSlice";

interface Props {}

const SupportContainer: FC<Props> = () => {
  useDocumentTitle("PROJECT.NAVBAR.HELP");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const isPro = useAppSelector(selectCurrentProjectIsPro);
  const [feedbackText, setFeedbackText] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const handleFormSubmit = useHandleFormSubmit<{ text: string }>();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguage);

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.SUPPORT));
  }, []);

  const supportItems = [
    {
      id: 1,
      href: {
        et_EE: "https://www.bauhub.ee/tugi/kuidas-alustada",
        en_EN: "https://www.bauhub.ee/support/getting-started",
        lv_LV: "https://www.bauhub.ee/atbalsts/vairak-par-bauhub",
        fi_FI: "https://www.bauhub.ee/tuki/nopeasti-alkuun"
      },
      nameCode: "SUPPORT.LINKS.START"
    },
    {
      id: 2,
      href: {
        et_EE: "https://www.bauhub.ee/tugi/toolaud-ja-tood",
        en_EN: "https://www.bauhub.ee/support/taskboards",
        lv_LV: "https://www.bauhub.ee/atbalsts/uzdevumu-tafeles",
        fi_FI: "https://www.bauhub.ee/tuki/tehtavienhallinta"
      },
      nameCode: "SUPPORT.LINKS.TASKBOARDS"
    },
    {
      id: 3,
      href: {
        et_EE: "https://www.bauhub.ee/tugi/kasutajad",
        en_EN: "https://www.bauhub.ee/support/users",
        lv_LV: "https://www.bauhub.ee/atbalsts/lietotaji",
        fi_FI: "https://www.bauhub.ee/tuki/kayttajat"
      },
      nameCode: "SUPPORT.LINKS.USERS"
    },
    {
      id: 4,
      href: {
        et_EE: "https://www.bauhub.ee/tugi/lepingud-ja-akteerimine",
        en_EN: "https://www.bauhub.ee/support/contract-and-progress-report",
        lv_LV: "https://www.bauhub.ee/atbalsts/ligums-un-progresa-zinojums",
        fi_FI: "https://www.bauhub.ee/tuki/contract-and-progress-report"
      },
      nameCode: "SUPPORT.LINKS.ACTS"
    },
    {
      id: 5,
      href: {
        et_EE: "https://www.bauhub.ee/tugi/failid-ja-dokumendid",
        en_EN: "https://www.bauhub.ee/support/files-and-documents",
        lv_LV: "https://www.bauhub.ee/atbalsts/failid-ja-dokumendid",
        fi_FI: "https://www.bauhub.ee/tuki/tiedostot-ja-dokumentit"
      },
      nameCode: "SUPPORT.LINKS.FILES"
    },
    {
      id: 6,
      href: {
        et_EE: "https://www.bauhub.ee/tugi/mobiilirakendused",
        en_EN: "https://www.bauhub.ee/support/apps",
        lv_LV: "https://www.bauhub.ee/atbalsts/mobiilirakendused",
        fi_FI: "https://www.bauhub.ee/tuki/mobiili"
      },
      nameCode: "SUPPORT.LINKS.MOBILE"
    },
    {
      id: 7,
      href: {
        et_EE: "https://www.bauhub.ee/tugi/tellimused",
        en_EN: "https://www.bauhub.ee/support/subscriptions",
        lv_LV: "https://www.bauhub.ee/atbalsts/tellimused",
        fi_FI: "https://www.bauhub.ee/tuki/tilaukset"
      },
      nameCode: "SUPPORT.LINKS.SUBSCRIPTIONS"
    },
    {
      id: 8,
      href: {
        et_EE: "https://www.bauhub.ee/tugi/kontrollid",
        en_EN: "https://www.bauhub.ee/support/checklists",
        lv_LV: "https://www.bauhub.ee/atbalsts/kontrollid",
        fi_FI: "https://www.bauhub.ee/tuki/tarkistuslistat"
      },
      nameCode: "SUPPORT.LINKS.CHECKLISTS"
    },
    {
      id: 9,
      href: {
        et_EE: "https://www.bauhub.ee/tugi/baudrive",
        en_EN: "https://www.bauhub.ee/support/baudrive",
        lv_LV: "https://www.bauhub.ee/atbalsts/baudrive",
        fi_FI: "https://www.bauhub.ee/tuki/baudrive"
      },
      nameCode: "GLOBAL.BAUDRIVE"
    }
  ];

  const handleSubmit = (formValues: { text: string }) => {
    setSaveLoading(true);
    saveFeedback(projectId, formValues)
      .then(() => {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.SUCCESS,
            disappear: true,
            children: t("SUPPORT.FEEDBACK_SENT")
          })
        );
        setFeedbackText("");
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const supportEmailMap = {
    et_EE: "tugi@bauhub.ee",
    en_EN: "support@bauhub.ee",
    fi_FI: "tuki@tocoman.com"
  };
  const supportEmail = supportEmailMap[currentUserLanguage as keyof typeof supportEmailMap] || supportEmailMap["en_EN"];

  const supportNumberMap = {
    et_EE: "+372 884 0440",
    en_EN: "+372 884 0440",
    fi_FI: "+358 20 742 0206"
  };
  const supportNumber = supportNumberMap[currentUserLanguage as keyof typeof supportNumberMap] || supportNumberMap["en_EN"];

  return (
    <DetailViewContainer
      header={
        <DetailViewHeading>
          <Trans>PROJECT.NAVBAR.HELP</Trans>
        </DetailViewHeading>
      }
    >
      <BhScrollableBody>
        <div className="mr-32 overflow-hidden xl:mr-0">
          <div className="flex flex-row lg:flex-col">
            <div className="w-1/2 pt-4 lg:w-full">
              <h3>
                <Trans>SUPPORT.INSTRUCTIONS</Trans>
              </h3>
              <p className="w-2/3">
                <Trans>SUPPORT.INSTRUCTIONS.DETAILS</Trans>
              </p>
            </div>
            <div className="w-1/2 pt-4 lg:w-full">
              {supportItems.map((supportItem) => {
                return (
                  <a
                    key={supportItem.id}
                    href={supportItem.href[currentUserLanguage as keyof typeof supportItem.href] || supportItem.href["en_EN"]}
                    target="_blank"
                    className="hover:bh-bg-mint group relative flex h-10 items-center px-3 font-bold"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faChevronRight} className="mx-1 h-4 w-4" aria-hidden="true" />
                    <span className="ml-3">{t(supportItem.nameCode)}</span>
                  </a>
                );
              })}
            </div>
          </div>
          <BhPigeon20Separator classes="my-4" />
          <div className="flex flex-row lg:flex-col">
            <div className="w-1/2 pt-1 lg:w-full">
              <h3>
                <Trans>SUPPORT.GET_CONTACT</Trans>
              </h3>
              <p className="w-2/3">{isPro ? <Trans>SUPPORT.GET_CONTACT.DETAILS_PRO</Trans> : <Trans>SUPPORT.GET_CONTACT.DETAILS</Trans>}</p>
            </div>
            <div className="my-2 flex w-1/2 flex-row space-x-2 lg:w-full">
              <SupportContactCard heading={<Trans>SUPPORT.GET_CONTACT.WRITE</Trans>} body={<a href={"mailto:" + supportEmail}>{supportEmail}</a>} />
              {!isPro && <SupportContactCardDark />}
              {isPro && <SupportContactCard heading={<Trans>SUPPORT.GET_CONTACT.CALL</Trans>} body={<a href={"tel:" + supportNumber.replace(/\s+/g, "")}>{supportNumber}</a>} />}
            </div>
          </div>
          <BhPigeon20Separator classes="my-4" />
          <div className="flex flex-row lg:flex-col">
            <div className="w-1/2 pt-1 lg:w-full">
              <h3>
                <Trans>SUPPORT.LEAVE_FEEDBACK</Trans>
              </h3>
              <p className="w-2/3">
                <Trans>SUPPORT.LEAVE_FEEDBACK.DETAILS</Trans>
              </p>
            </div>
            <div className="w-1/2 flex-col lg:w-full">
              <form id="feedbackForm" onSubmit={handleFormSubmit((feedback) => handleSubmit(feedback))}>
                <BhTextareaStackedLabel
                  label={t("SUPPORT.FEEDBACK")}
                  placeholder={t("SUPPORT.FEEDBACK.PLACEHOLDER")}
                  initialValue={feedbackText}
                  property={"text"}
                  onChangeCallback={({ text }: { text: string }) => {
                    setFeedbackText(text);
                  }}
                  rows={3}
                />
              </form>
              <div className="-mr-1 mt-2 flex flex-row justify-end">
                <BhPrimaryButton buttonProps={{ submitForm: "feedbackForm", disabled: feedbackText.length === 0 || saveLoading }}>
                  <Trans>GLOBAL.SEND</Trans>
                  {saveLoading && <FontAwesomeIcon icon={faSpinnerThird} className="fa-spin ml-2" />}
                </BhPrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </BhScrollableBody>
    </DetailViewContainer>
  );
};

export default SupportContainer;
