import React, { FC, useEffect } from "react";
import BhTableRow from "@components/table/BhTableRow";
import { classNames } from "@/utilities/jsUtilities";
import BhUserIconWithNameForSignature from "@components/user/BhUserIconWithNameForSignature";
import QueueSignatureActionButtons from "@/views/home/project/detail/container/containerComponents/QueueSignatureActionButtons";
import RegularSignatureActionButtons from "@/views/home/project/detail/container/containerComponents/RegularSignatureActionButtons";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { IFileEntity } from "@/model/files/IFileEntity";
import { formatDateTime } from "@/utilities/dateUtility";
import { Trans, useTranslation } from "react-i18next";
import BhSignatureCellStatusForUser from "@components/container/BhSignatureCellStatusForUser";
import { BhSectionMessageError } from "@components/sectionMessage/BhSectionMessages";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectNextToSignForContainer, signatureModified } from "@/app/store/signaturesSlice";

interface Props {
  signature: ISignatureHolder;
  containerFileEntity?: IFileEntity;
  index: number;
  queueEnabled: boolean;
  signatureAdditionalFieldsFilled?: string;
}

const SignaturesListItem: FC<Props> = ({ signature, containerFileEntity, index, queueEnabled, signatureAdditionalFieldsFilled }) => {
  const nextToSign = useAppSelector(selectNextToSignForContainer(signature.containerUuid));
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const roleResolution = signature?.roleResolution;
  const lastIndexOfSlash = roleResolution?.lastIndexOf("/");
  const role = roleResolution?.substring(0, lastIndexOfSlash).trim();
  const resolution = roleResolution?.substring(lastIndexOfSlash + 1).trim();

  useEffect(() => {
    // Kuna BE ei tagasta invite'i kustutades või keeldudes või allkirjastades järgmist allkirjastajat siis peab käsitsi uuendama
    if (nextToSign && nextToSign === signature && !signature.nextInLine) {
      dispatch(signatureModified({ ...signature, nextInLine: true }));
    }
  }, [nextToSign]);

  return (
    <React.Fragment>
      <BhTableRow classes={classNames(!signature.isUnsignedInvite && "bh-bg-success-green-5", (signature.declined || signatureAdditionalFieldsFilled) && "border-b-0")}>
        {queueEnabled && <td className="text-center">{containerFileEntity?.queueEnabled && index + 1}</td>}
        <td>
          <BhUserIconWithNameForSignature signature={signature} reducedOpacity={signature.isUnsignedInvite && signature.orderNumber && !signature.nextInLine ? true : false} />
        </td>
        <td>
          {signature.isUnsignedInvite && <span></span>}
          {!signature.isUnsignedInvite && <span>{formatDateTime(signature.claimedSigningTime)}</span>}
        </td>
        <td className="flex flex-row justify-center">
          <BhSignatureCellStatusForUser signatureHolder={signature} />
        </td>
        {containerFileEntity && (
          <td className="text-right">
            <>
              {containerFileEntity.queueEnabled && <QueueSignatureActionButtons signature={signature} containerFileEntity={containerFileEntity} />}
              <RegularSignatureActionButtons signature={signature} containerFileEntity={containerFileEntity} />
            </>
          </td>
        )}
      </BhTableRow>
      {signature.declined && (
        <BhTableRow classes="bh-no-top-separator !h-auto hover:bh-bg-white">
          <td colSpan={5}>
            <BhSectionMessageError small={true}>
              <b className="pr-1">
                <Trans>CONTAINER.DECLINE_REASON.HEADER</Trans>:
              </b>
              {signature.declineReason}
            </BhSectionMessageError>
          </td>
        </BhTableRow>
      )}
      {signatureAdditionalFieldsFilled && (
        <BhTableRow classes={classNames("bh-no-top-separator", !signature.isUnsignedInvite && "bh-bg-success-green-5 ")}>
          {containerFileEntity?.queueEnabled && <td></td>}
          <td colSpan={4}>
            <div className="inline-block w-1/3">
              {role && (
                <>
                  <div className="bh-table-header-text bh-text-deep-ocean-80 font-bold">{t("GLOBAL.ROLE")}</div>
                  <div>{role}</div>
                </>
              )}
            </div>
            <div className="inline-block w-1/3">
              {resolution && (
                <>
                  <div className="bh-table-header-text bh-text-deep-ocean-80 font-bold">{t("GLOBAL.RESOLUTION")}</div>
                  <div>{resolution}</div>
                </>
              )}
            </div>
            <div className="inline-block w-1/3">
              {(signature.country || signature.county || signature.city || signature.zip) && (
                <>
                  <div className="bh-table-header-text bh-text-deep-ocean-80 font-bold">{t("CONTAINER.COUNTRY_COUNTY_CITY_ZIP")}</div>
                  <div>{[signature.country, signature.county, signature.city, signature.zip].filter(Boolean).join(", ")}</div>
                </>
              )}
            </div>
          </td>
        </BhTableRow>
      )}
    </React.Fragment>
  );
};

export default SignaturesListItem;
