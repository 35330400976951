import React, { FC, useEffect, useState } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhModal from "@components/modal/BhModal";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhInputWithDropdown from "@components/input/BhInputWithDropdown";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boChangeInvoiceAsync, boGetPresignedUploadUrlForCustomSalesInvoiceAsync, boSaveNewInvoiceAsync, boSelectAllCompanies } from "@/app/store/backofficeSlice";
import BhDatepickerStackedLabel from "@components/input/BhDatepickerStackedLabel";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons";
import { countriesMap } from "@/utilities/countriesMap";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhAddFilesContainer from "@components/upload/BhAddFilesContainer";
import { IFileUploadPresignedDTO } from "@/model/files/IFileUploadPresignedDTO";
import { IFileEntity } from "@/model/files/IFileEntity";
import { pdftronExtensions } from "@/utilities/fileEntity/fileEntityUtilities";
import { IMonthlyInvoiceOverviewRow } from "@/model/invoices/IMonthlyInvoiceOverviewRow";
import BhModalFooter from "@components/modal/BhModalFooter";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uploadFile } from "@/api/fileAPI";
import { naturalSortByField } from "@/utilities/sortUtilities";
import { boFetchCompany } from "@/api/backoffice/boCompaniesAPI";
import { ICompany } from "@/model/ICompany";
import dayjs from "dayjs";
import { getCountryName } from "@/utilities/countriesUtil";
import { useTranslation } from "react-i18next";

interface Props {
  setIsShown: (value: boolean) => void;
  editableInvoiceRow?: IMonthlyInvoiceOverviewRow;
}

const BONewInvoiceModal: FC<Props> = ({ setIsShown, editableInvoiceRow }) => {
  const { i18n } = useTranslation();
  const newInvoice = { customerCountry: "Eesti", vatPercentage: 22 };
  const companiesList = useAppSelector(boSelectAllCompanies);
  const companiesListForInput = companiesList.map((company) => ({ id: company.id, companyName: company.name })).sort((a, b) => naturalSortByField(a, b, "companyName"));
  const currentCompany = editableInvoiceRow?.companyName;
  const countryNames = countriesMap.map((country) => getCountryName(country.countryCode, i18n.language)).sort();
  const [salesInvoice, setSalesInvoice] = useState<ISalesInvoice>(editableInvoiceRow?.salesInvoice || (newInvoice as ISalesInvoice));
  const [selectedCompany, setSelectedCompany] = useState<ICompany>();
  const [file, setFile] = useState<IFileEntity>();
  const allowedUploadFileTypes = pdftronExtensions.map((ext) => "." + ext).join(",");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (salesInvoice.sumWithoutVat) {
      const vatPercentage = salesInvoice.vatPercentage / 100;
      const vatAmount = salesInvoice.sumWithoutVat * vatPercentage;
      const sumWithVat = vatAmount + salesInvoice.sumWithoutVat;
      setSalesInvoice({ ...salesInvoice, vatAmount, sumWithVat });
    }
  }, [salesInvoice.sumWithoutVat]);

  const saveInvoice = () => {
    if (editableInvoiceRow?.salesInvoice) {
      dispatch(boChangeInvoiceAsync(salesInvoice)).then(() => {
        setIsShown(false);
      });
    } else {
      dispatch(boSaveNewInvoiceAsync(salesInvoice)).then(() => {
        setIsShown(false);
      });
    }
  };

  const handleInvoiceCompanyChange = (company: any) => {
    boFetchCompany(company.id).then((company) => {
      setSelectedCompany(company);
      setSalesInvoice({
        ...salesInvoice,
        companyId: company.id,
        customerRecipient: company.name,
        customerAddress: company.companyInfo.address,
        customerCountry: company.companyInfo.country,
        customerRegCode: company.companyInfo.regCode,
        customerVatNumber: company.companyInfo.vatNumber
      } as ISalesInvoice);
    });
  };

  const handleChange = (changedValue: Record<string, any>) => {
    setSalesInvoice({ ...salesInvoice, ...changedValue } as ISalesInvoice);
  };

  const handleUpload = (files: any) => {
    const newFile = files[0];
    dispatch(boGetPresignedUploadUrlForCustomSalesInvoiceAsync(newFile.type)).then((action) => {
      const fileUpload = action.payload as IFileUploadPresignedDTO;
      if (fileUpload) {
        const { promise } = uploadFile(fileUpload.url, "PUT", [{ name: "Content-Type", value: newFile.type + ";charset=UTF-8" }], newFile, dispatch, newFile);
        return promise.then((response) => {
          setSalesInvoice({ ...salesInvoice, invoiceFileName: newFile.name, uuid: fileUpload.objectName });
          setFile({ id: 0, name: newFile.name } as IFileEntity);
        });
      }
    });
  };

  const onFileRemove = (e: any) => {
    setSalesInvoice({ ...salesInvoice, invoiceFileName: "", uuid: "" });
    setFile({} as IFileEntity);
  };

  const isNewInvoiceValid = () => {
    const { customerRecipient, fullInvoiceNumber, invoiceDate, billingDueDate, sumWithoutVat, sumWithVat, vatAmount, projectsOnInvoice } = salesInvoice;
    return selectedCompany && customerRecipient && fullInvoiceNumber && invoiceDate && billingDueDate && sumWithVat && sumWithoutVat && vatAmount && projectsOnInvoice;
  };

  return (
    <BhModal
      size="2xl"
      setIsShown={setIsShown}
      header={<h2 className="bh-text-deep-ocean-80">{editableInvoiceRow?.salesInvoice ? "Change invoice" : "New invoice (special case)"}</h2>}
      footer={<BhModalFooter cancelButtonText="Cancel" onCancel={() => setIsShown(false)} confirmButtonText="Save" onConfirm={saveInvoice} confirmDisabled={!isNewInvoiceValid()} />}
    >
      <div className="h-full overflow-auto p-8">
        <div className="grid grid-cols-2 gap-4">
          <BhInputWithDropdown
            initialValue={currentCompany || selectedCompany?.name || ""}
            values={companiesListForInput}
            property="companyName"
            onSelect={handleInvoiceCompanyChange}
            label="Company"
          />
          <BhInputStackedLabel initialValue={selectedCompany?.name || salesInvoice.customerRecipient} property="customerRecipient" label="Recipient name" onChangeCallback={handleChange} />
          <BhInputStackedLabel initialValue={selectedCompany?.companyInfo.address || salesInvoice.customerAddress} property="customerAddress" label="Legal address" onChangeCallback={handleChange} />
          <BhInputWithDropdown initialValue={salesInvoice.customerCountry} values={countryNames} property="customerCountry" onSelect={handleChange} label="Country" />
          <BhInputStackedLabel initialValue={selectedCompany?.companyInfo.regCode || salesInvoice.customerRegCode} property="customerRegCode" label="Registry code" onChangeCallback={handleChange} />
          <BhInputStackedLabel
            initialValue={selectedCompany?.companyInfo.vatNumber || salesInvoice.customerVatNumber}
            property="customerVatNumber"
            label="VAT number"
            onChangeCallback={handleChange}
          />
          <BhInputStackedLabel initialValue={salesInvoice.fullInvoiceNumber} property="fullInvoiceNumber" label="Invoice number" onChangeCallback={handleChange} />
          <div className="full-w-datepicker">
            <BhDatepickerStackedLabel
              initialValue={new Date(salesInvoice.invoiceDate)}
              property="invoiceDate"
              label="Issued at"
              onChangeCallback={(e: any) => handleChange({ invoiceDate: dayjs(e.invoiceDate).format("YYYY-MM-DDTHH:mm:ss") })}
            />
          </div>
          <div className="full-w-datepicker">
            <BhDatepickerStackedLabel
              initialValue={new Date(salesInvoice.billingDueDate)}
              property="billingDueDate"
              label="Due date"
              onChangeCallback={(e: any) => handleChange({ billingDueDate: dayjs(e.billingDueDate).format("YYYY-MM-DDTHH:mm:ss") })}
            />
          </div>
        </div>
        <div className="mt-10 grid grid-cols-2 gap-4">
          <BhInputStackedLabel
            initialValue={salesInvoice.vatPercentage}
            property="vatPercentage"
            label="VAT rate"
            onChangeCallback={(e: any) => handleChange({ vatPercentage: parseFloat(e.vatPercentage) })}
          />
          <BhInputStackedLabel
            initialValue={salesInvoice.sumWithoutVat || 0}
            property="sumWithoutVat"
            label="Sum without VAT"
            onChangeCallback={(e: any) => handleChange({ sumWithoutVat: parseFloat(e.sumWithoutVat) })}
          />
          <BhInputStackedLabel
            initialValue={salesInvoice.vatAmount?.toFixed(2) || 0}
            property="vatAmount"
            label="Sum of VAT"
            onChangeCallback={(e: any) => handleChange({ vatAmount: parseFloat(e.vatAmount) })}
          />
          <BhInputStackedLabel
            initialValue={salesInvoice.sumWithVat?.toFixed(2) || 0}
            property="sumWithVat"
            label="Sum with VAT"
            onChangeCallback={(e: any) => handleChange({ sumWithVat: parseFloat(e.sumWithVat) })}
          />
          <BhInputStackedLabel
            initialValue={salesInvoice.projectsOnInvoice || 0}
            property="projectsOnInvoice"
            label="Project count on invoice"
            onChangeCallback={(e: any) => handleChange({ projectsOnInvoice: parseFloat(e.projectsOnInvoice) })}
          />
          <BhInputStackedLabel
            initialValue={salesInvoice.sumForAvgPrice || 0}
            property="sumForAvgPrice"
            label="Sum for avg price calculation"
            onChangeCallback={(e: any) => handleChange({ sumForAvgPrice: parseFloat(e.sumForAvgPrice) })}
          />
        </div>
        <div className="mt-10 grid grid-cols-2 gap-4">
          <BhInputStackedLabel initialValue={salesInvoice.customerRecipientEmails} property="customerRecipientEmails" label="Invoice e-mail" onChangeCallback={handleChange} />
        </div>
        {!salesInvoice.uuid && (
          <div className="mt-4">
            <BhAddFilesContainer
              uploadFilesSelectedCallback={handleUpload}
              button={<BhTextOnlyButton icon={faPaperclip}>Add attachment</BhTextOnlyButton>}
              dropdownPosition={BhDropdownPositionEnum.TOP_RIGHT}
              fileTypesAllowedForUpload={allowedUploadFileTypes}
            />
          </div>
        )}
        {file && salesInvoice.uuid && (
          <div className="mt-4">
            <div className="pb-2">
              <FontAwesomeIcon icon={faPaperclip} className={"bh-text-pigeon"} />
              <span className="bh-text-deep-ocean ml-2 font-bold">Attachments</span>
            </div>
            <AttachmentsContainerLarge attachments={[file]} removeCallback={onFileRemove} multiple={false} addingDisabled={true} chooseFromProjectAllowed={false} />
          </div>
        )}
      </div>
    </BhModal>
  );
};

export default BONewInvoiceModal;
