import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import CompanyLogo from "@/views/home/company/CompanyLogo";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";
import { ICompanyInfo } from "@/model/iCompanyInfo";
import { saveCompanyAsync, saveCompanyInfoAsync, saveNcnContractEmailAsync, selectCompanyById } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType, Module } from "@/model/IProject";
import { useTranslation } from "react-i18next";
import { ICompany } from "@/model/ICompany";
import { isValidEmail, isValidEmails } from "@/utilities/jsUtilities";
import { countriesMap } from "@/utilities/countriesMap";
import { languageMap } from "@/views/home/profile/ProfileTabs/ProfileUserInfo";
import { getAlpha2Code, getCountryName } from "@/utilities/countriesUtil";

interface Props {
  companyId: EntityId;
}

const CompanySettingsGeneralTab: FC<Props> = ({ companyId }) => {
  const { t, i18n } = useTranslation();
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const dispatch = useAppDispatch();
  const companyInfo = company?.companyInfo;
  const customCompanyModule = company?.modules?.find((module) => module.module === Module.NORDECON || module.module === Module.EMBACH);

  const companyLanguageCode = languageMap[company.language].langCode;

  const companyCountryObject = countriesMap.find((country) => country.countryCode === getAlpha2Code(companyInfo.country, companyLanguageCode));
  const companyCountryTextValueInUserLanguage = getCountryName(getAlpha2Code(companyInfo.country, companyLanguageCode), i18n.language);
  const selectedCountryStates = companyCountryObject?.states;

  const countryNamesList = countriesMap.map((country) => getCountryName(country.countryCode, i18n.language)).sort();

  const onCompanyDetailsSubmit = useCallback(
    (changedValue: ICompanyInfo) => {
      let changedObject = { ...companyInfo, ...changedValue };
      if (Object.keys(changedValue)[0] === "country") {
        changedObject = { ...changedObject, state: "" };
        const changedValueCountryObject = countriesMap.find((country) => country.countryCode === getAlpha2Code(changedValue.country, i18n.language));
        const countryNameInCompanyLanguage = changedValueCountryObject ? getCountryName(changedValueCountryObject.countryCode, companyLanguageCode) : changedValue.country;
        const changedValueInCompanyLanguage = { country: countryNameInCompanyLanguage };
        changedObject = { ...companyInfo, ...changedValueInCompanyLanguage, state: "" };
        return dispatch(saveCompanyInfoAsync(changedObject));
      }
      if (Object.keys(changedValue)[0] === "email") {
        if (!isValidEmail(changedValue.email)) {
          dispatch(
            toastFlagAdded({
              id: uuidv4(),
              type: BauhubBannerType.ERROR,
              disappear: true,
              children: t("GLOBAL.EMAIL_INVALID")
            })
          );
          return;
        }
      }
      dispatch(saveCompanyInfoAsync(changedObject));
    },
    [company, companyInfo]
  );

  const onNcnContractEmailSubmit = useCallback(
    (changedValue: any) => {
      if (!isValidEmails(changedValue.contractEmail)) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            children: t("GLOBAL.EMAIL_INVALID")
          })
        );
        return;
      }
      if (company && company.id) {
        dispatch(saveNcnContractEmailAsync({ companyId: company.id, contractEmail: changedValue.contractEmail }));
      }
    },
    [company]
  );

  const saveCompanyName = useCallback(
    (changedValue: ICompany) => {
      if (!company) return;
      if (changedValue?.name?.length < 1) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            children: t("INPUT.NOT_EMPTY")
          })
        );
        return;
      }
      const changedObject = { ...company, name: changedValue.name };
      dispatch(saveCompanyAsync(changedObject));
    },
    [company]
  );

  if (!company) {
    return null;
  }

  return (
    <BhScrollableBody>
      <div className="flex flex-row justify-center">
        <div className="flex max-w-[800px] gap-40 py-10 lg:gap-20 sm:gap-10">
          <div className="flex flex-row justify-center">
            <div className="flex flex-col text-center">
              <h3 className="bh-text-deep-ocean">{t("COMPANY.SETTINGS.LOGO")}</h3>
              <CompanyLogo />
            </div>
          </div>
          <div className="flex-1">
            <h2 className="bh-text-deep-ocean mt-0">{t("COMPANY.COMPANY_INFO")}</h2>
            <div>{t("COMPANY.SETTINGS.INFO.BODY")}</div>
            <div className="mt-5">
              <BhInputStackedLabel initialValue={company.name} property="name" label={t("GLOBAL.ITEM_NAME")} onChangeCallback={saveCompanyName} onBlurAction={saveCompanyName} debounceLength={900} />
            </div>
            <h3 className="bh-text-deep-ocean mt-8">{t("COMPANY.SETTINGS.CONTACT_INFO")}</h3>
            <div className="mt-4 flex flex-row">
              <div className="mr-8 flex-1 basis-1/2">
                <BhComboboxStackedLabel
                  initialValue={companyCountryTextValueInUserLanguage}
                  onSelect={onCompanyDetailsSubmit}
                  values={countryNamesList}
                  property="country"
                  label={t("COMPANY.COUNTRY")}
                />
              </div>
              <div className="flex-1 basis-1/2">
                <BhComboboxStackedLabel
                  initialValue={company.companyInfo.state}
                  onSelect={onCompanyDetailsSubmit}
                  values={selectedCountryStates ? selectedCountryStates : []}
                  property="state"
                  label={t("COMPANY.STATE")}
                  placeholder={company.companyInfo.state}
                />
              </div>
            </div>
            <div className="mt-6 flex flex-row">
              <div className="mr-8 flex-1 basis-1/2">
                <BhInputStackedLabel
                  initialValue={company.companyInfo.city}
                  property="city"
                  label={t("COMPANY.CITY")}
                  onChangeCallback={onCompanyDetailsSubmit}
                  onBlurAction={onCompanyDetailsSubmit}
                  debounceLength={900}
                />
              </div>
              <div className="flex-1 basis-1/2">
                <BhInputStackedLabel
                  initialValue={company.companyInfo.zipCode}
                  property="zipCode"
                  label={t("COMPANY.ZIPCODE")}
                  onChangeCallback={onCompanyDetailsSubmit}
                  onBlurAction={onCompanyDetailsSubmit}
                  debounceLength={900}
                />
              </div>
            </div>
            <div className="mt-6 flex flex-row">
              <div className="mr-8 flex-1 basis-1/2">
                <BhInputStackedLabel
                  initialValue={company.companyInfo.street}
                  property="street"
                  label={t("COMPANY.STREET")}
                  onChangeCallback={onCompanyDetailsSubmit}
                  onBlurAction={onCompanyDetailsSubmit}
                  debounceLength={900}
                />
              </div>
              <div className="flex-1 basis-1/2">
                <BhInputStackedLabel
                  initialValue={company.companyInfo.house}
                  property="house"
                  label={t("COMPANY.HOUSE")}
                  onChangeCallback={onCompanyDetailsSubmit}
                  onBlurAction={onCompanyDetailsSubmit}
                  debounceLength={900}
                />
              </div>
            </div>
            <div className="mt-6">
              <BhInputStackedLabel
                initialValue={company.companyInfo.email}
                property="email"
                label={t("COMPANY.EMAIL")}
                onChangeCallback={onCompanyDetailsSubmit}
                onBlurAction={onCompanyDetailsSubmit}
                debounceLength={900}
              />
            </div>
            <div className="mt-6">
              <BhInputStackedLabel
                initialValue={company.companyInfo.phoneNumber}
                property="phoneNumber"
                label={t("COMPANY.PHONE_NUMBER")}
                onChangeCallback={onCompanyDetailsSubmit}
                onBlurAction={onCompanyDetailsSubmit}
                debounceLength={900}
              />
            </div>
            {customCompanyModule && (
              <div className="mt-6">
                <BhInputStackedLabel
                  initialValue={customCompanyModule?.settings?.contractEmail}
                  property="contractEmail"
                  label={t("COMPANY.CONTRACT_EMAIL")}
                  onChangeCallback={onNcnContractEmailSubmit}
                  onBlurAction={onNcnContractEmailSubmit}
                  debounceLength={900}
                />
              </div>
            )}
            <h3 className="bh-text-deep-ocean mt-8">{t("COMPANY.SETTINGS.REG_INFO")}</h3>
            <div className="mt-4">
              <BhInputStackedLabel
                initialValue={company.companyInfo.regCode}
                property="regCode"
                label={t("COMPANY.REGCODE")}
                onChangeCallback={onCompanyDetailsSubmit}
                onBlurAction={onCompanyDetailsSubmit}
                debounceLength={900}
              />
            </div>
            <div className="mt-6">
              <BhInputStackedLabel
                initialValue={company.companyInfo.vatNumber}
                property="vatNumber"
                label={t("COMPANY.VATNUMBER")}
                onChangeCallback={onCompanyDetailsSubmit}
                onBlurAction={onCompanyDetailsSubmit}
                debounceLength={900}
              />
            </div>
          </div>
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default CompanySettingsGeneralTab;
