import BauhubBOHome from "@/views/home/backoffice/BauhubBOHome";
import { Route, Routes, useParams } from "react-router-dom";
import React, { FC, useEffect } from "react";
import BOCompanies from "@/views/home/backoffice/views/companies/BOCompanies";
import BOInvoices from "@/views/home/backoffice/views/invoices/BOInvoices";
import BOMonthlyInvoiceOverview from "@/views/home/backoffice/views/invoices/BOMonthlyInvoiceOverview";
import NotFound from "@/views/global/NotFound";
import BOCompany from "@/views/home/backoffice/views/company/BOCompany";
import BOTrials from "@/views/home/backoffice/views/trials/BOTrials";
import BOMonthlyTrialOverview from "./views/trials/BOMonthlyTrialOverview";
import BOUsers from "@/views/home/backoffice/views/users/BOUsers";
import BOBackend from "@/views/home/backoffice/views/backend/BOBackend";
import BORevenue from "@/views/home/backoffice/views/revenue/BORevenue";
import BOFutureRevenue from "@/views/home/backoffice/views/revenue/BOFutureRevenue";
import BODoubtfulInvoices from "@/views/home/backoffice/views/invoices/BODoubtfulInvoices";
import BOSalesInvoiceReports from "@/views/home/backoffice/views/invoices/BOSalesInvoiceReports";
import BOOutstandingInvoices from "@/views/home/backoffice/views/invoices/BOOutstandingInvoices";
import RequireSuperUserAuth from "@/views/authentication/RequireSuperUserAuth";
import RequireBOAuth from "@/views/authentication/RequireBOAuth";
import { SubResource } from "@/model/IUserAuthority";
import { useAppDispatch } from "@/app/hooks";
import { boSetFilters } from "@/app/store/backofficeSlice";
import { initialFilter } from "@/model/backoffice/IBoFilter";
import BOForms from "@/views/home/backoffice/views/forms/BOForms";

const BauhubBORouter: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return function cleanup() {
      dispatch(boSetFilters(initialFilter));
    };
  }, []);

  function BOCompanyWithParams() {
    let { companyId } = useParams();
    return companyId ? <BOCompany companyId={parseInt(companyId)} /> : <NotFound />;
  }

  return (
    <div className="w-full overflow-y-auto">
      <Routes>
        <Route index element={<BauhubBOHome />} />
        <Route
          path="company/:companyId/*"
          element={
            <RequireBOAuth subResource={SubResource.BO_COMPANIES}>
              <BOCompanyWithParams />
            </RequireBOAuth>
          }
        />
        <Route
          path="companies"
          element={
            <RequireBOAuth subResource={SubResource.BO_COMPANIES}>
              <BOCompanies />
            </RequireBOAuth>
          }
        />
        <Route
          path="users"
          element={
            <RequireBOAuth subResource={SubResource.BO_USERS}>
              <BOUsers />
            </RequireBOAuth>
          }
        />
        <Route
          path="users/:userId"
          element={
            <RequireBOAuth subResource={SubResource.BO_USERS}>
              <></>
            </RequireBOAuth>
          }
        />
        <Route
          path="invoices"
          element={
            <RequireSuperUserAuth>
              <BOInvoices />
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="invoices/:month"
          element={
            <RequireSuperUserAuth>
              <BOMonthlyInvoiceOverview />
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="revenue"
          element={
            <RequireSuperUserAuth>
              <BORevenue />
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="revenue/future"
          element={
            <RequireSuperUserAuth>
              <BOFutureRevenue />
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="invoices/doubtful"
          element={
            <RequireSuperUserAuth>
              <BODoubtfulInvoices />
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="invoices/outstanding"
          element={
            <RequireSuperUserAuth>
              <BOOutstandingInvoices />
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="invoices/reports"
          element={
            <RequireSuperUserAuth>
              <BOSalesInvoiceReports />
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="trials"
          element={
            <RequireSuperUserAuth>
              <BOTrials />
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="trials/:month"
          element={
            <RequireSuperUserAuth>
              <BOMonthlyTrialOverview />
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="backend"
          element={
            <RequireSuperUserAuth>
              <BOBackend />
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="template-developer"
          element={
            <RequireSuperUserAuth>
              <></>
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="forms"
          element={
            <RequireSuperUserAuth>
              <BOForms />
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="events"
          element={
            <RequireSuperUserAuth>
              <></>
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="baudrive-logs"
          element={
            <RequireSuperUserAuth>
              <></>
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="killswitch"
          element={
            <RequireSuperUserAuth>
              <></>
            </RequireSuperUserAuth>
          }
        />
        <Route
          path="asyncjobs"
          element={
            <RequireSuperUserAuth>
              <></>
            </RequireSuperUserAuth>
          }
        />
      </Routes>
    </div>
  );
};

export default BauhubBORouter;
