import React, { FC, useEffect, useState } from "react";
import TaskInfoField from "@components/task/modal/TaskInfoField";
import { ITask } from "@/model/taskBoard/ITask";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import useInputIsActive from "@hooks/useInputIsActive";
import { convertSimpleValidUserAuthorityToUser, ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { useAppSelector } from "@/app/hooks";
import { selectTaskBoardUsersByTaskBoardId } from "@/app/store/taskBoardsSlice";
import BhInputWithChips from "@components/input/BhInputWithChips";
import { IUser } from "@/model/IUser";
import { selectCurrentUser } from "@/app/store/userSlice";
import { isTaskPersonal } from "@/utilities/taskBoardUtil";
import { useTranslation } from "react-i18next";
import { selectOpenedTaskDisabled } from "@/app/store/tasksSlice";
import BhUserIconGrouped from "@/components/user/BhUserIconGrouped";

interface Props {
  task: ITask;
  onSelect: Function;
}

const TaskParticipantsInfoField: FC<Props> = ({ task, onSelect }) => {
  const { t } = useTranslation();
  const { isActive, toggleIsActive, inputRef } = useInputIsActive();
  const [dropdownValues, setDropdownValues] = useState([] as Array<IUser>);
  const taskBoardUsers = useAppSelector((state) => selectTaskBoardUsersByTaskBoardId(state, task.taskBoardId));
  const currentUser = useAppSelector(selectCurrentUser);
  const isTaskDisabled = useAppSelector(selectOpenedTaskDisabled);
  const participants = task.participants || [];
  const isPersonalTask = isTaskPersonal(task, currentUser);

  const convertToUsers = (auths: Array<ISimpleValidUserAuthority>) => {
    return auths.map((auth) => convertSimpleValidUserAuthorityToUser(auth));
  };

  useEffect(() => {
    if (taskBoardUsers && taskBoardUsers.length > 0) {
      setDropdownValues(convertToUsers(taskBoardUsers));
    }
  }, [taskBoardUsers]);

  if (isPersonalTask) return null;

  const selectParticipant = (user: IUser) => {
    const changedObject = { participants: [...participants, user] };
    onSelect(changedObject);
    setDropdownValues(convertToUsers(taskBoardUsers));
  };

  const removeParticipant = (user: IUser) => {
    const changedObject = { participants: participants.filter((participant) => participant.id !== user.id) };
    onSelect(changedObject);
    setDropdownValues(convertToUsers(taskBoardUsers));
  };

  return (
    <div onClick={() => toggleIsActive(isTaskDisabled)} className="w-full">
      <TaskInfoField
        isActive={isActive}
        text={t("TASK.PARTICIPANTS")}
        children={
          <div className="inline-block w-full">
            {!isActive && (
              <div className="bh-text-deep-ocean flex min-h-[40px] w-full flex-row items-center font-bold">{participants.length > 0 && <BhUserIconGrouped userGroup={participants} />}</div>
            )}
            {isActive && (
              <div className="py-1" onBlur={() => toggleIsActive()}>
                <BhInputWithChips dropdownValues={dropdownValues} onSelect={selectParticipant} currentValues={participants} onRemove={removeParticipant} inputRef={inputRef} />
              </div>
            )}
          </div>
        }
        valueExists={participants.length > 0}
        icon={faEye}
        trailingIcon={!isActive ? (participants.length > 0 ? faCaretDown : faPlus) : undefined}
        disabled={isTaskDisabled}
      />
    </div>
  );
};

export default TaskParticipantsInfoField;
