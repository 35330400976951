import React, { FC, useState } from "react";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhToggleButtonBar from "@components/toggle/BhToggleButtonBar";
import BhModal from "@components/modal/BhModal";
import { ICompany, INewCompanyDTO } from "@/model/ICompany";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";
import { languageMap } from "@/views/home/profile/ProfileTabs/ProfileUserInfo";
import { countriesMap } from "@/utilities/countriesMap";
import { getAlpha2Code, getCountryName } from "@/utilities/countriesUtil";
import { store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType, BauhubPlan } from "@/model/IProject";
import { ICompanyInfo } from "@/model/iCompanyInfo";
import { boSaveNewCompanyAsync } from "@/app/store/backofficeSlice";
import { useAppDispatch } from "@/app/hooks";
import { useNavigate } from "react-router-dom";

interface Props {
  setIsShown: (b: boolean) => void;
}

const BONewCompanyModal: FC<Props> = ({ setIsShown }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [newCompany, setNewCompany] = useState<INewCompanyDTO>({ tier: BauhubPlan.PRO } as INewCompanyDTO);

  const languagePrefix = (newCompany?.language || "en").substring(0, 2);
  const countryNames = countriesMap.map((country) => getCountryName(country.countryCode, languagePrefix)).sort();
  const selectedCountryStates = countriesMap.find((country) => country.countryCode === getAlpha2Code(newCompany?.companyInfo?.country, languagePrefix))?.states || [];

  const saveNewCompany = () => {
    if (newCompany) {
      const languageObject = Object.entries(languageMap).find(([_, value]) => value.label === newCompany.language);

      if (!languageObject || !languageObject[0]) {
        store.dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            children: "Language selection error"
          })
        );
        return;
      }
      const companyInfo = newCompany?.companyInfo ? newCompany?.companyInfo : ({} as ICompanyInfo);
      const company = { ...newCompany, language: languageObject[0], companyInfo, users: [] };
      dispatch(boSaveNewCompanyAsync(company)).then((action) => {
        const savedCompany = action.payload as ICompany;
        setNewCompany({} as INewCompanyDTO);
        setIsShown(false);
        navigate("/bo/company/" + savedCompany.id + "/general");
      });
    }
  };

  const handleNewCompanyFieldChange = (changedValue: Record<string, any>) => {
    setNewCompany({ ...newCompany, ...changedValue } as INewCompanyDTO);
  };

  const isNewCompanySaveButtonDisabled = !newCompany?.name || !newCompany?.tier;

  return (
    <BhModal
      setIsShown={setIsShown}
      header={<h2 className="bh-text-deep-ocean-80">New company</h2>}
      footer={<BhModalFooter cancelButtonText="Cancel" onCancel={setIsShown} confirmButtonText="Save" onConfirm={saveNewCompany} confirmDisabled={isNewCompanySaveButtonDisabled} />}
    >
      <div className="flex h-full flex-col gap-6 overflow-auto px-8 py-8">
        <BhInputStackedLabel initialValue={newCompany?.name} property="name" label={"Name"} onChangeCallback={handleNewCompanyFieldChange} />
        <BhToggleButtonBar label="Plan" items={[{ value: "PRO" }, { value: "LIGHT" }]} onClickAction={handleNewCompanyFieldChange} property="tier" selected={newCompany?.tier} />
        <h3>Contact info</h3>
        <div className="grid grid-cols-2 gap-4">
          <BhComboboxStackedLabel onSelect={handleNewCompanyFieldChange} values={Object.values(languageMap).map((l) => l.label)} property="language" label="Language" required={true} />
          <BhComboboxStackedLabel
            initialValue={newCompany?.companyInfo?.country}
            onSelect={(changedValue: any) =>
              handleNewCompanyFieldChange({
                companyInfo: {
                  ...newCompany?.companyInfo,
                  countryCode: getAlpha2Code(changedValue.country, languagePrefix),
                  country: changedValue.country
                }
              })
            }
            values={countryNames}
            property="country"
            label="Country"
            required={true}
          />
          <BhComboboxStackedLabel
            initialValue={newCompany?.companyInfo?.state}
            values={selectedCountryStates}
            property="state"
            onSelect={(changedValue: any) => handleNewCompanyFieldChange({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
            label="State"
          />
          <BhInputStackedLabel
            initialValue={newCompany?.companyInfo?.city}
            property="city"
            onChangeCallback={(changedValue: any) => handleNewCompanyFieldChange({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
            label="City"
          />
          <BhInputStackedLabel
            initialValue={newCompany?.companyInfo?.zipCode}
            property="zipCode"
            onChangeCallback={(changedValue: any) => handleNewCompanyFieldChange({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
            label="Zip code"
          />
          <BhInputStackedLabel
            initialValue={newCompany?.companyInfo?.street}
            property="street"
            onChangeCallback={(changedValue: any) => handleNewCompanyFieldChange({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
            label="Street"
          />
          <BhInputStackedLabel
            initialValue={newCompany?.companyInfo?.house}
            property="house"
            onChangeCallback={(changedValue: any) => handleNewCompanyFieldChange({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
            label="House/apartment no."
          />
        </div>
        <BhInputStackedLabel
          initialValue={newCompany?.companyInfo?.email}
          property="email"
          onChangeCallback={(changedValue: any) => handleNewCompanyFieldChange({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
          label="E-mail"
        />
        <BhInputStackedLabel
          initialValue={newCompany?.companyInfo?.phoneNumber}
          property="phoneNumber"
          onChangeCallback={(changedValue: any) => handleNewCompanyFieldChange({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
          label="Phone number"
        />
        <h3>Register info</h3>
        <BhInputStackedLabel
          initialValue={newCompany?.companyInfo?.regCode}
          property="regCode"
          onChangeCallback={(changedValue: any) => handleNewCompanyFieldChange({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
          label="Registration code"
        />
        <BhInputStackedLabel
          initialValue={newCompany?.companyInfo?.vatNumber}
          property="vatNumber"
          onChangeCallback={(changedValue: any) => handleNewCompanyFieldChange({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
          label="VAT number"
        />
      </div>
    </BhModal>
  );
};

export default BONewCompanyModal;
