import React, { useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useTranslation } from "react-i18next";
import BhDatepickerStackedLabel from "@components/input/BhDatepickerStackedLabel";
import { subtractMonthsFromDate } from "@/utilities/dateUtility";
import { boGetPaidInvoicesReport } from "@/api/backoffice/boInvoicesAPI";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";

const BOInvoicesPaidReportButton = () => {
  const { t } = useTranslation();
  const [since, setSince] = useState(subtractMonthsFromDate(new Date(), 1));
  const [until, setUntil] = useState(new Date());
  const [modalShown, setModalShown] = useState(false);

  const fetchReport = async () => {
    await boGetPaidInvoicesReport(since, until);
    setModalShown(false);
  };

  return (
    <>
      <BhSecondaryButton buttonProps={{ onClick: () => setModalShown(true) }}>Paid Invoices</BhSecondaryButton>
      {modalShown && (
        <BhModal
          isShown={true}
          setIsShown={setModalShown}
          onClose={setModalShown}
          header={<h2>Choose date range for Paid Invoices Report</h2>}
          children={
            <div className="flex flex-row justify-center gap-2 p-8">
              <BhDatepickerStackedLabel
                initialValue={new Date(since)}
                property="since"
                label="Since"
                onChangeCallback={(value: any) => setSince(value.since)}
                returnISOString={true}
                placeholder={t("LOG.SINCE") as string}
              />
              <BhDatepickerStackedLabel
                initialValue={new Date(until)}
                property="until"
                label="Until"
                onChangeCallback={(value: any) => setUntil(value.until)}
                returnISOString={true}
                placeholder={t("LOG.UNTIL") as string}
              />
            </div>
          }
          footer={<BhModalFooter onCancel={() => setModalShown(false)} onConfirm={fetchReport} />}
          allowOverflow={true}
        />
      )}
    </>
  );
};

export default BOInvoicesPaidReportButton;
