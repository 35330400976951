/* This example requires Tailwind CSS v2.0+ */
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import BhIconButton from "@components/buttons/BhIconButton";
import BhSeparatorMarginless from "@components/separator/BhSeparatorMarginless";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  isShown?: boolean;
  setIsShown: (value: boolean) => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl";
  onClose?: Function;
  disableOutsideClick?: boolean;
  hideSeparator?: boolean;
  allowOverflow?: boolean;
}

const BhModal: FC<Props> = ({ isShown, setIsShown, header, footer, children, size, onClose, disableOutsideClick, hideSeparator, allowOverflow }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [separatorShowing, setSeparatorShowing] = useState(false);

  const showModal = isShown === undefined ? true : isShown;

  useEffect(() => {
    if (containerRef && containerRef.current && containerRef.current.scrollHeight > containerRef.current.clientHeight) {
      setSeparatorShowing(true);
    }
  });

  // sizes - xs, sm, md, lg, xl, 2xl, 3xl, 4xl, 5xl, 6xl, 7xl
  const getModalSizeClass = (size?: string) => {
    return "min-max-w-" + (size ? size : "lg");
  };

  const largeModalArray = ["xl", "2xl", "3xl", "4xl", "5xl", "6xl", "7xl"];

  const isLargeModal = size && largeModalArray.some((s) => s === size);

  const closeModal = () => {
    if (onClose) {
      onClose();
      return;
    }
    setIsShown(false);
  };

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 flex h-full flex-col items-center justify-start overflow-visible"
        onClose={() => {
          if (disableOutsideClick) return;
          closeModal();
        }}
        initialFocus={containerRef}
      >
        <div className="inset-0 flex max-h-full max-w-full items-start justify-center overflow-visible p-12 text-center">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="bh-bg-pigeon-60-alpha95 fixed inset-0 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div
              className={classNames(
                "bauhub-modal bh-bg-raised-white inline-block flex h-full h-full max-w-full transform flex-col overflow-visible rounded text-left align-bottom shadow-xl transition-all",
                getModalSizeClass(size)
              )}
            >
              <div className="flex items-center justify-between pb-3.5 pl-8 pr-4 pt-3.5">
                {header}
                <BhIconButton icon={faTimes} buttonProps={{ onClick: () => closeModal(), classes: " mr-1 mt-2.5 " }} />
              </div>
              {!hideSeparator && (separatorShowing || isLargeModal) && <BhSeparatorMarginless />}
              <div className={classNames(!allowOverflow ? "overflow-hidden" : "", "flex max-h-full justify-center")}>
                <div ref={containerRef} className="w-full justify-center">
                  {children}
                </div>
              </div>
              {footer && (
                <div>
                  {!hideSeparator && (separatorShowing || isLargeModal) && <BhSeparatorMarginless />}
                  <div className="flex justify-end p-4">{footer}</div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BhModal;
