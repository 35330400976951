import React, { FC } from "react";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { useTranslation } from "react-i18next";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  callback: Function;
  disabled?: boolean;
  checklistLanguage?: string;
  isCheckpointList?: boolean;
}

const CheckpointButtonsNoSelection: FC<Props> = ({ callback, disabled, checklistLanguage, isCheckpointList }) => {
  const { t } = useTranslation();

  const saveAnswer = (answer: string) => {
    const valueToSave = { fieldValue: answer };
    callback(valueToSave, true);
  };

  return (
    <div className="flex h-12 flex-row items-center">
      <BhPrimaryButton
        icon={faCheck}
        buttonProps={{
          classes: classNames("h-full mr-0", isCheckpointList ? "w-[126px]" : "w-25"),
          onClick: () => saveAnswer("YES"),
          disabled: disabled
        }}
      >
        {isCheckpointList ? t("CHECKLIST.CHECKPOINT_LIST.YES", { lng: checklistLanguage }) : t("CHECKLIST.CHECKPOINT.YES", { lng: checklistLanguage })}
      </BhPrimaryButton>
      <BhPrimaryButton
        icon={faXmark}
        buttonProps={{
          classes: classNames("bh-bg-error-red h-full mr-0", isCheckpointList ? "w-[126px]" : "w-25"),
          onClick: () => saveAnswer("NO"),
          disabled: disabled
        }}
      >
        {isCheckpointList ? t("CHECKLIST.CHECKPOINT_LIST.NO", { lng: checklistLanguage }) : t("CHECKLIST.CHECKPOINT.NO", { lng: checklistLanguage })}
      </BhPrimaryButton>
      {!isCheckpointList && (
        <BhPrimaryButton
          buttonProps={{
            classes: "bh-bg-pigeon-40 hover:bh-bg-pigeon-45 !active:bh-bg-pigeon-50 h-full mr-0 disabled:bh-text-pigeon-60",
            widthClasses: "w-12",
            textClasses: "bh-text-deep-ocean",
            onClick: () => saveAnswer("N/A"),
            disabled: disabled
          }}
        >
          {t("CHECKLIST.CHECKPOINT.NA", { lng: checklistLanguage })}
        </BhPrimaryButton>
      )}
    </div>
  );
};

export default CheckpointButtonsNoSelection;
