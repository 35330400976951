import React, { FC } from "react";
import BhTableRow from "@components/table/BhTableRow";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { IBoAdminUserResponse } from "@/model/backoffice/users/IBoAdminUserResponse";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";

interface Props {
  user: IBoAdminUserResponse;
  selectedUser: IBoAdminUserResponse;
  onConfirm: Function;
}

const BOTransferAuthsTableRow: FC<Props> = ({ user, selectedUser, onConfirm }) => {
  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>The transferred authorities will be revoked.</h2>}
      body={
        <div>
          <p>
            {`By transferring authorities to ${user.firstName} ${user.lastName} (ID ${user.id}), user ${selectedUser.firstName} ${selectedUser.lastName} (ID ${selectedUser.id}) will lose their authorities.`}
          </p>
        </div>
      }
      confirmationButtonText={"Transfer"}
      handleDelete={() => onConfirm(user.username)}
    />
  ));
  
  return (
    <BhTableRow>
      <td>{user.firstName + " " + user.lastName}</td>
      <td>{user.username}</td>
      <td>{user.id}</td>
      <td>
        <div className="flex justify-end">
          <BhPrimaryButton buttonProps={{ onClick: showDeleteConfirmationModal }}>Transfer</BhPrimaryButton>
        </div>
      </td>
    </BhTableRow>
  );
};

export default BOTransferAuthsTableRow;
