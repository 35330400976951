import React, { FC, ReactNode, useCallback } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  property: string;
  label?: ReactNode;
  checked?: boolean;
  onChange?: Function;
  sizeClasses?: string;
  disabled?: boolean;
}

const BhRadioInput: FC<Props> = ({ property, label, checked, onChange, sizeClasses, disabled }) => {
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const changedValue = { [property]: e.target.checked };
    onChange && onChange(changedValue);
  }, []);

  return (
    <div className="flex items-center gap-3">
      <input
        type="radio"
        property={property}
        checked={checked}
        onChange={(e) => handleChange(e)}
        className={classNames(checked ? "bh-bg-bauhub-green-120" : "bh-border-pigeon-60 border", sizeClasses && sizeClasses, "disabled:bh-bg-smoke focus:ring-0")}
        disabled={disabled}
      />
      {label}
    </div>
  );
};

export default BhRadioInput;
