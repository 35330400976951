import isoCountries from "i18n-iso-countries";
import { languageMap } from "@/views/home/profile/ProfileTabs/ProfileUserInfo";

isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
isoCountries.registerLocale(require("i18n-iso-countries/langs/et.json"));
isoCountries.registerLocale(require("i18n-iso-countries/langs/lv.json"));
isoCountries.registerLocale(require("i18n-iso-countries/langs/lt.json"));
isoCountries.registerLocale(require("i18n-iso-countries/langs/fi.json"));
isoCountries.registerLocale(require("i18n-iso-countries/langs/ru.json"));

export const getCountryName = (code: string, lang = "en") => {
  return isoCountries.getName(code, lang) || "Unknown Country";
};

const getAlpha2CodeFromAllLanguages = (countryName: string) => {
  const langCodes = Object.values(languageMap).map((lang) => lang.langCode);
  for (const langCode of langCodes) {
    const alpha2Code = isoCountries.getAlpha2Code(countryName, langCode);
    if (alpha2Code) {
      return alpha2Code;
    }
  }
  return "";
};

export const getAlpha2Code = (countryName: string | undefined, lang = "en") => {
  if (!countryName) return "";
  const firstPick = isoCountries.getAlpha2Code(countryName, lang);
  if (!firstPick) {
    return getAlpha2CodeFromAllLanguages(countryName);
  }
  return firstPick;
};

export const getAllCountries = (lang = "en") => {
  return Object.entries(isoCountries.getNames(lang, { select: "official" })).map(([code, name]) => ({
    code,
    name
  }));
};
