import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { IBoAdminUserResponse } from "@/model/backoffice/users/IBoAdminUserResponse";
import { IBoAdminUserRequestDTO } from "@/model/backoffice/users/IBoAdminUserRequestDTO";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { IUserAuthority } from "@/model/IUserAuthority";

export function boFindUsers(dto: IBoAdminUserRequestDTO): Promise<Array<IBoAdminUserResponse>> {
  return bauhubPost("/admin/users?st=false", dto);
}

export function boClearUserAuthorityCache() {
  return bauhubGet("/admin/user/cache/clear");
}

export function boSaveAuths(auths: Array<ISimpleValidUserAuthority>): Promise<Array<IUserAuthority>> {
  return bauhubPost("/admin/auth/save", auths);
}

export function boFindAllUsersLike(query: string): Promise<Array<IBoAdminUserResponse>> {
  return bauhubGet("/admin/users?query=" + query);
}

export function boTransferAuthorities(dto: any): Promise<String> {
  return bauhubPost("/admin/auth/transfer", dto);
}
