import BOHeader from "@/views/home/backoffice/views/BOHeader";
import React, { FC, useEffect, useState } from "react";
import { boFetchRevenueAsync, boSelectRevenueSorted } from "@/app/store/backofficeSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhDatepickerStackedLabel from "@components/input/BhDatepickerStackedLabel";
import { IRevenueInfo } from "@/model/invoices/IRevenueInfo";
import BORevenueTable from "@/views/home/backoffice/views/revenue/BORevenueTable";
import { formatDateISO, getFirstDayOfMonth, getLastDayOfMonth } from "@/utilities/dateUtility";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { Link } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BhLink from "@components/buttons/BhLink";

const BORevenue: FC = () => {
  const revenue: IRevenueInfo = useAppSelector(boSelectRevenueSorted);
  const dispatch = useAppDispatch();

  const today = new Date();
  const firstDayPrevMonth = getFirstDayOfMonth(new Date(today.getFullYear(), today.getMonth() - 1, 1));
  const lastDayPrevMonth = getLastDayOfMonth(new Date(today.getFullYear(), today.getMonth() - 1, 1));

  const [dateFilter, setDateFilter] = useState({ start: firstDayPrevMonth, end: lastDayPrevMonth });

  useEffect(() => {
    dispatch(boFetchRevenueAsync({ start: formatDateISO(dateFilter.start), end: formatDateISO(dateFilter.end) }));
  }, [dateFilter]);

  const onChangeFilter = (filter: { start: Date; end: Date }) => {
    setDateFilter({ ...dateFilter, ...filter });
  };

  const previousMonth = () => {
    setDateFilter({ start: firstDayPrevMonth, end: lastDayPrevMonth });
  };

  const thisMonth = () => {
    setDateFilter({ start: getFirstDayOfMonth(new Date()), end: getLastDayOfMonth(new Date()) });
  };

  return (
    <div className="px-16 pb-20 sm:px-4">
      <BOHeader
        customHeader={
          <div className="flex flex-row flex-wrap items-center justify-between gap-2 py-1">
            <h1 className="m-0">Revenue</h1>
            <div>
              <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "/bo/revenue/future"}>
                <BhSecondaryButton>Future Revenue</BhSecondaryButton>
              </Link>
            </div>
          </div>
        }
      />
      <div className="flex flex-col gap-y-1">
        <div className="flex flex-row gap-2">
          <BhDatepickerStackedLabel initialValue={dateFilter.start} property="start" label="Start" onChangeCallback={onChangeFilter} />
          <BhDatepickerStackedLabel initialValue={dateFilter.end} property="end" label="End" onChangeCallback={onChangeFilter} />
        </div>
        <div className="flex flex-row gap-x-2">
          <BhLink onClick={previousMonth}>Eelmine kuu</BhLink>
          <BhLink onClick={thisMonth}>Jooksev kuu</BhLink>
        </div>
      </div>
      <h3 className="mt-6">Monthly subscriptions</h3>
      <BORevenueTable invoices={revenue.monthlySubscriptions}></BORevenueTable>
      <h3 className="mt-6">Annual subscriptions</h3>
      <BORevenueTable invoices={revenue.annualSubscriptions}></BORevenueTable>
      <h3 className="mt-6">Deferred subscriptions</h3>
      <BORevenueTable invoices={revenue.deferredAnnualSubscriptions}></BORevenueTable>
    </div>
  );
};

export default BORevenue;
