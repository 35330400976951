import React, { FC, ReactElement } from "react";
import { FormRowFieldType, IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { classNames } from "@/utilities/jsUtilities";
import { useAppDispatch } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import FormBaseTextField from "@components/form/base/body/fields/textField/FormBaseTextField";
import { IFormFieldSaveRequest } from "@/model/form/IFormFieldSaveRequest";
import { useTranslation } from "react-i18next";
import FormBaseDateField from "@components/form/base/body/fields/dateField/FormBaseDateField";
import { saveFormBaseVersionRowFieldAsync } from "@/app/store/form/formBaseRowsSlice";
import FormBaseTimeField from "@components/form/base/body/fields/timeField/FormBaseTimeField";
import FormBaseSeparator from "@components/form/base/body/fields/separatorField/FormBaseSeparator";
import FormBaseWeatherField from "@components/form/base/body/fields/weatherField/FormBaseWeatherField";
import FormBaseProtocolField from "@components/form/base/body/fields/protocolField/FormBaseProtocolField";
import FormBasePeopleField from "@components/form/base/body/fields/peopleField/FormBasePeopleField";
import FormBaseWysiwygField from "@components/form/base/body/fields/WysiwygField/FormBaseWysiwygField";
import FormBaseAttachmentField from "@components/form/base/body/fields/attachmentField/FormBaseAttachmentField";
import FormBaseTableField from "@components/form/base/body/fields/tableField/FormBaseTableField";

interface Props {
  field: IFormBaseVersionRowField;
  removeFieldCallback: Function;
  disabled?: boolean;
  companyId: EntityId;
  formBaseId: EntityId;
  formBaseVersionId: EntityId;
}

const FormBaseRowFieldContainer: FC<Props> = ({ field, removeFieldCallback, disabled, formBaseId, companyId, formBaseVersionId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const saveField = (changedProperty: string, changedValue: any) => {
    const formFieldSaveRequest = {
      formBaseVersionId: formBaseVersionId,
      changedObjectId: field._id,
      changedProperty: changedProperty,
      changedValue: changedValue
    } as IFormFieldSaveRequest;
    return dispatch(saveFormBaseVersionRowFieldAsync({ companyId: companyId, formBaseId: formBaseId, formFieldSaveRequest: formFieldSaveRequest }));
  };

  const fieldTypeToElementMap: Record<FormRowFieldType, ReactElement> = {
    ATTACHMENT: <FormBaseAttachmentField fieldValue={field.value} disabled={disabled} saveFieldCallback={saveField} />,
    DATE: <FormBaseDateField fieldValue={field.value} disabled={disabled} saveFieldCallback={saveField} />,
    TABLE: <FormBaseTableField fieldProperty={field.property} fieldValue={field.value} disabled={disabled} saveFieldCallback={saveField} />,
    PEOPLE: <FormBasePeopleField fieldValue={field.value} fieldProperty={field.property} disabled={disabled} saveFieldCallback={saveField} />,
    PROTOCOL: <FormBaseProtocolField fieldValue={field.value} disabled={disabled} saveFieldCallback={saveField} />,
    SEPARATOR: <FormBaseSeparator />,
    WEATHER: <FormBaseWeatherField fieldValue={field.value} fieldProperty={field.property} disabled={disabled} saveFieldCallback={saveField} />,
    TEXT: <FormBaseTextField fieldValue={field.value} disabled={disabled} saveFieldCallback={saveField} />,
    TIME: <FormBaseTimeField fieldValue={field.value} disabled={disabled} saveFieldCallback={saveField} />,
    WYSIWYG: <FormBaseWysiwygField fieldValue={field.value} disabled={disabled} saveFieldCallback={saveField} />,
    MNT_MP_TABLE_FIELD: <></>,
    TOOPLAAN_TABLE: <></>,
    TEXT_EDITOR: <></>,
    SCM_TABLE: <></>,
    SCM_TASKS: <></>,
    ATTACHMENTS_WITH_DATES: <></>,
    UPLOAD: <></>
  };

  return (
    <div
      className={classNames(
        "hover:bh-border-deep-ocean-80 group relative flex h-full w-full flex-col rounded border py-2 transition duration-200 ease-in-out",
        field.fieldType === FormRowFieldType.SEPARATOR ? "bh-border-white" : "bh-border-pigeon-40 px-2"
      )}
    >
      <div className="bh-bg-deep-ocean-80 bh-text-smoke text-12px absolute -top-6 left-0 hidden w-fit rounded-t font-bold leading-4 group-hover:block">
        <div className="flex flex-row items-center">
          <div className={classNames("py-1 pl-1.5", disabled ? "pr-1.5" : "pr-0.5")}>{t("FORMBUILDER.FORM.BASE.FIELD." + field.fieldType)}</div>
          {!disabled && (
            <div className="hover:bh-bg-deep-ocean-60 flex-shrink-0-tr flex h-6 w-6 flex-row items-center justify-center rounded hover:cursor-pointer" onClick={() => removeFieldCallback(field)}>
              <FontAwesomeIcon icon={faXmark} size="lg" />
            </div>
          )}
        </div>
      </div>
      {fieldTypeToElementMap[field.fieldType] || field.fieldType + " (old)"}
    </div>
  );
};

export default FormBaseRowFieldContainer;
