import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { declineSignature, fetchContainerSignatures, resendSignInvite } from "@/api/signatureAPI";
import { IFileEntity } from "@/model/files/IFileEntity";
import { deleteSignature, deleteSignInvite, saveQueueEnabledSignInvites, saveSignInvite, swapSignInvite } from "@/api/signInviteAPI";
import { selectCurrentUserUsername } from "@/app/store/userSlice";
import { ISignInvite } from "@/model/ISignInvite";
import { convertSignInvitesToSignatureHolders } from "@/utilities/signatureUtil";

const signaturesAdapter = createEntityAdapter<ISignatureHolder, EntityId>({
  selectId: (a) => a.uniqueId
});

const signaturesInitialState = signaturesAdapter.getInitialState<{}>({});

// export const fetchUserUnsignedInvitesAsync = createAsyncThunk("signatures/fetchUserUnsignedInvites", async () => {
//   return fetchUserUnsignedInvites();
// });

export const fetchContainerSignaturesAsync = createAsyncThunk("signatures/fetchContainerSignatures", async (fileEntity: IFileEntity) => {
  return fetchContainerSignatures(fileEntity.projectId, fileEntity.uuid, fileEntity.id);
});

export const declineSignatureAsync = createAsyncThunk("signatures/declineSignature", async (signatureHolder: ISignatureHolder, { dispatch }) => {
  return declineSignature(signatureHolder);
});

export const deleteSignInviteAsync = createAsyncThunk("signatures/deleteSignInvite", async (signatureHolder: ISignatureHolder, { dispatch }) => {
  return deleteSignInvite(signatureHolder);
});

export const deleteSignatureAsync = createAsyncThunk("signatures/deleteSignature", async (deleteObject: { fileEntityId: EntityId; signatureHolder: ISignatureHolder }, { dispatch }) => {
  return deleteSignature(deleteObject.fileEntityId, deleteObject.signatureHolder);
});

export const resendSignInviteAsync = createAsyncThunk("signatures/resendSignInvite", async (signatureHolder: ISignatureHolder, { dispatch }) => {
  return resendSignInvite(signatureHolder);
});

export const saveSignInviteAsync = createAsyncThunk("signatures/saveSignInvite", async (signInvite: ISignInvite, { dispatch }) => {
  return saveSignInvite(signInvite);
});

export const saveQueueEnabledSignInvitesAsync = createAsyncThunk("signatures/saveQueueEnabledSignInvites", async (signatureHolders: Array<ISignatureHolder>, { dispatch }) => {
  return saveQueueEnabledSignInvites(signatureHolders);
});

export const swapSignInviteAsync = createAsyncThunk("signatures/swapSignInvites", async (swapObject: { containerUuid: string; swapUsername: string; withUsername: string }, { dispatch }) => {
  return swapSignInvite(swapObject.containerUuid, swapObject.swapUsername, swapObject.withUsername);
});

export const handleSignInvitesToSignatureHolders = createAsyncThunk("signatures/handleSignInvites", async (signInvites: Array<ISignInvite>, { getState, dispatch }) => {
  const state = getState() as RootState;
  const existingSignatures = state.signatures.entities;
  const signatureHolders = convertSignInvitesToSignatureHolders(signInvites);
  dispatch(signaturesModified(signatureHolders));

  const currentUserName = state.user.current.username;
  const signatureHoldersToDelete = Object.values(existingSignatures).filter((signatureHolder) => {
    if (signatureHolder.isUnsignedInvite && currentUserName === signatureHolder.username) {
      return !signInvites.some((invite) => signatureHolder.containerUuid === invite.containerUuid);
    }
    return false;
  });

  const idsToDelete = signatureHoldersToDelete.map((signatureHolder) => signatureHolder?.uniqueId) as EntityId[];

  if (idsToDelete.length > 0) {
    dispatch(signaturesRemoved(idsToDelete));
  }
});

const signaturesSlice = createSlice({
  name: "signatures",
  initialState: signaturesInitialState,
  reducers: {
    signatureModified: signaturesAdapter.upsertOne,
    signaturesModified: signaturesAdapter.upsertMany,
    signaturesRemoved: signaturesAdapter.removeMany,
    signatureAdded: (state, action: PayloadAction<ISignatureHolder>) => {
      const signature = action.payload;
      signaturesAdapter.upsertOne(state, signature);

      if (signature.username) {
        const signatureHolderToDelete = Object.values(state.entities).find(
          (signatureHolder) => signatureHolder && !signatureHolder.signatureId && signatureHolder.containerUuid === signature.containerUuid && signatureHolder.username === signature.username
        );
        if (signatureHolderToDelete) {
          signaturesAdapter.removeOne(state, signatureHolderToDelete.uniqueId);
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContainerSignaturesAsync.pending, (state) => {})
      .addCase(fetchContainerSignaturesAsync.fulfilled, (state, action) => {
        const signatureEntities = action.payload;
        if (signatureEntities.length > 0) {
          const containerUuid = signatureEntities[0].containerUuid;
          if (containerUuid) {
            const existingContainerSignatureEntityIds = Object.values(state.entities)
              .filter((sig) => sig && sig.containerUuid === containerUuid)
              .map((sig) => sig && sig.uniqueId) as Array<EntityId>;
            signaturesAdapter.removeMany(state, existingContainerSignatureEntityIds);
          }
        }
        signaturesAdapter.upsertMany(state, signatureEntities);
      })
      .addCase(declineSignatureAsync.pending, (state) => {})
      .addCase(declineSignatureAsync.fulfilled, (state, action) => {
        signaturesAdapter.upsertOne(state, action.payload);
      })
      .addCase(deleteSignInviteAsync.pending, (state) => {})
      .addCase(deleteSignInviteAsync.fulfilled, (state, action) => {
        const uniqueId = "invite-" + action.payload.id;
        signaturesAdapter.removeOne(state, uniqueId);
      })
      .addCase(deleteSignatureAsync.pending, (state) => {})
      .addCase(deleteSignatureAsync.fulfilled, (state, action) => {
        signaturesAdapter.removeOne(state, action.payload.uniqueId);
      })
      .addCase(resendSignInviteAsync.pending, (state) => {})
      .addCase(resendSignInviteAsync.fulfilled, (state, action) => {
        signaturesAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveSignInviteAsync.pending, (state) => {})
      .addCase(saveSignInviteAsync.fulfilled, (state, action) => {
        signaturesAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveQueueEnabledSignInvitesAsync.pending, (state) => {})
      .addCase(saveQueueEnabledSignInvitesAsync.fulfilled, (state, action) => {
        signaturesAdapter.upsertMany(state, action.payload);
      })
      .addCase(swapSignInviteAsync.pending, (state) => {})
      .addCase(swapSignInviteAsync.fulfilled, (state, action) => {
        signaturesAdapter.upsertMany(state, action.payload);
      });
  }
});

export const { signatureAdded, signaturesModified, signatureModified, signaturesRemoved } = signaturesSlice.actions;

export const { selectAll: selectAllSignatures, selectById: selectSignatureById, selectIds: selectSignatureIds } = signaturesAdapter.getSelectors((state: RootState) => state.signatures);

export const selectSignaturesForContainer = (containerUuid: string) =>
  createSelector(selectAllSignatures, (signatures) => signatures.filter((signature) => signature.containerUuid === containerUuid && !signature.deleted));

export const selectDeletedSignaturesForContainer = (containerUuid: string) =>
  createSelector(selectAllSignatures, (signatures) => signatures.filter((signature) => signature.containerUuid === containerUuid && signature.deleted));

export const selectNextToSignForContainer = (containerUuid: string) =>
  createSelector(selectSignaturesForContainer(containerUuid), (signaturesForContainer) => {
    const invitesOutOfSignatures = signaturesForContainer.filter((signature) => signature.isUnsignedInvite && !signature.declined);
    const nextToSign = invitesOutOfSignatures.length > 0 && invitesOutOfSignatures.reduce((a, b) => (a.orderNumber < b.orderNumber ? a : b));
    return nextToSign;
  });

export const selectSignatureToSwapDown = (signature: ISignatureHolder) =>
  createSelector(selectSignaturesForContainer(signature.containerUuid), (signatures) => {
    let result: ISignatureHolder | undefined;
    signatures.forEach((containerSignature) => {
      if (!containerSignature.deleted && containerSignature.orderNumber > signature.orderNumber) {
        if (!result || (result && result.orderNumber > containerSignature.orderNumber)) result = containerSignature;
      }
    });
    return result;
  });

export const selectSignatureToSwapUp = (signature: ISignatureHolder) =>
  createSelector([selectSignaturesForContainer(signature.containerUuid), selectNextToSignForContainer(signature.containerUuid)], (signatures, nextToSignSignature) => {
    let result: ISignatureHolder | undefined;
    signatures.forEach((containerSignature) => {
      if (
        !containerSignature.deleted &&
        !containerSignature.signatureId &&
        !containerSignature.declined &&
        containerSignature.orderNumber < signature.orderNumber &&
        nextToSignSignature &&
        containerSignature.username !== nextToSignSignature.username
      ) {
        if (!result || (result && result.orderNumber < containerSignature.orderNumber)) result = containerSignature;
      }
    });
    return result;
  });

export const selectCountOfUnsignedInvitesForProject = (projectId: EntityId) =>
  createSelector(
    selectAllSignatures,
    selectCurrentUserUsername,
    (signatureHolders, currentUsername) =>
      signatureHolders.filter(
        (signatureHolder) =>
          signatureHolder.projectId === projectId && signatureHolder.isUnsignedInvite && !signatureHolder.declined && !signatureHolder.deleted && signatureHolder.username === currentUsername
      ).length
  );

export default signaturesSlice.reducer;
