import React, { FC, Fragment, useEffect } from "react";
import { useAppDispatch } from "@/app/hooks";
import { fetchUserUnsignedSignInvitesAsync } from "@/app/store/signInvitesSlice";

interface Props {
  open: boolean;
}

const BhCompanyDropdownSignaturesFetch: FC<Props> = ({ open }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    open && dispatch(fetchUserUnsignedSignInvitesAsync());
  }, [open]);

  useEffect(() => {
    dispatch(fetchUserUnsignedSignInvitesAsync());
  }, []);

  return <></>;
};

export default BhCompanyDropdownSignaturesFetch;
