import React, { FC } from "react";
import { IUser } from "@/model/IUser";
import { IContact } from "@/model/IContact";
import BhAvatarFallback from "@components/user/BhAvatarFallback";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import { classNames } from "@/utilities/jsUtilities";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { IPredefinedWorkGroupUser, IUserAuthority } from "@/model/IUserAuthority";
import { IBoAdminUserResponse } from "@/model/backoffice/users/IBoAdminUserResponse";

interface Props {
  user: IUser | IContact | ISimpleValidUserAuthority | IUserAuthority | ICompanyUser | ICompanyInvitedUser | IPartyPrivilegeListItemDTO | IPredefinedWorkGroupUser | IBoAdminUserResponse;
  size?: number;
  withoutRing?: boolean;
}

const BhUserIcon: FC<Props> = ({ user, size, withoutRing }) => {
  let heightClasses = "h-8 w-8";
  if (size) {
    heightClasses = "h-" + size / 4 + " w-" + size / 4;
  }

  const firstNameFieldExists = "firstName" in user && user.firstName !== "" && user.firstName !== null;
  const lastNameFieldExists = "lastName" in user && user.lastName !== "" && user.lastName !== null;
  const noNameExists = !firstNameFieldExists && !lastNameFieldExists;
  const userExists = "userExists" in user ? user.userExists : "username" in user;

  return (
    <div className={classNames("flex inline-flex rounded-full align-middle " + heightClasses, !withoutRing && "ring-2 ring-white")}>
      {/*TODO: User avatar objektide külge mappida BE-s*/}
      {/*{userAvatarUuid && (*/}
      {/*  <img className={classNames("rounded-full", size ? size : heightClasses)} crossorigin src={"https://s3.eu-central-1.amazonaws.com/www.bauhub.ee/public/avatar/" + userAvatarUuid} alt="Bauhub logo" />*/}
      {/*)}*/}
      {!noNameExists && <BhAvatarFallback firstName={user.firstName} lastName={user.lastName} userExists={userExists} size={size?.toString() || "32"} />}
      {noNameExists && <BhAvatarFallback firstName={""} lastName={""} userExists={false} size={size?.toString() || "32"} />}
    </div>
  );
};

export default BhUserIcon;
