import React, { FC, useState } from "react";
import { faRightLeft } from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { IBoAdminUserResponse } from "@/model/backoffice/users/IBoAdminUserResponse";
import BOTransferAuthsModal from "@components/backoffice/users/BOTransferAuthsModal";

interface Props {
  user: IBoAdminUserResponse;
}

const BOUsersTableRowDropdown: FC<Props> = ({ user }) => {
  const [transferAuthoritiesModalOpen, setTransferAuthoritiesModalOpen] = useState(false);
  const actionsMap = {
    TRANSFER_AUTHORITIES: {
      text: "Transfer authorities",
      icon: faRightLeft,
      function: () => setTransferAuthoritiesModalOpen(true)
    },
    ADD_AUTHORITIES: {
      text: "Add authorities",
      icon: faPlus,
      function: () => {}
    }
  };
  const userActions = [actionsMap.TRANSFER_AUTHORITIES, actionsMap.ADD_AUTHORITIES];
  return (
    <>
      <BhDropdown
        button={<BhIconButton icon={faEllipsisVertical} />}
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
        menu={<BhDropdownMenu values={userActions} type={BhDropdownTypeEnum.STRING} textProperty="text" />}
      />
      {transferAuthoritiesModalOpen && <BOTransferAuthsModal setIsShown={setTransferAuthoritiesModalOpen} selectedUser={user} />}
    </>
  );
};

export default BOUsersTableRowDropdown;
