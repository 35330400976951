import React, { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhModal from "@components/modal/BhModal";
import { IFileEntity } from "@/model/files/IFileEntity";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import FileNameWrapped from "@/features/fileContainer/FileNameWrapped";
import { useTranslation } from "react-i18next";

interface Props {
  isMoveModal: boolean;
  onConfirm: Function;
  onClose: Function;
  formsInFilesToCopyOrMove: Array<IFileEntity>;
}

const CopyOrMoveFormsToRootDirWarningModal: FC<Props> = ({ isMoveModal, onConfirm, onClose, formsInFilesToCopyOrMove }) => {
  const { t } = useTranslation();
  const [folders, setFolders] = useState<Array<string>>([]);
  const [filesInFolder, setFilesInFolder] = useState<Array<{ formFileEntity: IFileEntity; path: string }>>([]);

  useEffect(() => {
    let folders = new Set<string>();
    let forms = [] as Array<{ formFileEntity: IFileEntity; path: string }>;

    formsInFilesToCopyOrMove.forEach((formFileEntity) => {
      const pathParts = formFileEntity.filePath.split("/");
      const formPath = pathParts.slice(0, -1).join("/");
      forms.push({ formFileEntity: formFileEntity, path: formPath });
      folders.add(formPath);
    });
    setFolders(Array.from(folders).sort());
    setFilesInFolder(forms);
  }, []);

  const handleConfirm = () => {
    onConfirm([]);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={handleClose}
      size={"xl"}
      header={
        <h2>
          <FontAwesomeIcon className="bh-text-warning-yellow mr-5" icon={faExclamationTriangle} />
          {t(`${isMoveModal ? "MOVE" : "COPY"}.FILES.MODAL.COPY_FORMS_WARNING.HEADER`)}
        </h2>
      }
      footer={
        <BhModalFooter
          onConfirm={handleConfirm}
          onCancel={onClose}
          confirmButtonText={t(`${isMoveModal ? "MOVE" : "COPY"}.FILES.MODAL.COPY_FORMS_WARNING.CONFIRM_BUTTON`) as string}
          cancelButtonText={t("GLOBAL.CANCEL") as string}
          isWarningButton={true}
        />
      }
    >
      <BhScrollableBody>
        <div className="px-8 pt-8">
          <div className="flex flex-col">{t(`${isMoveModal ? "MOVE" : "COPY"}.FILES.MODAL.COPY_FORMS_WARNING.BODY`)}</div>
          {folders.map((folder) => {
            return (
              <div key={folder} className="overflow-scroll">
                <div className="text-13px l-h-20px bh-text-deep-ocean-60 flex flex-row items-center border-b p-2 font-bold">
                  <FontAwesomeIcon icon={faFolder} className="pr-1" />
                  <FileNameWrapped fileName={folder} />
                </div>
                {filesInFolder.map((fileAndPathDTO) => {
                  return (
                    fileAndPathDTO.path === folder && (
                      <div key={fileAndPathDTO.formFileEntity.id} className="flex h-6 w-full flex-row items-center overflow-ellipsis rounded p-1.5">
                        <BhFileCardIcon fileEntity={fileAndPathDTO.formFileEntity} fileCardSize={FileCardSize.SMALL} />
                        <span className="bh-text-deep-ocean ml-1.5 overflow-hidden whitespace-nowrap">
                          <FileNameWrapped fileName={fileAndPathDTO.formFileEntity.name} />
                        </span>
                      </div>
                    )
                  );
                })}
              </div>
            );
          })}
        </div>
      </BhScrollableBody>
    </BhModal>
  );
};

export default CopyOrMoveFormsToRootDirWarningModal;
