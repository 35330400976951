import { getCookie, serializeUrl } from "@/utilities/jsUtilities";
import { IBauhubException } from "@/model/IBauhubException";
import { store } from "@/app/store";
import {
  IFrozenProjectDetails,
  ISecurityDetails,
  securityTooLowDetailsSet,
  setProjectFrozenModalDetails,
  setProjectFrozenModalVisible,
  showSecurityTooLowModalSet,
  showSecurityTooLowSyncModalSet,
  toastFlagAdded
} from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

const handleErrors = async (response: Response) => {
  if (response.status === 404 && !response.url.includes("disableErrors=true")) {
    store.dispatch(
      toastFlagAdded({
        id: uuidv4(),
        type: BauhubBannerType.ERROR,
        disappear: true,
        translate: true,
        children: "ERROR.NO_ENDPOINT"
      })
    );
    throw new Error("No such server endpoint");
  }
  if (response.status === 500 && !response.url.includes("disableErrors=true")) {
    store.dispatch(
      toastFlagAdded({
        id: uuidv4(),
        type: BauhubBannerType.ERROR,
        disappear: true,
        translate: true,
        children: "ERROR.SERVER_ERROR"
      })
    );
    throw new Error("500 ERROR");
  }
  let error: IBauhubException = await response.json();

  if (error?.error === "SECURITY_LEVEL_TOO_LOW") {
    store.dispatch(showSecurityTooLowModalSet(true));
    store.dispatch(securityTooLowDetailsSet(error.params as ISecurityDetails));
  }

  if (error?.error === "SECURITY_LEVEL_TOO_LOW_SYNC") {
    store.dispatch(showSecurityTooLowSyncModalSet(true));
    store.dispatch(securityTooLowDetailsSet(error.params as ISecurityDetails));
  }

  if (error?.error === "PROJECT_FROZEN") {
    store.dispatch(setProjectFrozenModalVisible(true));
    store.dispatch(setProjectFrozenModalDetails(error.params as IFrozenProjectDetails));
  }

  const errorDescription = error.error;
  const jsError = new Error(errorDescription);
  jsError.stack = error.stackTrace;
  jsError.name = response.statusText;

  if (!response.url.includes("disableErrors=true")) {
    store.dispatch(
      toastFlagAdded({
        id: uuidv4(),
        type: BauhubBannerType.ERROR,
        translate: true,
        disappear: true,
        children: error.error
      })
    );
  }
  throw jsError;
};

export function bauhubGetWithBaseUrl(appendUrl: string, baseUrl?: string, urlParams?: object) {
  let urlAppendixAndParams = appendUrl;

  if (urlParams) {
    urlAppendixAndParams += "?" + new URLSearchParams(serializeUrl(urlParams));
  }
  return fetch((baseUrl ?? "") + urlAppendixAndParams)
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        await handleErrors(response);
      }
    })
    .then((responseJson) => {
      return responseJson;
    });
}

export function bauhubPostWithBaseUrl(appendUrl: string, baseUrl?: string, dto?: any, urlParams?: object) {
  let urlAppendixAndParams = appendUrl;

  if (urlParams) {
    urlAppendixAndParams += "?" + new URLSearchParams(serializeUrl(urlParams));
  }

  let requestParams: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "X-XSRF-TOKEN": getCookie("XSRF-TOKEN") || ""
    }
  };

  if (dto) {
    requestParams.body = JSON.stringify(dto);
  }

  return fetch((baseUrl ?? "") + urlAppendixAndParams, requestParams)
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        await handleErrors(response);
      }
    })
    .then((responseJson) => {
      return responseJson;
    });
}

export function bauhubPost(appendUrl: string, dto?: any, urlParams?: object) {
  return bauhubPostWithBaseUrl(appendUrl, ConfigSingleton.getInstance().getConfig().REACT_APP_API_URL, dto, urlParams);
}

export function bauhubPublicPost(appendUrl: string, dto?: any, urlParams?: object) {
  return bauhubPostWithBaseUrl(appendUrl, ConfigSingleton.getInstance().getConfig().REACT_APP_PUBLIC_API_URL, dto, urlParams);
}

export function bauhubGet(appendUrl: string, urlParams?: object) {
  return bauhubGetWithBaseUrl(appendUrl, ConfigSingleton.getInstance().getConfig().REACT_APP_API_URL, urlParams);
}

export function bauhubPublicGet(appendUrl: string, urlParams?: object) {
  return bauhubGetWithBaseUrl(appendUrl, ConfigSingleton.getInstance().getConfig().REACT_APP_PUBLIC_API_URL, urlParams);
}
