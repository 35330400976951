import React, { FC, ReactNode } from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const BhLink: FC<Props> = ({ children, ...rest }) => {
  return (
    <div className="text-14px bh-text-deep-ocean cursor-pointer leading-5 hover:underline" {...rest}>
      {children}
    </div>
  );
};

export default BhLink;
