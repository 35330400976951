import React, { FC, useEffect, useState } from "react";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectCompanyId, selectCurrentProjectId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";
import ProjectFormsSettingsHeader from "@components/form/settings/projectSettings/ProjectFormsSettingsHeader";
import ProjectFormsSettingsTable from "@components/form/settings/projectSettings/ProjectFormsSettingsTable";
import {
  fetchCompanyCustomFormBasesAsync,
  fetchCompanyFormBasesForProjectAsync,
  fetchProjectFormBasesForCompanyProjectAsync,
  selectCompanyCustomFormBases,
  selectCompanyFormBases,
  selectCompanyFormBasesStatus
} from "@/app/store/form/formBasesSlice";
import NoFormBases from "@components/form/settings/projectSettings/NoFormBases";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { IFormBaseFilter } from "@/model/form/IFormBaseFilter";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import FormBuilderEuFundingFooter from "@components/form/settings/FormBuilderEuFundingFooter";

const FormsProjectSettings: FC = () => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const companyId = useAppSelector(selectCurrentProjectCompanyId);
  const rootDocumentDirId = useAppSelector(selectRootDocumentDirectoryId);
  const formBases = useAppSelector(selectCompanyFormBases);
  const customFormBases = useAppSelector(selectCompanyCustomFormBases);
  const formBaseStatus = useAppSelector(selectCompanyFormBasesStatus);
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState<IFormBaseFilter>({ searchString: "", usernames: [], showProjectTemplatesOnly: false, showCompanyTemplatesOnly: false, orderBy: "type", reversed: false });
  const navigate = useNavigate();
  const formBasesToDisplay = [...formBases, ...customFormBases];

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.DOCUMENTS));
    dispatch(fetchCompanyFormBasesForProjectAsync({ companyId: companyId, projectId: projectId }));
    dispatch(fetchProjectFormBasesForCompanyProjectAsync({ companyId: companyId, projectId: projectId }));
    dispatch(fetchCompanyCustomFormBasesAsync({ companyId: companyId, projectId: projectId }));
  }, []);

  const navigateToDirectory = () => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/dir/${rootDocumentDirId}`);
  };

  return (
    <DetailViewContainer
      header={
        <DetailViewHeading>
          <div className="flex flex-row items-center gap-x-2">
            <div className={classNames("bh-text-deep-ocean-60 cursor-pointer")} onClick={navigateToDirectory}>
              {t("GLOBAL.DOCUMENTS")}
            </div>
            <div className="flex flex-row items-center gap-x-2">
              <div className="bh-text-deep-ocean-40">
                <FontAwesomeIcon icon={faChevronRight} size={"2xs"} />
              </div>
              <div>{t("COMPANY.SETTINGS.FORM_BASES")}</div>
            </div>
          </div>
        </DetailViewHeading>
      }
    >
      <>
        <BhScrollableBody header={<ProjectFormsSettingsHeader filter={filter} setFilter={setFilter} />}>
          {(formBaseStatus === BhStateStatusType.PENDING || formBasesToDisplay.length > 0) && (
            <>
              <ProjectFormsSettingsTable filter={filter} setFilter={setFilter} />
              <FormBuilderEuFundingFooter />
            </>
          )}
          {formBaseStatus === BhStateStatusType.SUCCESS && <>{(!formBasesToDisplay || formBasesToDisplay.length === 0) && <NoFormBases />}</>}
        </BhScrollableBody>
      </>
    </DetailViewContainer>
  );
};

export default FormsProjectSettings;
