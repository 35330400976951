import React, { FC } from "react";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import { useAppDispatch } from "@/app/hooks";
import { boChangeInvoicePaymentDateAsync, boMarkInvoicePaidAsync } from "@/app/store/backofficeSlice";
import BhDatePicker from "@components/input/BhDatePicker";
import dayjs from "dayjs";

interface Props {
  salesInvoice: ISalesInvoice;
  disabled?: boolean;
}

const BOInvoicePaidField: FC<Props> = ({ salesInvoice, disabled }) => {
  const dispatch = useAppDispatch();

  const markInvoicePaid = () => {
    let newSalesInvoice;
    if (!salesInvoice.paid) {
      newSalesInvoice = { ...salesInvoice, paid: true };
    } else {
      newSalesInvoice = { ...salesInvoice, paid: false };
    }
    dispatch(boMarkInvoicePaidAsync(newSalesInvoice));
  };

  const changeInvoicePaymentDate = (paymentDate: Record<string, Date>) => {
    const newSalesInvoice = { ...salesInvoice, ...paymentDate };
    dispatch(boChangeInvoicePaymentDateAsync(newSalesInvoice));
  };

  return (
    <div className="relative flex items-center">
      <div>
        <BhCheckbox isChecked={salesInvoice.paid} onChange={markInvoicePaid} property={"paid"} large={true} disabled={disabled} />
      </div>
      {salesInvoice.paymentDate && salesInvoice.paid && (
        <BhDatePicker
          property={"paymentDate"}
          initialValue={new Date(salesInvoice.paymentDate)}
          placeholder={dayjs(salesInvoice.paymentDate).format("DD.MM.YY")}
          classes="bg-none border-none hover:underline cursor-pointer"
          onChangeCallback={changeInvoicePaymentDate}
          returnISOString={true}
        />
      )}
    </div>
  );
};

export default BOInvoicePaidField;
