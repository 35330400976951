import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

//FIGMA: https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=296%3A4329

interface Props {
  type: BhTagType;
  children?: React.ReactNode;
  classes?: string;
  onClickCallback?: Function;
  onDeleteClick?: Function;
  object?: any;
}

const BhTag: FC<Props> = ({ type, children, classes, onClickCallback, onDeleteClick, object }) => {
  switch (type) {
    case BhTagType.TAG:
      classes += " bh-border-pigeon-60 bh-bg-pigeon-20 bh-text-deep-ocean-80 border hover:bh-bg-pigeon-40";
      break;
    case BhTagType.ALLTOOVOTJA:
      classes += " bh-border-pigeon-40 bh-bg-pigeon-40 bh-text-deep-ocean";
      break;
    case BhTagType.TELLIJA:
      classes += " bh-border-deep-ocean bh-bg-deep-ocean bh-text-white";
      break;
    case BhTagType.ADMINISTRAATOR:
      classes += " bh-border-pigeon bh-bg-pigeon bh-text-white";
      break;
    case BhTagType.REGISTREERIMATA:
      classes += " bh-border-pigeon-50 bh-bg-white bh-text-pigeon";
      break;
    case BhTagType.ARHIVEERITUD:
      classes += " bh-border-pigeon-50 bh-bg-smoke bh-text-pigeon";
      break;
    case BhTagType.VOLGNEVUS:
      classes += " bh-border-error-red-20 bh-bg-error-red-10 bh-text-error-red";
      break;
    case BhTagType.MUSTAND:
      classes += " bh-bg-warning-yellow bh-border-warning-yellow bh-text-deep-ocean";
      break;
    case BhTagType.PROJEKT_TOOS:
      classes += " bh-border-bauhub-green-120 bh-bg-mint bh-text-dark-jungle";
      break;
    case BhTagType.TIP:
      classes += " bh-bg-bauhub-green-120 bh-text-white";
      break;
    case BhTagType.TASK_STATUS:
      classes += " bh-bg-pigeon-40 bh-text-pigeon";
      break;
    case BhTagType.CUSTOM:
      classes += " bh-text-white";
      break;
    case BhTagType.PAID:
      classes += " bh-border-mint-120 bh-bg-mint bh-text-dark-jungle";
      break;
    case BhTagType.FAILED:
      classes += " bh-border-error-red-20 bh-bg-error-red-10 bh-text-error-red hover:bh-bg-error-red-20";
      break;
    case BhTagType.SENT:
      classes += " bh-border-bauhub-green-120 bh-bg-mint bh-text-dark-jungle hover:bh-bg-mint-120b";
      break;
    case BhTagType.NOT_SENT:
      classes += " bh-bg-pigeon-40 hover:bh-bg-pigeon-20 bh-text-pigeon";
      break;
    default:
      classes += " bh-border-pigeon-20 bh-bg-smoke bh-text-deep-ocean-80";
  }

  return (
    <>
      {children && (
        <div
          className={classNames(
            classes,
            onClickCallback && "cursor-pointer",
            onDeleteClick ? "pr-1px pl-1.5" : "px-1.5",
            "py-0.25 l-h-14px text-12px font-weight-700 my-0.25 mr-1 flex w-min max-w-full flex-row items-center whitespace-nowrap rounded-xl border font-semibold"
          )}
          onClick={
            onClickCallback
              ? (e) => {
                  e.stopPropagation();
                  onClickCallback();
                }
              : undefined
          }
        >
          <div className="flex-1 overflow-hidden truncate">{children}</div>
          {onDeleteClick && (
            <div className="hover:bh-bg-deep-ocean hover:bh-text-white ml-0.5 flex h-3.5 w-3.5 flex-row items-center justify-center rounded-full">
              <FontAwesomeIcon
                className="h-2.5 w-2.5 cursor-pointer"
                icon={faTimes}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteClick(object);
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BhTag;
