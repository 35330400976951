import React, { FC, useState } from "react";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import { useAppDispatch } from "@/app/hooks";
import { boClearUserAuthorityCacheAsync, boFindUsersAsync } from "@/app/store/backofficeSlice";
import BhInputWithPrefixDropdown from "@components/input/BhInputWithPrefixDropdown";
import { IBoAdminUserRequestDTO } from "@/model/backoffice/users/IBoAdminUserRequestDTO";

const BOUsersSearch: FC = () => {
  const [request, setRequest] = useState<IBoAdminUserRequestDTO>({ includeDeleted: false } as IBoAdminUserRequestDTO);
  const dispatch = useAppDispatch();
  const isDisabled = !request?.firstName && !request?.lastName && !request?.username && !request.phoneNumber && !request?.identityCode;

  const onChange = (changedValue: Record<string, string>) => {
    setRequest({ ...request, ...changedValue } as IBoAdminUserRequestDTO);
  };

  const onSubmit = () => {
    if (request) {
      dispatch(boFindUsersAsync({ ...request} as IBoAdminUserRequestDTO));
    }
  };

  const clearCache = () => {
    dispatch(boClearUserAuthorityCacheAsync());
  };

  return (
    <div>
      <div className="grid grid-cols-6 items-center gap-4 whitespace-nowrap">
        <BhInputStackedLabel onEnterCallback={onSubmit} initialValue={request.firstName} property="firstName" label="First name" onChangeCallback={onChange} onChangeCallbackOnEveryValue={true} />
        <BhInputStackedLabel onEnterCallback={onSubmit} initialValue={request.lastName} property="lastName" label="Last name" onChangeCallback={onChange} onChangeCallbackOnEveryValue={true} />
        <BhInputStackedLabel onEnterCallback={onSubmit} initialValue={request.username} property="username" label="E-mail" onChangeCallback={onChange} onChangeCallbackOnEveryValue={true} />
        <BhInputStackedLabel onEnterCallback={onSubmit} initialValue={request.phoneNumber} property="phoneNumber" label="Phone" onChangeCallback={onChange} onChangeCallbackOnEveryValue={true} />
        <BhInputStackedLabel onEnterCallback={onSubmit} initialValue={request.identityCode} property="identityCode" label="ID number" onChangeCallback={onChange} onChangeCallbackOnEveryValue={true} />
        <div className="col-span-1 mt-6 flex items-center gap-4">
          <BhPrimaryButton buttonProps={{ onClick: onSubmit, disabled: isDisabled }}>Search</BhPrimaryButton>
          <BhTextOnlyButton icon={faArrowsRotate} buttonProps={{ onClick: clearCache }}>
            Empty cache
          </BhTextOnlyButton>
        </div>
      </div>
      <div className="-ml-5">
        <BhCheckboxWithText text="Show expired authorities" isChecked={request.includeDeleted} property="includeDeleted" onChange={onChange} />
      </div>
    </div>
  );
};

export default BOUsersSearch;
