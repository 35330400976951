import React, { FC } from "react";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhDatePicker from "@components/input/BhDatePicker";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boSelectFilter, boSetFilters } from "@/app/store/backofficeSlice";
import BhFilter from "@components/filters/BhFilter";
import { IBoPlanFilter, ITrialsFilter } from "@/model/backoffice/IBoFilter";

interface Props {}

const BOTrialsFilter: FC<Props> = ({}) => {
  const filter = useAppSelector(boSelectFilter);
  const dispatch = useAppDispatch();

  const onNameFilterChange = (searchQuery: Record<string, string>) => {
    const changedObject = { ...filter, ...searchQuery };
    dispatch(boSetFilters(changedObject));
  };

  const onPlanFilterChange = (changedValue: IBoPlanFilter) => {
    const trialsFilter: ITrialsFilter = { ...filter.trialsFilter };
    const updatedPlanFilter = trialsFilter.plans.map((plan) => (plan.value === changedValue.value ? { ...plan, selected: !plan.selected } : plan));
    const newTrialsFilter = { ...trialsFilter, plans: updatedPlanFilter };
    const newFilter = { ...filter, trialsFilter: newTrialsFilter };
    dispatch(boSetFilters(newFilter));
  };

  const isPlanSelected = (plan: IBoPlanFilter) => {
    return filter.trialsFilter.plans.flatMap((p) => p.selected && p.value).includes(plan.value);
  };

  const onPlanFilterReset = () => {
    const newFilterPlans = filter.trialsFilter.plans.map((plan) => ({ ...plan, selected: false }));
    const newFilter = { ...filter, trialsFilter: { ...filter.trialsFilter, plans: newFilterPlans } };
    dispatch(boSetFilters(newFilter));
  };

  const onSinceDateChange = (changedObj: Record<string, string>) => {
    const newFilter = { ...filter, trialsFilter: { ...filter.trialsFilter, date: { ...filter.trialsFilter.date, since: changedObj.date } } };
    dispatch(boSetFilters(newFilter));
  };

  const onSinceDateReset = () => {
    const newFilter = { ...filter, trialsFilter: { ...filter.trialsFilter, date: { ...filter.trialsFilter.date, since: "" } } };
    dispatch(boSetFilters(newFilter));
  };

  const onUntilDateChange = (changedObj: Record<string, string>) => {
    const newFilter = { ...filter, trialsFilter: { ...filter.trialsFilter, date: { ...filter.trialsFilter.date, until: changedObj.date } } };
    dispatch(boSetFilters(newFilter));
  };

  const onUntilDateReset = () => {
    const newFilter = { ...filter, trialsFilter: { ...filter.trialsFilter, date: { ...filter.trialsFilter.date, until: "" } } };
    dispatch(boSetFilters(newFilter));
  };

  return (
    <div>
      <BhFilterContainer>
        <div>
          <BhSearchInputWBG initialValue={filter.trialsSearchQuery} property="trialsSearchQuery" placeholder="Search trial" inputClasses="w-52" onChangeCallback={onNameFilterChange} />
        </div>
        <BhDatePicker property={"date"} placeholder="Trial start" onChangeCallback={onSinceDateChange} onResetCallback={onSinceDateReset} returnISOString={true} />
        <BhDatePicker property={"date"} placeholder="Trial end" onChangeCallback={onUntilDateChange} onResetCallback={onUntilDateReset} returnISOString={true} />
        <BhFilter
          title={"Plan"}
          values={filter.trialsFilter.plans}
          textProperty={"name"}
          onSelect={onPlanFilterChange}
          isSelected={isPlanSelected}
          onReset={onPlanFilterReset}
          selectedValues={filter.trialsFilter.plans?.filter((plan) => plan.selected).length}
        />
      </BhFilterContainer>
    </div>
  );
};

export default BOTrialsFilter;
