import React, { FC, ReactElement } from "react";
import { CompanyStatus, ICompany, ICompanyComment } from "@/model/ICompany";
import { BhSectionMessageError, BhSectionMessageSomethingElse, BhSectionMessageSuccess } from "@components/sectionMessage/BhSectionMessages";
import { faRollerCoaster } from "@fortawesome/pro-solid-svg-icons";
import { faSnooze } from "@fortawesome/pro-regular-svg-icons";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boSaveCompanyCommentAsync, boSaveCompanyLanguageAsync, boSaveCompanyTrialEndAsync, boSaveCompanyTrialStartAsync, boSelectCompany } from "@/app/store/backofficeSlice";
import { EntityId } from "@reduxjs/toolkit";
import BhInlineInput from "@components/input/BhInlineInput";
import { languageMap } from "@/views/home/profile/ProfileTabs/ProfileUserInfo";
import BhDatepickerStackedLabel from "@components/input/BhDatepickerStackedLabel";
import BhInputLabel from "@components/input/BhInputLabel";
import BhDropdown from "@components/dropdown/BhDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";

export interface IBOCompanyTrialDateDTO {
  companyId: EntityId;
  date: Date;
}

const BOCompanyAndBillingGeneral: FC = () => {
  const company = useAppSelector(boSelectCompany);
  const dispatch = useAppDispatch();
  const statusMap: Record<CompanyStatus, ReactElement> = {
    [CompanyStatus.TRIAL]: (
      <BhSectionMessageSomethingElse icon={faRollerCoaster} classes="bh-bg-warning-yellow-10 bh-border-warning-yellow font-bold" iconClasses="bh-text-warning-yellow">
        On trial.
      </BhSectionMessageSomethingElse>
    ),
    [CompanyStatus.TRIAL_EXPIRED]: (
      <BhSectionMessageError>
        <span className="font-bold">Trial expired.</span> At least one project created but none of the projects are confirmed
      </BhSectionMessageError>
    ),
    [CompanyStatus.INACTIVE]: (
      <BhSectionMessageSomethingElse icon={faSnooze}>
        <span className="font-bold">Inactive.</span> Trial has ended without any projects created.
      </BhSectionMessageSomethingElse>
    ),
    [CompanyStatus.ACTIVE]: (
      <BhSectionMessageSuccess>
        <span className="font-bold">Active.</span> At least 1 active project.
      </BhSectionMessageSuccess>
    )
  };

  const handleChangeTrialStart = (changedValue: Record<string, Date>) => {
    const dateWithCompanyId = { companyId: company.id, date: changedValue.trialStart };
    dispatch(boSaveCompanyTrialStartAsync(dateWithCompanyId));
  };

  const handleChangeTrialEnd = (changedValue: Record<string, Date>) => {
    const dateWithCompanyId = { companyId: company.id, date: changedValue.trialEnd };
    dispatch(boSaveCompanyTrialEndAsync(dateWithCompanyId));
  };

  const handleCommentChange = (changedObj: ICompanyComment) => {
    const companyComment = { ...company.comment, ...changedObj, companyId: company.id };
    dispatch(boSaveCompanyCommentAsync(companyComment));
  };

  const handleLanguageChange = (language: any) => {
    dispatch(boSaveCompanyLanguageAsync({ id: company.id, language: language.value } as ICompany));
  };

  return (
    <div>
      <h2>General</h2>
      <div className="mt-4 flex w-full gap-6">
        <div className="w-80">
          <p className="font-bold">Status</p>
          {statusMap[company.status]}
        </div>
        <div className="w-full">
          <p className="font-bold">Comment</p>
          <BhInlineInput
            initialValue={company?.comment?.comment}
            placeholder="Any comments about the company?"
            property="comment"
            inputClasses="border-none w-full"
            saveCallback={handleCommentChange}
          />
        </div>
        <div className="w-32">
          <div className="flex flex-col">
            <BhInputLabel>Language</BhInputLabel>
            <BhDropdown
              buttonClasses={"w-28"}
              button={
                <button className="min-w-70px bh-border-smoke-40 hover:bh-border-pigeon-70 flex h-9 w-full items-center justify-between rounded border p-2">
                  {languageMap[company?.language]?.label}
                  <FontAwesomeIcon icon={faCaretDown} />
                </button>
              }
              menu={
                <BhDropdownMenu
                  values={Object.values(languageMap)}
                  closeOnItemClick={true}
                  type={BhDropdownTypeEnum.STRING}
                  onSelect={handleLanguageChange}
                  textProperty={"label"}
                  widthClass={"w-28"}
                />
              }
            />
          </div>
        </div>
      </div>
      <div className="">
        <div className="mt-6 grid max-w-80 grid-cols-2 gap-4">
          <div className="full-w-datepicker">
            <BhDatepickerStackedLabel initialValue={new Date(company.trialStart)} property="trialStart" label="Trial start" onChangeCallback={handleChangeTrialStart} />
          </div>
          <div className="full-w-datepicker">
            <BhDatepickerStackedLabel initialValue={new Date(company.trialEnd)} property="trialEnd" label="Trial end" onChangeCallback={handleChangeTrialEnd} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BOCompanyAndBillingGeneral;
