import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { IYearlyInvoiceOverview } from "@/model/invoices/IYearlyInvoiceOverview";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import { IMonthlyInvoiceOverview } from "@/model/invoices/IMonthlyInvoiceOverview";
import { IInvoiceEmail } from "@/model/invoices/IInvoiceEmail";
import { IFileUploadPresignedDTO } from "@/model/files/IFileUploadPresignedDTO";
import { IMonthlyInvoiceOverviewRow } from "@/model/invoices/IMonthlyInvoiceOverviewRow";
import { IRevenueInfo } from "@/model/invoices/IRevenueInfo";
import { IFutureRevenueDTO } from "@/model/invoices/IFutureRevenueDTO";
import { formatDateISO } from "@/utilities/dateUtility";
import { getCookie, serializeUrl } from "@/utilities/jsUtilities";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { ISalesInvoiceReport } from "@/model/billing/ISalesInvoiceReport";

export function boFetchYearlyInvoiceOverview(year: string): Promise<IYearlyInvoiceOverview> {
  return bauhubGet("/admin/year/" + year + "/invoice/overview");
}

export function boFetchMonthlyInvoiceOverview(month: string): Promise<IMonthlyInvoiceOverview> {
  return bauhubGet("/admin/month/" + month + "/invoice/overview");
}

export function boDownloadInvoice(uuid: string): Promise<any> {
  return bauhubGet("/admin/invoice/" + uuid + "/presigned");
}

export function boFetchRevenue(start: string, end: string): Promise<IRevenueInfo> {
  return bauhubGet("/admin/invoices/revenue", { start: start, end: end });
}

export function boFetchFutureRevenue(selected: string): Promise<Array<IFutureRevenueDTO>> {
  return bauhubGet("/admin/invoices/revenue/future", { selected: selected });
}

export function boCreditInvoice(invoice: ISalesInvoice): Promise<IMonthlyInvoiceOverview> {
  return bauhubPost("/admin/invoice/credit", invoice);
}

export function boMarkInvoicePaid(invoice: ISalesInvoice): Promise<IMonthlyInvoiceOverview> {
  return bauhubPost("/admin/invoice/mark/paid", invoice);
}

export function boChangeInvoicePaymentDate(invoice: ISalesInvoice): Promise<ISalesInvoice> {
  return bauhubPost("/admin/invoice/change/payment/date", invoice);
}

export function boResendSalesInvoice(invoice: ISalesInvoice): Promise<void> {
  return bauhubPost("/admin/invoice/resend?disableSuccessToast=true", invoice);
}

export function boSendKmdInfA(emails: IInvoiceEmail): Promise<void> {
  return bauhubPost("/admin/monthly/invoice/report/kmd", emails);
}

export function boSendMonthlyInvoiceReport(emails: IInvoiceEmail): Promise<void> {
  return bauhubPost("/admin/monthly/invoice/report", emails);
}

export function boSaveNewInvoice(invoice: ISalesInvoice): Promise<IMonthlyInvoiceOverviewRow> {
  return bauhubPost("/admin/invoice/custom", invoice);
}

export function boChangeInvoice(invoice: ISalesInvoice): Promise<IMonthlyInvoiceOverviewRow> {
  return bauhubPost("/admin/invoice/change/generate", invoice);
}

export function boGetPresignedUploadUrlForCustomSalesInvoice(contentType: string): Promise<IFileUploadPresignedDTO> {
  return bauhubGet("/url/upload/invoice/custom", { contentType: contentType });
}

export function boFetchOutstandingSalesInvoicesForDateRange(since: string, until: string): Promise<Array<IMonthlyInvoiceOverviewRow>> {
  return bauhubGet("/admin/invoice/outstanding", { since: since, until: until });
}

export function boFetchDoubtfulSalesInvoicesForDateRange(since: string, until: string, doubtfulSince: string, doubtfulUntil: string): Promise<Array<IMonthlyInvoiceOverviewRow>> {
  return bauhubGet("/admin/invoice/doubtful", {
    since: since,
    until: until,
    doubtfulSince: doubtfulSince,
    doubtfulUntil: doubtfulUntil
  });
}

export function boFetchSalesInvoiceReports(): Promise<Array<ISalesInvoiceReport>> {
  return bauhubGet("/admin/invoice/reports");
}

export function boRunSalesInvoiceReportsJob(): Promise<any> {
  return bauhubGet("/admin/salesinvoicereportjob");
}

export function boFetchSalesInvoiceReportPresignedUrl(report: any): Promise<any> {
  return bauhubPost("/admin/invoice/report/presigned", report);
}

export async function boGetPaidInvoicesReport(since: Date, until: Date): Promise<any> {
  let urlAppendixAndParams = "/admin/invoice/paid";
  const sinceString = formatDateISO(since);
  const untilString = formatDateISO(until);

  urlAppendixAndParams += "?" + new URLSearchParams(serializeUrl({ since: sinceString, until: untilString }));

  try {
    const response = await fetch(ConfigSingleton.getInstance().getConfig().REACT_APP_API_URL + urlAppendixAndParams, {
      method: "get",
      headers: {
        "Content-Type": "text/csv",
        "X-XSRF-TOKEN": getCookie("XSRF-TOKEN") || ""
      },
      credentials: "include"
    });

    if (!response.ok) {
      throw new Error("File download failed!");
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "paid_invoices_" + sinceString + "_to_" + untilString + ".csv"; // Set file name
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Download failed:", error);
  }
}
