import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  children?: React.ReactNode;
  classes?: string;
  trRef?: any;
  highlight?: boolean;
}

const BhSpreadsheetTableTR: FC<Props> = ({ children, classes, trRef, highlight }) => {
  return (
    <tr className={classNames(classes, highlight ? "bh-bg-smoke-50 font-bold" : "hover:bh-bg-smoke-50", "group relative h-8")} ref={trRef}>
      {children}
    </tr>
  );
};

export default BhSpreadsheetTableTR;
