import React, { FC, useEffect, useState } from "react";
import BOHeader from "@/views/home/backoffice/views/BOHeader";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boFetchAllCompaniesAsync, boSelectAllCompaniesFilteredAndSorted, boSelectCompaniesLoadingState } from "@/app/store/backofficeSlice";
import BOCompaniesFilterRow from "@/views/home/backoffice/views/companies/BOCompaniesFilterRow";
import BONewCompanyModal from "@/views/home/backoffice/views/companies/BONewCompanyModal";
import BOCompaniesListTable from "@/views/home/backoffice/views/companies/BOCompaniesListTable";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";

const BOCompanies: FC = () => {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const companies = useAppSelector(boSelectAllCompaniesFilteredAndSorted);
  const loading = useAppSelector(boSelectCompaniesLoadingState);

  useEffect(() => {
    dispatch(boFetchAllCompaniesAsync());
  }, []);

  return (
    <BhScrollableBody
      header={
        <div className="px-16 sm:px-4">
          <BOHeader />
          <div className="flex flex-col">
            <BOCompaniesFilterRow onNewCompanyClickCallback={setModalOpen} />
            {loading === BhStateStatusType.SUCCESS && <div className="bh-text-pigeon mb-2 text-sm">{companies.length} Companies</div>}
          </div>
        </div>
      }
      keepScrollForKey="boCompaniesList"
    >
      <div className="px-16 sm:px-4">
        <BOCompaniesListTable />
      </div>
      {modalOpen && <BONewCompanyModal setIsShown={setModalOpen} />}
    </BhScrollableBody>
  );
};

export default BOCompanies;
