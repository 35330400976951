import React from "react";
import BOHeader from "@/views/home/backoffice/views/BOHeader";
import BOUsersSearch from "@components/backoffice/users/BOUsersSearch";
import BOUsersTable from "@components/backoffice/users/BOUsersTable";

const BOUsers = () => {
  return (
    <div className="px-16 sm:px-4">
      <BOHeader />
      <BOUsersSearch />
      <BOUsersTable />
    </div>
  );
};

export default BOUsers;
