import React, { FC, useCallback } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";
import FormPeopleFieldTableHeader from "@components/form/fields/peopleField/FormPeopleFieldTableHeader";
import FormPeopleFieldTableRow from "@components/form/fields/peopleField/FormPeopleFieldTableRow";
import { IFormDataSaveRequest } from "@/model/IForm";

interface Props {
  field: any;
  isPreview?: boolean;
  disabled?: boolean;
  addCallback?: Function;
  saveCallback?: Function;
  removeNestedDataCallback?: Function;
}

const FormPeopleField: FC<Props> = ({ field, isPreview, disabled, addCallback, saveCallback, removeNestedDataCallback }) => {
  const people = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, field.property) : field.value.prefilledPersons));
  const disableTitleEdit = field.value.columns.name.nameChangeDisabled;
  const { t } = useTranslation();

  const enabledColumns = Object.keys(field.value.columns).filter((key) => field.value.columns[key].enabled);

  const addPerson = useCallback(() => {
    const defaultValues: Record<string, any> = {
      status: "ATTENDED",
      isSigner: true,
      date: new Date()
    };
    const title = disableTitleEdit && people.length > 0 ? people[0].title : field.value.columns.name.inputLabel;
    let newPerson = { title: title } as any;

    enabledColumns.forEach((key) => {
      newPerson[key] = defaultValues[key] !== undefined ? defaultValues[key]! : "";
    });
    addCallback && addCallback({ changes: newPerson, path: field.property } as IFormDataSaveRequest);
  }, [people]);

  return (
    <div className="flex w-full flex-col gap-y-1.5">
      {people &&
        people.length > 0 &&
        people.map((person: any, index: number) => {
          return (
            <div className="flex flex-col gap-y-1" key={index}>
              <FormPeopleFieldTableHeader
                person={person}
                columns={field.value.columns}
                fieldProperty={field.property}
                enabledColumns={enabledColumns}
                disabled={disabled || (index !== 0 && disableTitleEdit)}
                saveCallback={saveCallback}
                isPreview={isPreview}
              />
              <FormPeopleFieldTableRow
                person={person}
                enabledColumns={enabledColumns}
                saveCallback={saveCallback}
                fieldProperty={field.property}
                removeNestedDataCallback={removeNestedDataCallback}
                disabled={disabled}
              />
            </div>
          );
        })}
      <div>
        <BhTextOnlyButton
          icon={faCirclePlus}
          buttonProps={{
            disabled: disabled,
            onClick: addPerson
          }}
        >
          {t("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.ADD_NEW")}
        </BhTextOnlyButton>
      </div>
    </div>
  );
};

export default FormPeopleField;
