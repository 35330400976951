import React, { FC, useState } from "react";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import BOFormsCopyToLanguageModal from "@/views/home/backoffice/views/forms/BOFormsCopyToLanguageModal";

const BOFormsCopyToLanguage: FC = () => {
  const [modalShown, setModalShown] = useState(false);

  return (
    <div>
      <BhSecondaryButton buttonProps={{ onClick: () => setModalShown(true) }}>Copy form template to specific country code</BhSecondaryButton>
      {modalShown && <BOFormsCopyToLanguageModal setIsShown={setModalShown} />}
    </div>
  );
};

export default BOFormsCopyToLanguage;
