import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhMenuListItem from "@components/nav/BhMenuListItem";
import { faCashRegister, faEyes, faFort, faHandHorns, faHelmetSafety, faPeach, faPersonFromPortal, faPuzzlePieceSimple, faRollerCoaster, faSkull, faWorm } from "@fortawesome/pro-solid-svg-icons";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import React from "react";
import { faBuilding, faMoneyBill1Wave } from "@fortawesome/pro-regular-svg-icons";
import { SubResource } from "@/model/IUserAuthority";
import { EntityId } from "@reduxjs/toolkit";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { useAppSelector } from "@/app/hooks";
import { currentUserBackOfficeAccessSubResources, isCurrentUserSuperUser } from "@/app/store/userSlice";

interface BOMenuItem {
  id: EntityId;
  title: string;
  href: string;
  icon: IconDefinition;
  iconColor: string;
  subResource?: SubResource;
}

const BauhubBOHome = () => {
  const isSuperAdmin = useAppSelector(isCurrentUserSuperUser);
  const currentUserSubResources = useAppSelector(currentUserBackOfficeAccessSubResources);

  const clientObjects: Array<BOMenuItem> = [
    {
      id: 1,
      title: "Companies",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "companies",
      icon: faFort,
      iconColor: "bh-bg-success-green-20",
      subResource: SubResource.BO_COMPANIES
    },
    {
      id: 2,
      title: "Projects",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "projects",
      icon: faBuilding,
      iconColor: "bh-bg-pigeon-30"
    },
    {
      id: 3,
      title: "Users",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "users",
      icon: faHelmetSafety,
      iconColor: "bh-bg-warning-yellow-20",
      subResource: SubResource.BO_USERS
    },
    {
      id: 4,
      title: "Invoices",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "invoices",
      icon: faCashRegister,
      iconColor: "bh-bg-error-red-10"
    },
    {
      id: 5,
      title: "Revenue",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "revenue",
      icon: faMoneyBill1Wave,
      iconColor: "bh-bg-mint-110b"
    },
    {
      id: 6,
      title: "Trials",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "trials",
      icon: faRollerCoaster,
      iconColor: "bh-bg-mint"
    }
  ].filter((o) => isSuperAdmin || (o.subResource !== undefined && currentUserSubResources.includes(o.subResource)));

  const developerObjects: Array<BOMenuItem> = [
    {
      id: 1,
      title: "Backend",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "backend",
      icon: faPeach,
      iconColor: "bh-bg-royal-blue-light"
    },
    {
      id: 2,
      title: "Template developer ™",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "template-developer",
      icon: faPuzzlePieceSimple,
      iconColor: "bh-bg-eggplant-10"
    },
    {
      id: 3,
      title: "Forms",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "forms",
      icon: faWorm,
      iconColor: "bh-bg-pigeon-30"
    },
    {
      id: 4,
      title: "Events",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "events",
      icon: faHandHorns,
      iconColor: "bh-bg-mint-110"
    },
    {
      id: 5,
      title: "Baudrive logs",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "baudrive-logs",
      icon: faEyes,
      iconColor: "bh-bg-mediterranean-10"
    },
    {
      id: 6,
      title: "Killswitch",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "killswitch",
      icon: faSkull,
      iconColor: "bh-bg-error-red-20"
    },
    {
      id: 7,
      title: "Asyncjobs",
      href: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "asyncjobs",
      icon: faPersonFromPortal,
      iconColor: "bh-bg-warning-yellow-20"
    }
  ].filter((o) => isSuperAdmin);

  return (
    <BhScrollableBody>
      <div className="px-16 pt-6">
        <h1>Admin</h1>
        {clientObjects.length > 0 && (
          <div className="flex flex-row sm:flex-col">
            <div className="w-72">
              <h2>Clients</h2>
            </div>
            <div className="w-640px sm:w-full">
              {clientObjects.map((menuObject) => (
                <BhMenuListItem key={menuObject.id} url={menuObject.href} iconColor={menuObject.iconColor} icon={menuObject.icon} linkName={menuObject.title} />
              ))}
            </div>
          </div>
        )}
        {developerObjects.length > 0 && (
          <div className="mt-12 flex flex-row sm:flex-col">
            <div className="w-72">
              <h2>Developer</h2>
            </div>
            <div className="w-640px sm:w-full">
              {developerObjects.map((menuObject) => (
                <BhMenuListItem key={menuObject.id} url={menuObject.href} iconColor={menuObject.iconColor} icon={menuObject.icon} linkName={menuObject.title} />
              ))}
            </div>
          </div>
        )}
      </div>
    </BhScrollableBody>
  );
};

export default BauhubBOHome;
