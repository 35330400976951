import React, { FC, useEffect, useState } from "react";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { IBillingModel, ICompanyBillingRequisites, IProjectBillingRequisites, ProjectInvoiceType } from "@/model/IBillingInfo";
import BhToggleButtonBar, { IBhToggleButtonBarItem } from "@components/toggle/BhToggleButtonBar";
import { Trans, useTranslation } from "react-i18next";
import { IProjectBilling } from "@/model/billing/IProjectBilling";
import { textWithVariables } from "@/utilities/jsUtilities";
import parse from "html-react-parser";
import { useAppSelector } from "@/app/hooks";
import { BhSectionMessageInfo, BhSectionMessageWarning } from "@components/sectionMessage/BhSectionMessages";
import BhInputLabel from "@components/input/BhInputLabel";
import { selectCurrentUser } from "@/app/store/userSlice";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";
import { countriesMap } from "@/utilities/countriesMap";
import BillingFormVatValidator from "@/views/home/project/detail/projectSettings/BillingFormVatValidator";
import dayjs from "dayjs";
import { getCountryName } from "@/utilities/countriesUtil";

interface Props {
  billingForm: IProjectBillingRequisites | IProjectBilling | ICompanyBillingRequisites;
  companyBillingRequisites?: ICompanyBillingRequisites;
  onChange: Function;
  billingModel?: IBillingModel;
  companyHasMonthlyAggregateInvoice?: boolean;
  companyHasTrial?: boolean;
  dontShowProjectBillingRequisitesType?: boolean;
  companyTrialEnd?: string;
  projectName?: string;
  isProjectFrozen?: boolean;
}

const BillingForm: FC<Props> = ({
  billingForm,
  billingModel,
  companyBillingRequisites,
  onChange,
  companyHasMonthlyAggregateInvoice,
  companyHasTrial,
  dontShowProjectBillingRequisitesType,
  companyTrialEnd,
  projectName,
  isProjectFrozen
}) => {
  const { t, i18n } = useTranslation();
  const [initialBillingForm, setInitialBillingForm] = useState<IProjectBillingRequisites | IProjectBilling>();
  const [initialBillingType, setInitialBillingType] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const currentUser = useAppSelector(selectCurrentUser);

  const countryNames = countriesMap.map((country) => getCountryName(country.countryCode, i18n.language)).sort();

  useEffect(() => {
    if ("type" in billingForm) {
      setInitialBillingType(billingForm?.type);
      setInitialBillingForm(billingForm);
    }
  }, []);

  useEffect(() => {
    checkActiveBilling();
  }, ["type" in billingForm && billingForm?.type]);

  const checkActiveBilling = () => {
    if ("type" in billingForm) {
      if (companyHasMonthlyAggregateInvoice && companyBillingRequisites) {
        const monthlyBillingSelected = billingForm.type === ProjectInvoiceType.MONTH;
        if (monthlyBillingSelected) {
          onChange({
            ...billingForm,
            customerRecipientEmails: companyBillingRequisites.customerRecipientEmails || "",
            customerRecipient: companyBillingRequisites.customerRecipient || "",
            customerAddress: companyBillingRequisites.customerAddress || "",
            customerCountry: companyBillingRequisites.customerCountry || "",
            customerRegCode: companyBillingRequisites.customerRegCode || "",
            customerVatNumber: companyBillingRequisites.customerVatNumber || "",
            customerAdditionalInfo: companyBillingRequisites.customerAdditionalInfo || "",
            customerCivilian: companyBillingRequisites.customerCivilian,
            einvoice: companyBillingRequisites.einvoice
          } as IProjectBillingRequisites);
          setIsDisabled(true);
        } else {
          initialBillingForm &&
            onChange({
              ...billingForm,
              customerRecipientEmails: initialBillingForm.customerRecipientEmails || "",
              customerRecipient: initialBillingForm.customerRecipient || "",
              customerAddress: initialBillingForm.customerAddress || "",
              customerCountry: initialBillingForm.customerCountry || "",
              customerRegCode: initialBillingForm.customerRegCode || "",
              customerVatNumber: initialBillingForm.customerVatNumber || "",
              customerAdditionalInfo: initialBillingForm.customerAdditionalInfo || "",
              customerCivilian: initialBillingForm.customerCivilian,
              einvoice: initialBillingForm.einvoice
            } as IProjectBillingRequisites);
          setIsDisabled(false);
        }
      }
    }
  };

  const isOrderActive = ("confirmed" in billingForm && billingForm.confirmed) || !companyHasTrial;
  const isBillingModelChanged = billingForm && "type" in billingForm && billingModel && initialBillingType !== billingForm?.type;
  const orderIsActiveAndHasBillingDate = isOrderActive && "billingDate" in billingForm;

  const givenDatePlusOneYear = (billingDate: Date) => {
    return `${dayjs(billingDate).format("DD.MM.YYYY")} - ${dayjs(billingDate).add(1, "year").format("DD.MM.YYYY")}`;
  };

  const givenDatePlusOneMonth = (billingDate: Date) => {
    return `${dayjs(billingDate).format("DD.MM.YYYY")} - ${dayjs(billingDate).add(1, "month").format("DD.MM.YYYY")}`;
  };

  return (
    <div className="mt-6 flex flex-col gap-4 pb-10 ">
      {!isOrderActive && companyTrialEnd && <BhSectionMessageWarning>{parse(textWithVariables(t("PROJECT.CONFIRM.BILLING.MODAL.BODY"), { trialEnd: companyTrialEnd }))}</BhSectionMessageWarning>}
      {projectName && (
        <div className="flex flex-col">
          <div className="flex flex-row">
            <BhInputLabel>{t("COMPANY.SETTINGS.ORDER.INVOICES.PROJECT")}</BhInputLabel>
          </div>
          <span className="px-0.5">{projectName}</span>
        </div>
      )}
      {!dontShowProjectBillingRequisitesType && "type" in billingForm && (
        <>
          <BhToggleButtonBar
            label={t("PROJECT.BILLING.MODEL") as string}
            items={
              [
                !isProjectFrozen && {
                  value: ProjectInvoiceType.MONTH,
                  text: t("PROJECT.BILLING.MONTH") as string,
                  detail: `(${billingModel?.monthlyPrice || "-"} €)`
                },
                {
                  value: ProjectInvoiceType.YEAR,
                  text: t("PROJECT.BILLING.YEAR") as string,
                  detail: `(${billingModel?.yearlyPrice || "-"} €)`
                }
              ].filter(Boolean) as Array<IBhToggleButtonBarItem>
            }
            onClickAction={(changedValue: IProjectBillingRequisites | IProjectBilling | ICompanyBillingRequisites) => {
              onChange(changedValue);
            }}
            property="type"
            selected={billingForm?.type}
          />
          <div>
            <span>{t("PROJECT.CONFIRM.BILLING.MODAL.INFORMATION_TEXT")}</span>
            <a href={currentUser.language === "et_EE" ? "https://www.bauhub.ee/hinnad" : "https://www.bauhub.ee/prices"} target={"_blank"} className="font-bold underline" rel="noreferrer">
              {t("PROJECT.CONFIRM.BILLING.MODAL.COMPARE_SUBSCRIPTION_TYPES")}
            </a>
          </div>
          {isProjectFrozen && <BhSectionMessageInfo children={t("PROJECT_FROZEN_BILLING_MODAL.BANNER")} />}
        </>
      )}
      {isBillingModelChanged && orderIsActiveAndHasBillingDate && (
        <BhSectionMessageWarning>
          {parse(
            textWithVariables(t("PROJECT.BILLING.CHANGE.MODEL.WARNING"), {
              period:
                billingForm.type === ProjectInvoiceType.MONTH ? givenDatePlusOneMonth(new Date(billingForm.billingDate as string)) : givenDatePlusOneYear(new Date(billingForm.billingDate as string)),
              price: billingForm.type === ProjectInvoiceType.MONTH ? `${billingModel.monthlyPrice}` : `${billingModel.yearlyPrice}`
            })
          )}
        </BhSectionMessageWarning>
      )}
      <div className="flex flex-col">
        <h3 className="px-0.5">{t("PROJECT.BILLING_FORM.BILLING_DETAILS")}</h3>
        {!isDisabled && <span className="px-0.5">{t("PROJECT.BILLING.CHANGE.MODAL.BODY")}</span>}
      </div>
      {isDisabled && (
        <BhSectionMessageInfo>
          <Trans>PROJECT.ACTIVATION.BILLING.AGGREGATE_INVOICE_WARNING</Trans>
        </BhSectionMessageInfo>
      )}
      <BhInputStackedLabel
        disabled={isDisabled}
        initialValue={billingForm?.customerRecipientEmails}
        property={"customerRecipientEmails"}
        label={t("PROJECT.ACTIVATION.BILLING.MODAL.ADDRESS") as string}
        onChangeCallback={onChange}
        required={true}
        onBlurAction={onChange}
        debounceLength={900}
      />
      <div className="flex flex-none items-center gap-3">
        <input
          disabled={isDisabled}
          className="h-4 w-4 rounded disabled:cursor-not-allowed"
          type="checkbox"
          checked={billingForm?.customerCivilian}
          name="customerCivilian"
          onChange={() => {
            onChange({ customerCivilian: !billingForm?.customerCivilian } as IProjectBillingRequisites);
          }}
        />
        <p>{t("GLOBAL.CIVILIAN")}</p>
      </div>
      <BhInputStackedLabel
        disabled={isDisabled}
        initialValue={billingForm?.customerRecipient}
        property={"customerRecipient"}
        label={t("PROJECT.ACTIVATION.BILLING.MODAL.RECIPIENT") as string}
        onChangeCallback={onChange}
        onBlurAction={onChange}
        debounceLength={900}
      />
      <BhComboboxStackedLabel disabled={isDisabled} property="customerCountry" initialValue={billingForm.customerCountry} values={countryNames} onSelect={onChange} label={t("COMPANY.COUNTRY")} />
      <BhInputStackedLabel
        disabled={isDisabled}
        initialValue={billingForm.customerAddress ?? ""}
        property="customerAddress"
        label={t("PROJECT.ACTIVATION.BILLING.MODAL.LEGAL_ADDRESS")}
        onChangeCallback={onChange}
      />
      {!billingForm?.customerCivilian && (
        <div className="mt-1 flex flex-col gap-3">
          <BhInputStackedLabel
            disabled={isDisabled}
            initialValue={billingForm?.customerRegCode}
            property={"customerRegCode"}
            label={t("PROJECT.ACTIVATION.BILLING.MODAL.REG_CODE") as string}
            onChangeCallback={onChange}
          />
          <div className="relative">
            <BhInputStackedLabel
              disabled={isDisabled}
              initialValue={billingForm?.customerVatNumber}
              property={"customerVatNumber"}
              label={t("PROJECT.ACTIVATION.BILLING.MODAL.VAT_NUMBER") as string}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
              onChangeCallback={onChange}
            />
            <BillingFormVatValidator vatNumber={billingForm.customerVatNumber ?? ""} customerRecipient={billingForm.customerRecipient ?? ""} onChangeHandler={onChange} />
          </div>
        </div>
      )}
      <BhInputStackedLabel
        disabled={isDisabled}
        initialValue={billingForm?.customerAdditionalInfo}
        property={"customerAdditionalInfo"}
        label={t("PROJECT.ACTIVATION.BILLING.MODAL.ADDITIONAL_INFO") as string}
        labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
        onChangeCallback={onChange}
      />
      <BhToggleButtonBar
        label={t("PROJECT.ACTIVATION.MODAL.TYPE") ?? ""}
        items={[
          { value: false, text: t("PROJECT.ACTIVATION.MODAL.TYPE.MAIL") as string },
          { value: true, text: t("PROJECT.ACTIVATION.MODAL.TYPE.EINVOICE") as string }
        ]}
        onClickAction={onChange}
        property="einvoice"
        selected={billingForm?.einvoice}
        classes="mt-4"
        disabled={isDisabled}
      />
    </div>
  );
};

export default BillingForm;
