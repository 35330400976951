import React, { FC } from "react";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhBreadcrumbs from "@components/breadcrumb/BhBreadcrumbs";
import BhTabs from "@components/tabs/BhTabs";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useAppSelector } from "@/app/hooks";
import BOCompanyAndBillingInfoTab from "@/views/home/backoffice/views/company/BOCompanyAndBillingInfoTab";
import { boSelectCompany, boSelectCompanyLoadingState } from "@/app/store/backofficeSlice";
import BhBOCompanyPlanTag from "@components/tags/BhBOCompanyPlanTag";
import BhBOCompanyStatusTag from "@components/tags/BhBOCompanyStatusTag";
import BOCompanyModulesTemplatesTab from "@/views/home/backoffice/views/company/BOCompanyModulesTemplatesTab";
import BOCompanyProjectsTab from "@/views/home/backoffice/views/company/BOCompanyProjectsTab";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { useNavigate } from "react-router-dom";
import RequireSuperUserAuth from "@/views/authentication/RequireSuperUserAuth";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";

const BOCompanySettingsContainer: FC = () => {
  const company = useAppSelector(boSelectCompany);
  const loading = useAppSelector(boSelectCompanyLoadingState);
  const navigate = useNavigate();

  if (!company) {
    return null;
  }

  const breadcrumbValues = [
    { text: "Admin", url: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/bo` },
    { text: "Companies", url: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/bo/companies` },
    { text: company?.name || "" }
  ];

  const tabs = [
    {
      id: 0,
      header: "Company & billing info",
      content: <BOCompanyAndBillingInfoTab company={company} />,
      href: "general"
    },
    {
      id: 1,
      header: "Projects",
      content: <BOCompanyProjectsTab />,
      href: "projects"
    },
    {
      id: 2,
      header: "Modules & Templates",
      content: <BOCompanyModulesTemplatesTab />,
      href: "modules-templates"
    }
  ];

  return (
    <BhScrollableBody>
      <div className="relative flex w-full flex-col">
        <div className="bh-bg-smoke w-full px-20 pb-14 pt-2 lg:px-24 md:px-8">
          <div className="relative mt-4">
            <BhBreadcrumbs values={breadcrumbValues} />
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <h1 className="mt-3">{company?.name}</h1>
                <BhBOCompanyPlanTag plan={company.plan} />
                <BhBOCompanyStatusTag status={company.status} />
              </div>
              <RequireSuperUserAuth>
                <BhPrimaryButton buttonProps={{ onClick: () => navigate("/company/" + company.id + "/general") }} icon={faGear}>
                  Company settings
                </BhPrimaryButton>
              </RequireSuperUserAuth>
            </div>
          </div>
        </div>
        <div className="-mt-12 flex h-full w-full flex-1 overflow-hidden pt-0.5">
          {loading !== BhStateStatusType.SUCCESS && (
            <div className="flex h-full w-full flex-row gap-x-2 overflow-hidden px-20 lg:px-24 md:px-8">
              <div className="bh-bg-pigeon-50 mb-1 mt-1.5 h-4 w-24 animate-pulse rounded" />
              <div className="bh-bg-pigeon-50 mb-1 mt-1.5 h-4 w-24 animate-pulse rounded" />
              <div className="bh-bg-pigeon-50 mb-1 mt-1.5 h-4 w-24 animate-pulse rounded" />
            </div>
          )}
          {loading === BhStateStatusType.SUCCESS && <BhTabs tabs={tabs} router={true} tabsContainerClasses="lg:px-24 px-20 md:px-8" />}
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default BOCompanySettingsContainer;
