import React, { FC, useRef, useState } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { boCreditInvoiceAsync, boDownloadInvoiceAsync, boResendSalesInvoiceAsync } from "@/app/store/backofficeSlice";
import { faPen } from "@fortawesome/pro-regular-svg-icons/faPen";
import { useAppDispatch } from "@/app/hooks";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { faArrowRightFromBracket } from "@fortawesome/pro-regular-svg-icons";
import { faLockKeyhole } from "@fortawesome/pro-regular-svg-icons/faLockKeyhole";
import BONewInvoiceModal from "@components/backoffice/invoices/BONewInvoiceModal";
import BOCreditingConfirmationModal from "@components/backoffice/invoices/BOCreditingConfirmationModal";
import { IMonthlyInvoiceOverviewRow } from "@/model/invoices/IMonthlyInvoiceOverviewRow";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";

interface Props {
  invoiceRow: IMonthlyInvoiceOverviewRow;
  disabled?: boolean;
}

const BOInvoiceDropdown: FC<Props> = ({ invoiceRow, disabled }) => {
  const dispatch = useAppDispatch();
  const [creditingModalOpen, setCreditingModalOpen] = useState(false);
  const [editInvoiceModalOpen, setEditInvoiceModalOpen] = useState(false);
  const dropdownButtonRef = useRef(null);

  const actionsMap = {
    DOWNLOAD_INVOICE: {
      text: "Download",
      function: () => {
        dispatch(boDownloadInvoiceAsync(invoiceRow.salesInvoice.uuid)).then((action) => {
          imitateUrlDownload(action.payload.value);
        });
      },
      icon: faArrowDownToLine
    },
    EDIT_INVOICE: {
      text: "Edit",
      function: () => setEditInvoiceModalOpen(true),
      icon: faPen
    },
    SEND_INVOICE: {
      text: "Send",
      function: () => {
        dispatch(boResendSalesInvoiceAsync(invoiceRow.salesInvoice));
      },
      icon: faArrowRightFromBracket
    },
    CREDIT_INVOICE: {
      text: "Credit",
      function: () => setCreditingModalOpen(true),
      icon: faLockKeyhole
    }
  };

  const invoiceActions = [
    invoiceRow.salesInvoice.uuid && actionsMap.DOWNLOAD_INVOICE,
    !disabled && actionsMap.EDIT_INVOICE,
    !disabled && actionsMap.SEND_INVOICE,
    !disabled && !invoiceRow.salesInvoice.credited && actionsMap.CREDIT_INVOICE
  ].filter(Boolean);

  const creditInvoice = () => {
    if (!invoiceRow.salesInvoice.credited) {
      dispatch(boCreditInvoiceAsync(invoiceRow.salesInvoice)).then(() => {
        setCreditingModalOpen(false);
      });
    }
  };

  return (
    <>
      <BhDropdown
        button={<BhIconButton buttonProps={{ buttonRef: dropdownButtonRef }} icon={faEllipsisVertical} />}
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
        menu={
          <BhFixedDropdownParent dropdownPosition={BhDropdownPositionEnum.BOTTOM_LEFT} parentRef={dropdownButtonRef}>
            <BhDropdownMenu values={invoiceActions} type={BhDropdownTypeEnum.STRING} textProperty="text" />
          </BhFixedDropdownParent>
        }
      />
      {editInvoiceModalOpen && <BONewInvoiceModal setIsShown={setEditInvoiceModalOpen} editableInvoiceRow={invoiceRow} />}
      {creditingModalOpen && <BOCreditingConfirmationModal setIsShown={() => setCreditingModalOpen(!creditingModalOpen)} isShown={creditingModalOpen} onConfirmCallback={creditInvoice} />}
    </>
  );
};

export default BOInvoiceDropdown;
