import { EntityId } from "@reduxjs/toolkit";

export interface ISalesInvoiceReport {
  id: EntityId;
  name: string;
  uuid: string;
  type: SalesInvoiceReportType;
  deleted: boolean;
  generated: Date;
}

export enum SalesInvoiceReportType {
  MONTHLY_DOUBTFUL_INVOICES = "MONTHLY_DOUBTFUL_INVOICES",
  MONTHLY_RECOVERED_INVOICES = "MONTHLY_RECOVERED_INVOICES",
  MONTHLY_OUTSTANDING_INVOICES = "MONTHLY_OUTSTANDING_INVOICES"
}
