import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  addNestedFormDataAsync,
  fetchFormDTOAsync,
  removeNestedFormDataAsync,
  saveFormDataAsync,
  saveNestedFormDataAsync,
  selectCanCurrentUserEditForm,
  selectCurrentFormId
} from "@/app/store/form/formSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { IFormDataSaveRequest } from "@/model/IForm";
import FormModals from "@components/form/modals/FormModals";
import FormContent from "@components/form/FormContent";
import { EntityId } from "@reduxjs/toolkit";
import FormHeaderRow from "@components/form/FormHeaderRow";
import FormFooterRow from "@components/form/FormFooterRow";

interface Props {
  fileEntityId: EntityId;
  dirId: EntityId;
}

const FormContainerNew: FC<Props> = ({ fileEntityId, dirId }) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const formId = useAppSelector(selectCurrentFormId);

  const canCurrentUserEditForm = useAppSelector((state) => selectCanCurrentUserEditForm(state, projectId));

  useEffect(() => {
    dispatch(fetchFormDTOAsync({ dirId: dirId, fileEntityId: fileEntityId, projectId: projectId }));
  }, [fileEntityId]);

  const formDataSave = (formDataSaveRequest: IFormDataSaveRequest) => {
    const dataSaveRequest = { ...formDataSaveRequest, ...{ formId, fileEntityId, projectId } };
    return dispatch(saveFormDataAsync(dataSaveRequest));
  };

  const formNestedDataSave = (formDataSaveRequest: IFormDataSaveRequest) => {
    const dataSaveRequest = { ...formDataSaveRequest, ...{ formId, fileEntityId, projectId } };
    dispatch(saveNestedFormDataAsync(dataSaveRequest));
  };

  const formNestedDataAdd = (formDataSaveRequest: IFormDataSaveRequest) => {
    const dataSaveRequest = { ...formDataSaveRequest, ...{ formId, fileEntityId, projectId } };
    dispatch(addNestedFormDataAsync(dataSaveRequest));
  };

  const formNestedDataRemove = (formDataSaveRequest: IFormDataSaveRequest) => {
    const dataSaveRequest = { ...formDataSaveRequest, ...{ formId, fileEntityId, projectId } };
    dispatch(removeNestedFormDataAsync(dataSaveRequest));
  };

  if (!formId) return null;

  return (
    <>
      <div className="pb-24 pr-3">
        <div className="mb-5">
          <FormHeaderRow disabled={!canCurrentUserEditForm} projectId={projectId} saveNumberCallback={formDataSave} dirId={dirId} formId={formId} />
        </div>
        <FormContent
          dataSaveCallback={formDataSave}
          nestedDataSaveCallback={formNestedDataSave}
          nestedDataAddCallback={formNestedDataAdd}
          nestedDataRemoveCallback={formNestedDataRemove}
          disabled={!canCurrentUserEditForm}
        />
        <FormFooterRow disabled={!canCurrentUserEditForm} saveCallback={formDataSave} />
      </div>
      <FormModals dirId={dirId} saveCallback={formDataSave} />
    </>
  );
};

export default FormContainerNew;
