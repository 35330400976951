import React, { FC } from "react";
import { SidebarItemType } from "@/model/ISidebarItem";
import { Link } from "react-router-dom";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProject, selectProjectStatus, selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";
import { selectCurrentSidebarMenuItem, selectSidebarMenuItemsForCurrentUser, setCurrentSidebarMenuItem } from "@/app/store/sidebarSlice";
import { selectedInFileTreeFolderSet } from "@/app/store/foldersSlice";
import BhSidebarNavDivider from "@components/nav/BhSidebarNavDivider";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { selectCountOfUnsignedInvitesForProject } from "@/app/store/signaturesSlice";
import { allFileEntitiesUnSelected, fileNameFilterReset } from "@/app/store/filesSlice";
import { useTranslation } from "react-i18next";
import { selectUserNeedsApprovalFileConfirmationIdsForProjectCount } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import BhProPlanTagFaded from "@components/tags/BhProPlanTagFaded";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { BauhubPlan } from "@/model/IProject";

interface Props {
  collapsed: boolean;
}

const SidebarNav: FC<Props> = ({ collapsed }) => {
  const { t } = useTranslation();
  const project = useAppSelector(selectCurrentProject);
  const projectStatus = useAppSelector(selectProjectStatus);
  const sidebarItems = useAppSelector((state) => selectSidebarMenuItemsForCurrentUser(state, project.id, project));
  const currentItem = useAppSelector(selectCurrentSidebarMenuItem);
  const rootDirectoryId = useAppSelector(selectRootDirectoryId);
  const rootDocumentDirectoryId = useAppSelector(selectRootDocumentDirectoryId);
  const signInvitesCount = useAppSelector(selectCountOfUnsignedInvitesForProject(project.id));
  const confirmationsCount = useAppSelector((state) => selectUserNeedsApprovalFileConfirmationIdsForProjectCount(state, project.id));
  const dispatch = useAppDispatch();

  const onClickHandler = (type: SidebarItemType | undefined) => {
    if (!type) {
      return;
    }
    if (type === SidebarItemType.DRAWINGS) {
      if (currentItem !== SidebarItemType.DRAWINGS) {
        dispatch(allFileEntitiesUnSelected());
      }
      dispatch(setCurrentSidebarMenuItem(SidebarItemType.DRAWINGS));
      dispatch(selectedInFileTreeFolderSet(rootDirectoryId));
      dispatch(fileNameFilterReset());
    }
    if (type === SidebarItemType.DOCUMENTS) {
      if (currentItem !== SidebarItemType.DOCUMENTS) {
        dispatch(allFileEntitiesUnSelected());
      }
      dispatch(setCurrentSidebarMenuItem(SidebarItemType.DOCUMENTS));
      dispatch(selectedInFileTreeFolderSet(rootDocumentDirectoryId));
      dispatch(fileNameFilterReset());
    }
  };

  const getBadgeForItem = (type: SidebarItemType, collapsed: boolean) => {
    if (type === SidebarItemType.SIGN_INVITES && signInvitesCount > 0) {
      return (
        <BhBadge type={BhBadgeType.BRIGHT} classes="ml-2">
          {signInvitesCount}
        </BhBadge>
      );
    }
    if (type === SidebarItemType.CONFIRMATIONS && confirmationsCount && confirmationsCount > 0) {
      return (
        <BhBadge type={BhBadgeType.BRIGHT} classes="ml-2">
          {confirmationsCount}
        </BhBadge>
      );
    }
    if (type === SidebarItemType.BAUDRIVE && !collapsed && project.plan !== BauhubPlan.PRO) {
      return <BhProPlanTagFaded />;
    }
  };

  return (
    <nav className="mb-4 mt-2 flex-1" aria-label="Sidebar">
      {rootDirectoryId &&
        projectStatus === BhStateStatusType.SUCCESS &&
        Object.entries(sidebarItems).map(([key, navItem]) => {
          if (navItem.type === SidebarItemType.DIVIDER) {
            return <BhSidebarNavDivider key={navItem.id} />;
          }
          let dirIdOrBlank = "";
          if (navItem.type === SidebarItemType.DRAWINGS) {
            dirIdOrBlank = rootDirectoryId.toString();
          }
          if (navItem.type === SidebarItemType.DOCUMENTS) {
            dirIdOrBlank = rootDocumentDirectoryId.toString();
          }
          return (
            <Link
              key={navItem.id}
              to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${project.id}/${navItem.href}/${dirIdOrBlank}`}
              onClick={() => onClickHandler(navItem.type)}
              className={classNames(currentItem === navItem.type ? "bh-bg-pigeon-30" : "", "hover:bh-bg-pigeon-40 group relative flex h-8 items-center rounded-none pl-6 pr-4 font-bold")}
            >
              <FontAwesomeIcon
                icon={(currentItem === navItem.type ? navItem.iconActive : navItem.icon) || faCheck}
                className={classNames(currentItem === navItem.type ? "bh-text-bauhub-green-120" : "", "mx-1 h-4 w-4")}
                aria-hidden="true"
              />
              {!collapsed && (
                <div className="ml-3 flex flex-row items-center">
                  <span>{t(navItem.nameCode)}</span>
                  {navItem.type && getBadgeForItem(navItem.type, collapsed)}
                </div>
              )}
              {collapsed && <div className="absolute right-3 top-0.5">{navItem.type && getBadgeForItem(navItem.type, collapsed)}</div>}
            </Link>
          );
        })}
    </nav>
  );
};

export default SidebarNav;
