import React, { FC, useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { fetchCurrentUserAsync, fetchCurrentUserInfoAsync } from "@/app/store/userSlice";
import { useAppDispatch } from "@/app/hooks";
import ProjectListContainer from "@/views/home/ProjectListContainer";
import NotFound from "@/views/global/NotFound";
import RequireAuth from "@/views/authentication/RequireAuth";
import Company from "@/views/home/company/Company";
import CreateProjectContainer from "@/views/home/company/createProject/CreateProjectContainer";
import BauhubAdmin from "@/views/home/admin/BauhubAdminRouter";
import PartyTemplateSettingsContainer from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateSettingsContainer";
import CreateNewPartyTemplateContainer from "@/views/home/company/detail/companySettingsPartyTemplates/createNewPartyTemplate/CreateNewPartyTemplateContainer";
import NotificationIntervalController from "@/views/home/notifications/NotificationIntervalController";
import ProjectAuth from "@/views/home/project/ProjectAuth";
import SecurityTooLowModal from "@/views/global/SecurityTooLowModal";
import NavbarGlobal from "@/views/home/navbar/NavbarGlobal";
import Profile from "@/views/home/profile/Profile";
import SyncProjectListContainer from "./sync/SyncProjectListContainer";
import EmptyRouteFallback from "@/views/home/EmptyRouteFallback";
import SyncProjectDirectoryTabsContainer from "./sync/SyncProjectDirectoryTabsContainer";
import SyncStepsContainer from "./sync/SyncStepsContainer";
import RequireSuperUserAuth from "@/views/authentication/RequireSuperUserAuth";
import BauhubBORouter from "@/views/home/backoffice/BauhubBORouter";
import ChecklistBaseContainer from "@/views/home/project/detail/checklists/base/ChecklistBaseContainer";
import PdftronContainerViewOnly from "@/views/home/project/detail/pdftron/PdftronContainerViewOnly";
import CompanyModals from "@/views/home/company/CompanyModals";
import FormBaseContainer from "@/views/home/project/detail/form/base/FormBaseContainer";
import RequireBOAuth from "@/views/authentication/RequireBOAuth";
import ProjectFrozenModals from "@/views/global/ProjectFrozenModals";

const HomeRouter: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCurrentUserAsync());
    dispatch(fetchCurrentUserInfoAsync());
  }, []);

  function ProjectWithParams() {
    let { projectId } = useParams();
    return projectId ? <ProjectAuth projectId={parseInt(projectId)} /> : <NotFound />;
  }

  function CompanyWithParams() {
    let { companyId } = useParams();
    return companyId ? <Company companyId={parseInt(companyId)} /> : <NotFound />;
  }

  function PartyTemplateSettingsWithParams() {
    let { companyId, predefinedPartyId } = useParams();
    return companyId && predefinedPartyId ? <PartyTemplateSettingsContainer companyId={parseInt(companyId)} predefinedPartyId={parseInt(predefinedPartyId)} /> : <NotFound />;
  }

  function PartyTemplateCreateNewWithParams() {
    let { companyId } = useParams();
    return companyId ? <CreateNewPartyTemplateContainer companyId={parseInt(companyId)} /> : <NotFound />;
  }

  function CreateProjectContainerWithParams() {
    let { companyId } = useParams();
    return companyId ? <CreateProjectContainer companyId={parseInt(companyId)} /> : <NotFound />;
  }

  function CreateSyncProjectPickerWithParams() {
    let { projectId } = useParams();
    return projectId ? <SyncProjectDirectoryTabsContainer projectId={parseInt(projectId)} /> : <NotFound />;
  }

  function ChecklistBaseWithParams() {
    const { companyId } = useParams();
    const { checklistBaseId } = useParams();
    const { checklistBaseVersionId } = useParams();
    if (companyId && checklistBaseId && checklistBaseVersionId) {
      const companyIdInt = parseInt(companyId);
      const checklistBaseIdInt = parseInt(checklistBaseId);
      const checklistBaseVersionIdInt = parseInt(checklistBaseVersionId);
      return <ChecklistBaseContainer companyId={companyIdInt} checklistBaseId={checklistBaseIdInt} checklistBaseVersionId={checklistBaseVersionIdInt} />;
    }
    return <NotFound />;
  }

  function FormBaseWithParams() {
    const { companyId } = useParams();
    const { formBaseId } = useParams();
    const { formBaseVersionId } = useParams();
    if (companyId && formBaseId && formBaseVersionId) {
      const companyIdInt = parseInt(companyId);
      const formBaseIdInt = parseInt(formBaseId);
      const formBaseVersionIdInt = parseInt(formBaseVersionId);
      return <FormBaseContainer companyId={companyIdInt} formBaseId={formBaseIdInt} formBaseVersionId={formBaseVersionIdInt} />;
    }
    return <NotFound />;
  }

  return (
    <div className="bh-bg-white flex h-screen flex-col overflow-hidden text-left">
      <RequireAuth>
        <NavbarGlobal />
        <div className="flex h-full w-full overflow-hidden">
          <Routes>
            <Route index element={<ProjectListContainer />} />

            <Route path="project/:projectId/*" element={<ProjectWithParams />} />

            <Route path="company/:companyId/*" element={<CompanyWithParams />} />
            <Route path="company/:companyId/work-group-templates/:predefinedPartyId/edit/*" element={<PartyTemplateSettingsWithParams />} />
            <Route path="company/:companyId/work-group-templates/add/*" element={<PartyTemplateCreateNewWithParams />} />
            <Route path="view" element={<PdftronContainerViewOnly />} />
            <Route path="user/*" element={<Profile />} />
            <Route
              path="bo/*"
              element={
                <RequireBOAuth>
                  <BauhubBORouter />
                </RequireBOAuth>
              }
            />
            <Route
              path="admin/*"
              element={
                <RequireSuperUserAuth>
                  <BauhubAdmin />
                </RequireSuperUserAuth>
              }
            />
            <Route path="company/:companyId/new/*" element={<CreateProjectContainerWithParams />} />

            <Route path="company/:companyId/checklist/base/:checklistBaseId/*" element={<ChecklistBaseWithParams />} />
            <Route path="company/:companyId/checklist/base/:checklistBaseId/version/:checklistBaseVersionId" element={<ChecklistBaseWithParams />} />

            <Route path="company/:companyId/form/base/:formBaseId/*" element={<FormBaseWithParams />} />
            <Route path="company/:companyId/form/base/:formBaseId/version/:formBaseVersionId" element={<FormBaseWithParams />} />

            <Route path="sync/steps" element={<SyncStepsContainer />} />
            <Route path="sync/home" element={<SyncProjectListContainer />} />
            <Route path="sync/:projectId/picker/*" element={<CreateSyncProjectPickerWithParams />} />
            <Route path="*" element={<EmptyRouteFallback />} />
          </Routes>
        </div>
        <SecurityTooLowModal />
        <ProjectFrozenModals />
        <NotificationIntervalController />
        <CompanyModals />
      </RequireAuth>
    </div>
  );
};
export default HomeRouter;
