import React, { FC } from "react";
import BhTableRow from "@components/table/BhTableRow";
import BhUserIcon from "@components/user/BhUserIcon";
import BhStatusCellApproved from "@components/status/BhStatusCellApproved";
import BhStatusCellDenied from "@components/status/BhStatusCellDenied";
import BhIconButton from "@components/buttons/BhIconButton";
import { faHubspot } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import BOUsersTableRowDropdown from "@components/backoffice/users/BOUsersTableRowDropdown";
import { IBoAdminUserResponse } from "@/model/backoffice/users/IBoAdminUserResponse";

interface Props {
  user: IBoAdminUserResponse;
}

const BOUsersTableRow: FC<Props> = ({ user }) => {
  return (
    <BhTableRow>
      <td>
        <div className="flex items-center justify-between">
          <div className="flex gap-3">
            <BhUserIcon user={user} />
            <p>
              {user.firstName + " " + user.lastName} <span className="bh-text-pigeon">{"(ID: " + user.id + ")"}</span>
            </p>
          </div>
          <BhIconButton icon={faHubspot as IconProp} />
        </div>
      </td>
      <td>{user.username}</td>
      <td>{user.userInfo.contactNumber}</td>
      <td>{user.language.split("_")[1]}</td>
      <td className="!p-0">{user.personalCode ? <BhStatusCellApproved /> : <BhStatusCellDenied />}</td>
      <td className="!p-0">{user.twoFactorEnabled ? <BhStatusCellApproved /> : <BhStatusCellDenied />}</td>
      <td>
        <div className="relative flex justify-end">
          <BOUsersTableRowDropdown user={user} />
        </div>
      </td>
    </BhTableRow>
  );
};

export default BOUsersTableRow;
