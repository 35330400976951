import { IBhSort } from "@/model/IBhSort";
import { ICompany } from "@/model/ICompany";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import { BauhubPlan, ProjectSecurityLevel, ProjectStatus } from "@/model/IProject";
import { IProjectBilling } from "@/model/billing/IProjectBilling";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { IMonthlyTrialOverviewRow } from "@/model/trials/IMonthlyTrialOverviewRow";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";

export interface IBoLoadingState {
  companies: BhStateStatusType;
  company: BhStateStatusType;
  companyUsers: BhStateStatusType;
  companyBilling: BhStateStatusType;
}

export interface IBoSort {
  companiesSort: IBhSort<ICompany>;
  invoicesSort: IBhSort<IBoInvoiceRowSort>;
  companyProjectsSort: IBhSort<IBoProjectSort>;
  trialsSort: IBhSort<IMonthlyTrialOverviewRow>;
  revenueSort: IBhSort<ISalesInvoice>;
}

export interface IBoInvoiceRowSort extends ISalesInvoice {
  companyName: string;
  companyPlan: BauhubPlan;
}

export interface IBoProjectSort extends IProjectBilling {
  name: string;
  status: ProjectStatus;
  created: Date;
  relatedUsers: Array<ISimpleValidUserAuthority>;
  securityLevel: ProjectSecurityLevel;
  language: string;
  closed: boolean;
  suspended: boolean;
}

export const initialCompaniesSort: IBhSort<ICompany> = {
  property: "name" as keyof ICompany,
  reversed: false
};

export const initialInvoiceRowSort: IBhSort<IBoInvoiceRowSort> = {
  property: "invoiceDate" as keyof IBoInvoiceRowSort,
  reversed: true
};

const initialCompanyProjectsSort: IBhSort<IBoProjectSort> = {
  property: "name" as keyof IBoProjectSort,
  reversed: false
};

const initialTrialsSort: IBhSort<IMonthlyTrialOverviewRow> = {
  property: "companyName" as keyof IMonthlyTrialOverviewRow,
  reversed: false
};

const initialRevenueSort: IBhSort<ISalesInvoice> = {
  property: "fullInvoiceNumber" as keyof ISalesInvoice,
  reversed: true
};

export const initialSort: IBoSort = {
  companiesSort: initialCompaniesSort,
  invoicesSort: initialInvoiceRowSort,
  companyProjectsSort: initialCompanyProjectsSort,
  trialsSort: initialTrialsSort,
  revenueSort: initialRevenueSort
};
