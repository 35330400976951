import BOHeader from "@/views/home/backoffice/views/BOHeader";
import React, { FC, useEffect, useState } from "react";
import { boFetchFutureRevenueAsync, boSelectFutureRevenueSorted, boSelectSort, boSetSort } from "@/app/store/backofficeSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhDatepickerStackedLabel from "@components/input/BhDatepickerStackedLabel";
import { formatDate, formatDateISO, getLastDayOfMonth } from "@/utilities/dateUtility";
import BhSpreadsheetTableBody from "@components/spreadsheet/BhSpreadsheetTableBody";
import BhSpreadsheetTableTR from "@components/spreadsheet/BhSpreadsheetTableTR";
import BhSpreadsheetTableCell from "@components/spreadsheet/BhSpreadsheetTableCell";
import BhSpreadsheetTable from "@components/spreadsheet/BhSpreadsheetTable";
import { IFutureRevenueDTO } from "@/model/invoices/IFutureRevenueDTO";
import { formatCurrency } from "@/utilities/accountingUtilities";
import { sum } from "lodash";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import BhSpreadsheetTableHeader from "@components/spreadsheet/BhSpreadsheetTableHeader";

const BOFutureRevenue: FC = () => {
  const boSort = useAppSelector(boSelectSort);
  const revenue: Array<IFutureRevenueDTO> = useAppSelector(boSelectFutureRevenueSorted);
  const dispatch = useAppDispatch();

  const today = new Date();
  const lastDayPrevMonth = getLastDayOfMonth(new Date(today.getFullYear(), today.getMonth() - 1, 1));

  const [dateFilter, setDateFilter] = useState({ selected: lastDayPrevMonth });

  useEffect(() => {
    dispatch(boFetchFutureRevenueAsync(formatDateISO(dateFilter.selected)));
  }, [dateFilter]);

  const onChangeFilter = (filter: { selected: Date }) => {
    setDateFilter(filter);
  };

  const formatSum = (sum: number) => {
    return formatCurrency(sum, { symbol: "€", thousandsSeparator: " ", decimalSeparator: ",", significantDigits: 2 }, true);
  };

  const sortCallback = (column: IBhTableHeader<ISalesInvoice>) => {
    if (column.field) {
      const updatedRevenueSort = { ...boSort.revenueSort, property: column.field, reversed: column.field === boSort.revenueSort.property ? !boSort.revenueSort.reversed : boSort.revenueSort.reversed };
      const newSort = { ...boSort, revenueSort: updatedRevenueSort };
      dispatch(boSetSort(newSort));
    }
  };

  const columns: Array<IBhTableHeader<ISalesInvoice>> = [
    { content: "Invoice Number", field: "fullInvoiceNumber", sortable: true, classes: "w-36" },
    { content: "Invoice date", field: "invoiceDate", sortable: true, classes: "w-36" },
    { content: "Customer", field: "customerRecipient", sortable: true },
    { content: "Type", field: "type", classes: "w-28" },
    { content: "Invoice total", field: "sumWithoutVat", sortable: true, classes: "w-36" },
    { content: "Remaining months", classes: "w-36" },
    { content: "Remaining value", classes: "w-36" }
  ];

  return (
    <div className="px-16 pb-20 sm:px-4">
      <BOHeader
        customHeader={
          <div className="flex items-center gap-4 py-2">
            <h1 className="m-0">Revenue</h1>
          </div>
        }
      />
      <BhDatepickerStackedLabel initialValue={dateFilter.selected} property="selected" label="Date" onChangeCallback={onChangeFilter} />
      <h3 className="mt-6">Future revenue</h3>
      <BhSpreadsheetTable>
        <BhSpreadsheetTableHeader columns={columns} sort={boSort.revenueSort} onClickCallback={sortCallback} />
        <BhSpreadsheetTableBody>
          {revenue &&
            revenue.map((subscription, index) => (
              <BhSpreadsheetTableTR key={index}>
                <BhSpreadsheetTableCell>{subscription.invoice.fullInvoiceNumber}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{formatDate(subscription.invoice.invoiceDate)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{subscription.invoice.customerRecipient}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{subscription.invoice.type}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right">{formatSum(subscription.invoice.sumWithoutVat)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{subscription.remainingMonths}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right">{formatSum(subscription.remainingValue)}</BhSpreadsheetTableCell>
              </BhSpreadsheetTableTR>
            ))}
          {revenue && revenue.length > 0 && (
            <BhSpreadsheetTableTR highlight={true}>
              <BhSpreadsheetTableCell></BhSpreadsheetTableCell>
              <BhSpreadsheetTableCell></BhSpreadsheetTableCell>
              <BhSpreadsheetTableCell></BhSpreadsheetTableCell>
              <BhSpreadsheetTableCell></BhSpreadsheetTableCell>
              <BhSpreadsheetTableCell></BhSpreadsheetTableCell>
              <BhSpreadsheetTableCell>
                <strong>Total: </strong>
              </BhSpreadsheetTableCell>
              <BhSpreadsheetTableCell classes="text-right">{formatSum(sum(revenue?.map((i) => i.remainingValue)))}</BhSpreadsheetTableCell>
            </BhSpreadsheetTableTR>
          )}
          {(!revenue || revenue.length === 0) && (
            <BhSpreadsheetTableTR>
              <BhSpreadsheetTableCell colSpan={7}>No data</BhSpreadsheetTableCell>
            </BhSpreadsheetTableTR>
          )}
        </BhSpreadsheetTableBody>
      </BhSpreadsheetTable>
    </div>
  );
};

export default BOFutureRevenue;
